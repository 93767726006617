import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Checkbox,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import styles from "../Modals.styles";
import { modalTypes } from "../../../../constants/field-constants";
import { selectSuperAdmin } from "../../../../../modules/super-admin/selectors";
import { setUnitsForSave } from "../../../../../modules/importPDF/actions";

const PDFUnitsDetailsModal = () => {
  const dispatch = useDispatch();
  const [objectUnits, setUnits] = useState<any[]>([]);
  const [objectStatus, setObjectStatus] = useState("");

  const handleOpen = ({ units, status, onUnitsChange }) => {
    const preparedUnits = units.map((unit: any) => {
      return {
        ...unit,
        isChecked: true,
      };
    });
    setUnits(preparedUnits);
    setObjectStatus(status);
  };

  const getUnitName = (unit: any) => {
    const street_name = unit.units_addresses.street_name
      ? unit.units_addresses.street_name.charAt(0).toUpperCase() +
        unit.units_addresses.street_name.slice(1).toLowerCase()
      : "";
    const number = unit.units_addresses.number
      ? unit.units_addresses.number
      : "";
    const number_add = unit.units_addresses.number_add
      ? unit.units_addresses.number_add
      : "";
    const postcode = unit.units_addresses.postcode
      ? unit.units_addresses.postcode
      : "";
    return `${postcode} ${street_name} ${number} ${number_add}`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, unit: any) => {
    setUnits([...objectUnits, { ...unit, isChecked: e.target.checked }]);
  };

  const handleSave = () => {
    const unitsForSave: any = [];
    objectUnits.forEach((unit: any) => {
      if (unit.isChecked) {
        unitsForSave.push(unit);
      }
    });

    dispatch(setUnitsForSave(unitsForSave));
  };

  return (
    <>
      <DefaultModal onOpen={handleOpen} nameEvent={types.openUnitsDetailsModal}>
        {({ closeModal }) => (
          <>
            <ModalHeader title="Object units details" />
            <Container>
              <List sx={styles.list}>
                {objectUnits &&
                  objectUnits.length > 0 &&
                  objectUnits.map((unit: any, index) => (
                    <ListItem key={index} sx={styles.listItem}>
                      {objectStatus === "check&update" ? (
                        <ListItemButton>
                          <ListItemIcon>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  edge="end"
                                  checked={unit.isChecked || true}
                                  onChange={(e) => handleChange(e, unit)}
                                />
                              }
                              label=""
                            />
                          </ListItemIcon>
                          v
                        </ListItemButton>
                      ) : (
                        <ListItemText>{getUnitName(unit)}</ListItemText>
                      )}
                    </ListItem>
                  ))}
              </List>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={
                objectStatus === "check&update"
                  ? modalTypes.SAVE
                  : modalTypes.INFO
              }
              onSave={handleSave}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const { entityMainDetails } = selectLeadgen(state);
  const { tagsList } = selectSuperAdmin(state);
  return { entityMainDetails, tagsList };
}
export default connect(mapStateToProps)(PDFUnitsDetailsModal);
