import { createSelector } from "reselect";

const leadsState = (state) => {
  if (state && state.login) {
    return state.login;
  }
  return null;
};

const userSelector = createSelector(
  [leadsState],
  (state) => state //return login
);

const receiveUserSelector = createSelector(
  [userSelector],
  (state) => state.receive
);

export const selectUser: any = createSelector(
  [userSelector, receiveUserSelector],
  (user, receive) => {
    const userData = user && receive ? receive : {};
    const userId = userData && userData.id ? userData.id : null;
    let userName = "Current User";
    if (user && receive && userData?.name) {
      userName =
        userData.surname !== null
          ? `${userData.name} ${userData.surname}`
          : `${userData.name}`;
    }

    const userNameInitial =
      userData && userData.name ? userData.name.charAt(0) : "";
    const userSurnameInitial =
      userData && userData.surname && userData.surname !== null
        ? userData.surname.charAt(0)
        : "";
    const userInitials = `${userNameInitial}${userSurnameInitial}`;
    const userRole = userData.role;
    const userAvatar = userData.img_url;

    return {
      userData,
      userId,
      userName,
      userInitials,
      userRole,
      userAvatar,
    };
  }
);
