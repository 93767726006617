const styles = {
  toolBar: {
    minHeight: {
      xs: "26px",
      sm: "40px",
      md: "50px",
      lg: "64px",
    },
  },
  dataBox: {
    flexGrow: 1,
    height: {
      xs: "30px",
      sm: "40px",
      md: "50px",
      lg: "60px",
    },
    background: "#114B76",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
  },
  headerContent: {
    flexGrow: 1,
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "10px",
      sm: "12px",
      md: "14px",
      lg: "18px",
    },
    color: "#FFF",
  },
  buttonContent: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "10px",
      sm: "12px",
      md: "14px",
      lg: "16px",
    },
    color: "#FFF",
  },
  table: {
    maxHeight: {
      xs: 895,
      lg: 1024,
    },
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  dataHeaderTable: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: {
      xs:"10px",
      sm: "12px",
      lg: "14px",
    },
    padding: {
      xs: "0 10px 0",
      sm: "7px 6px",
      md: "10px 9px",
      lg: "16px",
    },
    color: "#495057",
    backgroundColor: "secondary.light",
  },
  dataTableBody: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "10px",
      sm: "12px",
      lg: "14px",
    },
    padding: {
      xs: "5px",
      sm: "5px",
      md: "8px",
      lg: "14px",
      xl: "16px",
    },
    color: "#495057",
  },
  editButtonsContainer:{

    minWidth: {
      xs: "5rem",
      md: "6rem",
      lg:"6.9rem"
    },

  },

  buttonTableBody: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "10px",
      sm: "12px",
      lg: "14px",
    },
    color: "#495057",
    background: "rgba(180, 206, 229, 0.44)",
    borderRadius: "5px",
  },

  iconStyles:{
    color: "#B4CEE5",
    fontSize: {
      xs: "medium",
      md: "24px",
    },
  },
};

export default styles;
