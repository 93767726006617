import { styled } from "@mui/styles";

// eslint-disable-next-line no-empty-pattern
const StyledSearchIconWrapper = styled("div")(({}) => ({
  padding: "0 2px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default StyledSearchIconWrapper;
