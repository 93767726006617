const styles = {
  header: {
    flexGrow: 1,
    height: "55px",
  },
  toolbar: {
    backgroundColor: "#114B76",
  },
  headerButton: {
    color: "#F8F8FB",
    width: "90px",
    backgroundColor: "#658EB1",
    borderColor: "#114B76",
    "&:hover": {
      backgroundColor: "#658EB1",
      boxShadow: "none",
    },
  },
  userInfoHolder: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  userName: {
    fontFamily: "Poppins",
    color: "#495057",
    fontSize: 14,
    fontWeight: "medium",
  },
  userRole: {
    fontFamily: "Poppins",
    color: "#495057",
    fontSize: 12,
    fontWeight: "regular",
  },
  list: {
    display: "block",
    padding: "1rem 0",
  },
};

export default styles;
