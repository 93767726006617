import DefaultModal from "../../common/components/modal/default-modal/DefaultModal";
import { types } from "../../common/components/helpers/eventEmitter";
import { Container } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import ModalFooter from "../../common/components/modal/modal-footer/ModalFooter";
import { modalTypes } from "../../common/constants/field-constants";
import ModalHeader from "../../common/components/modal/modal-header/ModalHeader";
import styles from "./GlobalSearch.styles";
import FilterMenu from "./components/FilterMenu";
import SearchBlock from "./components/SearchBlock";
import {
  editSettingsRequest,
  receiveSettingsRequest,
} from "./actions/settings";
import { connect, useDispatch } from "react-redux";
import { selectSearch } from "./selectors";

const initialSettingsState = {
  search_limits: {
    objectsLimit: 5,
    entitiesLimit: 5,
  },
  // objectsLimit: 5,
  // entitiesLimit: 5,
  queryCity: "",
  queryCitiesGroup: [],
  area_filters: {
    city: true,
    cities: false,
  },
  search_type: {
    entity: true,
    object: true,
  },
  entity_search_type: {
    entity_name: true,
    entity_phone_number: true,
    entity_kadaster_id: false,
  },
  entity_type: {
    person: true,
    company: false,
  },
  entity_role: {
    owner: false,
    non_owner: false,
    contact: false,
    manager: false,
    all: true,
  },
  object_search_type: {
    object_street_name: true,
    object_kadaster_id: false,
  },
  object_plot_params: {
    object_plot_G0: true,
    object_plot_A1: false,
  },
};

const GlobalSearchModal = (props: any) => {
  const dispatch = useDispatch();
  const { getSettings } = props;
  const [userSearchSettings, setUserSearchSettings] =
    useState(initialSettingsState);
  const [currentUserId, setUserId] = useState(null);

  const handleOpen = (userId: any) => {
    if (userId) {
      setUserId(userId);
    }
    dispatch(receiveSettingsRequest());
  };

  const handleClose = () => {
    setUserSearchSettings(getSettings);
  };
  // console.log('userSearchSettings', userSearchSettings);
  // useEffect(() => {
  //   dispatch(receiveSettingsRequest());
  // }, [dispatch]);

  useEffect(() => {
    // getSettings && setUserSearchSettings(getSettings);
    if (getSettings && Object.keys(getSettings).length !== 0) {
      return setUserSearchSettings(getSettings);
    } else {
      dispatch(receiveSettingsRequest());
      return setUserSearchSettings(getSettings);
    }
  }, [dispatch, getSettings]);

  const handleChecked = (
    block: any,
    field: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (block === "area_filters") {
      if (field === "city") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            [field]: event.target.checked,
            cities: !event.target.checked,
          },
        });
      } else {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            [field]: event.target.checked,
            city: !event.target.checked,
          },
        });
      }
    }
    if (block === "entity_search_type") {
      if (field === "entity_kadaster_id") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            entity_name: !event.target.checked,
            entity_phone_number: !event.target.checked,
            [field]: event.target.checked,
          },
        });
      } else if (field === "entity_name") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            [field]: event.target.checked,
            entity_phone_number: !event.target.checked,
            entity_kadaster_id: !event.target.checked,
          },
        });
      } else {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            [field]: event.target.checked,
            entity_name: !event.target.checked,
            entity_kadaster_id: !event.target.checked,
          },
        });
      }
    }
    if (block === "entity_type") {
      if (field === "person") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            [field]: event.target.checked,
            company: !event.target.checked,
          },
        });
      } else {
        return setUserSearchSettings({
          ...userSearchSettings,
          entity_role: {
            all: true,
            owner: false,
            non_owner: false,
            contact: false,
            manager: false,
          },
          [block]: {
            [field]: event.target.checked,
            person: !event.target.checked,
          },
        });
      }
    }
    if (block === "entity_role") {
      if (field === "all") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            [field]: event.target.checked,
            owner: !event.target.checked,
            non_owner: !event.target.checked,
            contact: !event.target.checked,
            manager: !event.target.checked,
          },
        });
      } else {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            ...userSearchSettings[block],
            [field]: event.target.checked,
            all: false,
          },
        });
      }
    }
    if (block === "object_search_type") {
      if (field === "object_kadaster_id") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            object_street_name: !event.target.checked,
            [field]: event.target.checked,
          },
        });
      } else {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            [field]: event.target.checked,
            object_kadaster_id: false,
          },
        });
      }
    }

    return setUserSearchSettings({
      ...userSearchSettings,
      [block]: {
        ...userSearchSettings[block],
        [field]: event.target.checked,
      },
    });
  };

  const handleChangeCity = (type: string, valueChange: any) => {
    if (type === "queryCitiesGroup") {
      const convertedValue = valueChange.map(({ city }) => city);
      return setUserSearchSettings({
        ...userSearchSettings,
        queryCitiesGroup: convertedValue,
      });
    }
    if (type === "queryCity") {
      const convertedValue = valueChange !== null ? valueChange.city : "";
      return setUserSearchSettings({
        ...userSearchSettings,
        queryCity: convertedValue,
      });
    }
  };

  const handleChangeLimit = (
    block: any,
    field: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (block === "search_limits") {
      if (field === "objectsLimit") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            ...userSearchSettings[block],
            [field]: event.target.value,
          },
        });
      }
      if (field === "entitiesLimit") {
        return setUserSearchSettings({
          ...userSearchSettings,
          [block]: {
            ...userSearchSettings[block],
            [field]: event.target.value,
          },
        });
      }
    }
  };
  // console.log('objectsLimit', userSearchSettings.search_limits);
  const handleUpdateSettings = () => {
    dispatch(editSettingsRequest(userSearchSettings));
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        onClose={handleClose}
        nameEvent={types.openGlobalSearchModal}
        modalType="search"
        // onClose={handleClose}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader title={"SEARCH"} />
            <Container sx={styles.searchContainer}>
              <FilterMenu
                settings={userSearchSettings}
                handleChecked={handleChecked}
                handleUpdateSettings={handleUpdateSettings}
                changeCities={handleChangeCity}
                changeLimit={handleChangeLimit}
              />
              <SearchBlock filters={userSearchSettings} />
            </Container>
            <ModalFooter closeModal={closeModal} type={modalTypes.INFO} />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  //@ts-ignore
  const { getSettings } = selectSearch(state);
  return { getSettings };
}

export default connect(mapStateToProps)(GlobalSearchModal);
