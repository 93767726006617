import {
  RECEIVE_ENTITY_NOTES_REQUEST,
  RECEIVE_ENTITY_NOTES_SUCCESS,
  RECEIVE_ENTITY_NOTES_ERROR,
  UPDATE_ENTITY_NOTE_REQUEST,
  UPDATE_ENTITY_NOTE_SUCCESS,
  UPDATE_ENTITY_NOTE_ERROR,
  DELETE_ENTITY_NOTE_REQUEST,
  DELETE_ENTITY_NOTE_SUCCESS,
  DELETE_ENTITY_NOTE_ERROR,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";

export const receiveEntityNotesRequest = (entityId) => {
  return {
    type: RECEIVE_ENTITY_NOTES_REQUEST,
    entity_id: entityId,
  };
};

export const receiveEntityNotesSuccess = (entityNote) => {
  return {
    type: RECEIVE_ENTITY_NOTES_SUCCESS,
    entityNote,
  };
};

export const receiveEntityNotesError = (error) => {
  AlertService.error(`SOMETHING WENT WRONG. Error: ${error}`);
  return {
    type: RECEIVE_ENTITY_NOTES_ERROR,
    error,
  };
};

export const createUpdateNoteRequest = (data) => {
  return {
    type: UPDATE_ENTITY_NOTE_REQUEST,
    data,
  };
};

export const updateNoteSuccess = (entityNote, message) => {
  AlertService.success(message);
  return {
    type: UPDATE_ENTITY_NOTE_SUCCESS,
    entityNote,
  };
};

export const updateNoteError = (error) => {
  AlertService.error(`SOMETHING WENT WRONG. Error: ${error}`);
  return {
    type: UPDATE_ENTITY_NOTE_ERROR,
    error,
  };
};

export const removeNoteRequest = (id) => {
  return {
    type: DELETE_ENTITY_NOTE_REQUEST,
    id,
  };
};

export const removeNoteSuccess = (message) => {
  AlertService.success(message);
  return {
    type: DELETE_ENTITY_NOTE_SUCCESS,
  };
};

export const removeNoteError = (error) => {
  AlertService.error(`SOMETHING WENT WRONG. Error: ${error}`);
  return {
    type: DELETE_ENTITY_NOTE_ERROR,
    error,
  };
};
