import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import styles from "../../Leadgen.styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  receiveObjectByIdRequest,
  setObjectDelete,
  updateObjectRequest,
} from "../../actions/objects";
import { selectLeadgen } from "../../selectors";
import eventEmitter, {
  types,
} from "../../../../common/components/helpers/eventEmitter";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

interface ObjectProps {
  objectId?: string | undefined;
  currentObject?: any;
  isDeleteObject: boolean;
}

const Object = (props: ObjectProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [objectDetails, setObjectDetails] = useState({} as any);
  const { objectId, currentObject, isDeleteObject } = props;
  const actionTypes = ["primary_owner"];

  // console.log("objectDetails ", objectDetails);

  useEffect(() => {
    dispatch(receiveObjectByIdRequest(objectId));
  }, [dispatch, objectId]);

  useEffect(() => {
    currentObject && setObjectDetails(currentObject);
  }, [dispatch, currentObject]);

  useEffect(() => {
    if (isDeleteObject) {
      navigate("/leadgen");
      dispatch(setObjectDelete());
    }
  }, [dispatch, navigate, isDeleteObject]);

  const getUnitAddress = (address: any) => {
    const streetName = address.street_name ? address.street_name : "";
    const number = address.number ? address.number : "";
    const numberAdd = address.number_add ? address.number_add : "";
    return `${streetName} ${number} ${numberAdd}`;
  };

  const setRowOpen = () => {
    setOpen(!isOpen);
  };

  const setPrimaryOwner = (id) => {
    dispatch(
      updateObjectRequest(actionTypes, objectId, { primary_owner_id: id })
    );
  };

  const getRows = (owners: any[], primary_owner_id: any) => {
    const rows: any = [];
    if (owners && owners.length) {
      owners.forEach((owner) => {
        rows.push({
          id: owner.id,
          "Owner name": owner.name,
          Age: owner.age,
          Status: owner.status,
          Actions: owner.id,
          primary_owner_id: primary_owner_id,
        });
      });
    }
    return rows;
  };

  const dataGridRows = getRows(
    objectDetails.ownersDetails,
    objectDetails.primary_owner_id
  );

  const dataGridColumns: GridColDef[] = [
    {
      field: "Owner name",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return (
          <Link
            href={`/leadgen/entity/${params.row.id}`}
            title={`To leadgen`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "Age",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        let backgroundColor;
        let text;
        if (
          params.row.primary_owner_id &&
          params.row.primary_owner_id === params.row.id
        ) {
          backgroundColor = "#4caf50";
          text = "primary owner";
        } else {
          backgroundColor = "#658EB1";
          text = "owner";
        }

        return (
          <span
            style={{
              padding: "4px 6px",
              borderRadius: "15px",
              color: "#ffffff",
              backgroundColor,
              minWidth: "130px",
              textAlign: "center",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      getActions: ({ id }) => {
        return [
          <Button onClick={() => setPrimaryOwner(id)} disabled={objectDetails.primary_owner_id === id}>Set as primary</Button>,
        ];
      },
    },
  ];

  return (
    <>
      <Card sx={styles.card}>
        <Box sx={styles.objectInfoHolder}>
          <Typography
            variant="h4"
            component="div"
            sx={styles.objectContentTitle}
          >
            {`${objectDetails.city}, ${objectDetails.objectAddress}`}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              sx={styles.buttonLinkObjects}
              // onClick={() =>
              //   eventEmitter.emit(types.openObjectSearchModal, entityId)
              // }
              onClick={() =>
                eventEmitter.emit(
                  types.openEntityObjectEditModal,
                  "objectEdit",
                  objectId
                )
              }
            >
              EDIT
            </Button>
            <Button
              variant="contained"
              sx={styles.buttonUnlinkObjects}
              // onClick={() =>
              //   eventEmitter.emit(types.openAllObjectUnlinkModal, { unLinkType: 'all_objects', entityId, objectList })
              // }
              onClick={() =>
                eventEmitter.emit(
                  types.openDeleteEntityObjectModal,
                  "objectDelete",
                  objectId
                )
              }
            >
              DELETE
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "17px",
            marginBottom: "15px",
            width: "100%",
          }}
        >
          <Typography sx={{ mr: 2 }} variant="body2" component="div">
            Total size: {objectDetails.full_size}m²
          </Typography>
          <Typography sx={{ mr: 2 }} variant="body2" component="div">
            Housing: {objectDetails.house_size}m²
          </Typography>
          <Typography sx={{ mr: 2 }} variant="body2" component="div">
            Other: {objectDetails.other_size}m²
          </Typography>
          <Typography variant="body2" component="div">
            Value: {objectDetails.price} €
          </Typography>
        </Box>
        <TableContainer component={Paper} sx={styles.objectTable}>
          <Table aria-label="collapsible table">
            <TableHead sx={{ background: "#B4CEE5" }}>
              <TableRow>
                <TableCell />
                <TableCell>Address</TableCell>
                <TableCell align="center">Size, m²</TableCell>
                <TableCell align="center">Purpose</TableCell>
                <TableCell align="center">Value, €</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: "16px 6px" }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setRowOpen()}
                  >
                    {isOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {objectDetails.objectAddress}
                </TableCell>
                <TableCell align="center">
                  {objectDetails.full_size || "n/a"}
                </TableCell>
                <TableCell align="center">
                  {objectDetails.purpose || "n/a"}
                </TableCell>
                <TableCell align="center">
                  {objectDetails.price || "n/a"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={10}
                >
                  {isOpen && objectDetails.units.length > 0 && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: "10px 10px 20px" }}>
                        <Table size="small" aria-label="units_details">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Unit address</TableCell>
                              <TableCell align="center">
                                Unit size, m²
                              </TableCell>
                              <TableCell align="center">Purpose</TableCell>
                              <TableCell align="center">Value, €</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {objectDetails.units.map((unit, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {getUnitAddress(unit.address)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {unit.size || "n/a"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {unit.purpose || "n/a"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {unit.price || "n/a"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card sx={styles.objectOwnersCard}>
        <Typography variant="h4" component="div" sx={styles.contentTitleMain}>
          Owners details
        </Typography>
        {objectDetails?.ownersDetails?.length > 0 ? (
          <DataGrid
            sx={{ marginTop: "15px" }}
            columns={dataGridColumns}
            rows={dataGridRows}
          />
        ) : (
          <Typography sx={styles.contentTitle}> There is no owners </Typography>
        )}
      </Card>
    </>
  );
};

function mapStateToProps(state) {
  const { currentObject, isDeleteObject } = selectLeadgen(state);
  return { currentObject, isDeleteObject };
}

export default connect(mapStateToProps)(Object);
