import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  receiveSettingsSuccess,
  editSettingsSuccess,
  receiveSettingsError,
  editSettingsError,
} from "../actions/settings";
import {
  SEARCH_RECEIVE_SETTINGS_REQUEST,
  SEARCH_EDIT_SETTINGS_REQUEST,
} from "../../../common/constants/action-constants";

function* searchReceiveSettings() {
  try {
    const settings = yield call(ApiService.get, "/current-user/settings");
    yield put(receiveSettingsSuccess(settings));
  } catch (error) {
    yield put(receiveSettingsError(error));
  }
}

function* searchEditSettings(action) {
  try {
    const settings = yield call(
      ApiService.post,
      "/current-user/settings",
      action.data
    );
    yield put(editSettingsSuccess(settings));
  } catch (error) {
    yield put(editSettingsError(error));
  }
}

export default function* actionSearchSettings() {
  yield takeLatest(SEARCH_RECEIVE_SETTINGS_REQUEST, searchReceiveSettings);
  yield takeLatest(SEARCH_EDIT_SETTINGS_REQUEST, searchEditSettings);
}
