import React from "react";
import PropTypes from "prop-types";
import styles from "./ModalHeader.styles";
import { AppBar, Toolbar, Typography } from "@mui/material";

export default function ModalHeader(props: any) {
  return (
    <AppBar position="relative" sx={styles.dataApp}>
      <Toolbar>
        {props.email ? (
          <>
            <Typography variant="h6" component="div" sx={styles.headerContent}>
              {props.title}
            </Typography>
            <Typography variant="h6" component="div" sx={styles.headerContent}>
              {props.email}
            </Typography>
          </>
        ) : (
          <Typography variant="h6" component="div" sx={styles.headerContent}>
            {props.title}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  email: PropTypes.string,
};
