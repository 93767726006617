import { call, put, takeLatest } from "redux-saga/effects";
import {
  adminGetUsersRequest,
  adminGetUsersSuccess,
  adminGetUsersError,
  adminGetUserLogsSuccess,
  adminGetUserLogsError,
  adminDeleteUserError,
  adminCreateUserError,
  adminGetUserDetailsSuccess,
  adminGetUserDetailsError,
} from "../actions";
import {
  ADMIN_GET_USERS_REQUEST,
  ADMIN_GET_USER_LOGS_REQUEST,
  ADMIN_DELETE_USER_REQUEST,
  ADMIN_CREATE_USER_REQUEST,
  ADMIN_GET_USER_DETAILS_REQUEST,
  ADMIN_UPDATE_USER_REQUEST,
} from "../../../common/constants/action-constants";
import ApiService from "../../../common/services/api.service";
import AlertService from "../../../common/services/notification.service";
import { notificationMessage } from "../../../common/constants/notication-constants";

function* getUsersFullData() {
  try {
    const users = yield call(ApiService.get, "/users/list");
    yield put(adminGetUsersSuccess(users.data));
  } catch (error) {
    yield put(adminGetUsersError(error));
    AlertService.error(notificationMessage.PERMISSIONS_ERROR);
  }
}

function* getUserLogs(action) {
  try {
    const userLogs = yield call(ApiService.get, `/user/${action.id}/logs`);
    yield put(adminGetUserLogsSuccess(userLogs.data));
  } catch (error) {
    yield put(adminGetUserLogsError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}
function* getUserDetails(action) {
  try {
    const user = yield call(ApiService.get, `/user/${action.id}/data`);
    yield put(adminGetUserDetailsSuccess(user.data));
  } catch (error) {
    yield put(adminGetUserDetailsError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* createUser(action) {
  try {
    const newUser = yield call(ApiService.post, "/user", action.user);
    if (newUser.data.user.id) {
      AlertService.success(newUser.data.message);
      yield put(adminGetUsersRequest());
    }
  } catch (error) {
    yield put(adminCreateUserError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* deleteUser(action) {
  try {
    const userDelete = yield call(ApiService.delete, `/user/${action.id}`);
    if (userDelete.data.message) {
      AlertService.success(userDelete.data.message);
    }
    yield call(ApiService.get, "/users/list");
    yield put(adminGetUsersRequest());
  } catch (error) {
    yield put(adminDeleteUserError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* updateUser(action) {
  try {
    const userUpdate = yield call(
      ApiService.put,
      `/user/${action.id}`,
      action.user
    );
    if (userUpdate.data.message) {
      AlertService.success(userUpdate.data.message);
    }
    yield call(ApiService.get, "/users/list");
    yield put(adminGetUsersRequest());
  } catch (error) {
    yield put(adminDeleteUserError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

export default function* actionAdmin() {
  yield takeLatest(ADMIN_GET_USERS_REQUEST, getUsersFullData);
  yield takeLatest(ADMIN_GET_USER_LOGS_REQUEST, getUserLogs);
  yield takeLatest(ADMIN_GET_USER_DETAILS_REQUEST, getUserDetails);
  yield takeLatest(ADMIN_DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(ADMIN_CREATE_USER_REQUEST, createUser);
  yield takeLatest(ADMIN_UPDATE_USER_REQUEST, updateUser);
}
