import axios from "axios";
import UserService from "./user.service";
import { useNavigate } from "react-router-dom";

const { REACT_APP_API_HOST, REACT_APP_API_PORT } = process.env;
if (!REACT_APP_API_HOST || !REACT_APP_API_PORT) {
  throw new Error("Please ensure you have setup your .env file");
}

export const API_HOST = `${REACT_APP_API_HOST}:${REACT_APP_API_PORT}/api/v1`;
const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

const OnError = (error) => {
  const { response } = error;
  const navigate = useNavigate();
  if (response.status === 401) {
    UserService.logout();
    navigate("/login");
  }
};

class ApiService {
  static get(uri) {
    return axios.get(`${API_HOST}${uri}`, getHeaders()).catch(OnError);
  }

  static post(uri, data) {
    return axios.post(`${API_HOST}${uri}`, data, getHeaders()).catch(OnError);
  }

  static put(uri, data) {
    return axios.put(`${API_HOST}${uri}`, data, getHeaders()).catch(OnError);
  }

  static delete(uri) {
    return axios.delete(`${API_HOST}${uri}`, getHeaders()).catch(OnError);
  }
}

export default ApiService;
