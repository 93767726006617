import { toast } from "react-toastify";

export default class AlertService {
  static success(message) {
    return toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  }

  static error(message) {
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  }

  static info(message) {
    return toast.info(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  }

  static warning(message) {
    return toast.warning(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  }
}
