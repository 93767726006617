const styles = {
  root: {
    height: "100vh",
    width: "100vw",
  },
  loginContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: {
      xs: "100vw",
      sm: "696px",
      md: "796px",
    },
    height: {
      xs: "100vh",
      sm: "557px",
    },
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: {
      xs: 0,
      sm: "398px",
    },
    height: "557px",
    overflow: "hidden",
    backgroundColor: "#B4CEE5",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "398px",
    height: "557px",
  },
  welcomeTitle: {
    fontSize: "32px",
    fontWeight: "regular",
    fontFamily: "Bebas Neue",
    color: "#015A9C",
  },
  welcomeSubTitle: {
    fontSize: "20px",
    fontWeight: "regular",
    fontFamily: "Bebas Neue",
    color: "#82AFD1",
  },
  loginButton: {
    height: "70px",
    width: "300px",
    backgroundColor: "#26A96A",
    fontSize: "24px",
    fontFamily: "Bebas Neue",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#007c41",
      borderColor: "#046236",
    },
  },
  textHolder: {
    marginTop: "25%",
  },
  buttonHolder: {
    marginTop: "25%",
  },
  logo: {},
};

export default styles;
