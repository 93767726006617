const styles = {
  container: {
    height: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  typography: {
    color: "#495057",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "25px",
    fontWeight: "500",
  },
  imgBg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "100%",
  },
};

export default styles;
