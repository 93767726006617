import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Button,
} from "@mui/material";
import styles from "../ImportPDF.styles";
import StyledTextField from "../../../common/components/styled-components/StyledTextField";
import { formatImportDate } from "../../../common/formats/date-fromatter";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import PropTypes from "prop-types";
import eventEmitter, {
  types,
} from "../../../common/components/helpers/eventEmitter";

const Head = ({
  fullList,
  entity,
  object,
  isDead,
  statistic,
  lastImportDate,
  onChange,
}) => {
  const entityOptions = [
    { value: "person", label: "person" },
    { value: "company", label: "company" },
    { none: "", label: "n/a" },
  ];

  const entityIsDeadOptions = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
    { none: 3, label: "n/a" },
  ];

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {entity && (
        <>
          <Grid item xs={2}>
            <Box sx={styles.entityObjectInfo}>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                NAME: {entity.entity_name ? entity.entity_name : "n/a"}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                KPN:
                {entity.kadaster_personal_number ? (
                  <a
                    href={entity.kadaster_url || "/"}
                    title={"Kadaster link"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {entity.kadaster_personal_number}
                  </a>
                ) : (
                  "n/a"
                )}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                KVK:{" "}
                {entity.chamber_commerce_number ? (
                  <a
                    href={entity.chamber_commerce_url || "/"}
                    title={"KVK link"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {entity.chamber_commerce_number}
                  </a>
                ) : (
                  "n/a"
                )}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                STATUS:{" "}
                {entity.id ? (
                  <a
                    href={`/leadgen/entity/${entity.id}`}
                    title={`leadgen entity-${entity.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EXISTS IN DB
                  </a>
                ) : (
                  "DOESN'T EXIST IN DB"
                )}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                LAST IMPORT DATE:{" "}
                {entity.last_import_date
                  ? formatImportDate(entity.last_import_date)
                  : "n/a"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <StyledTextField
              fullWidth
              select
              label="Alive"
              margin="dense"
              onChange={(e) => onChange("is_dead", e)}
              value={isDead || ""}
            >
              {entityIsDeadOptions.map((option, index) => (
                // @ts-ignore
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Import date"
                  value={lastImportDate}
                  onChange={(value) => onChange("lastImportDate", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ marginLeft: 3 }}
              control={
                <Checkbox
                  checked={fullList}
                  onChange={(e) => onChange("isFullList", e)}
                />
              }
              label={<Typography>Full list</Typography>}
            />
          </Grid>
          <Grid item xs={2}>
            <StyledTextField
              fullWidth
              label="Entity type"
              select
              margin="dense"
              onChange={(e) => onChange("entity_type", e)}
              value={entity.entity_type || ""}
            >
              {entityOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={2}>
            <Box sx={styles.entityObjectInfo}>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                PDF OBJECT AMOUNT: {statistic.pdfObjectsAmount}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                OBJECTS IN DB: {statistic.objectsInDB}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                ENTITY OBJECTS AMOUNT: {statistic.entityObjects}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={styles.entityObjectInfo}>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                PARSED OBJECT AMOUNT: {statistic.extractedObjectsAmount}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                NEW OBJECTS: {statistic.notFoundInDB}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                LINKED TO ENTITY: {statistic.linkedObjects}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                NOT LINKED TO ENTITY: {statistic.notLinkedToEntityObjects}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      {object && (
        <>
          <Grid item xs={2}>
            <Box sx={styles.entityObjectInfo}>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                NAME: {object.name ? object.name : "n/a"}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                KPN:
                {object.kadaster_id ? (
                  <a
                    href={object.kadaster_url || "/"}
                    title={"Kadaster link"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {object.kadaster_id}
                  </a>
                ) : (
                  "n/a"
                )}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                STATUS:{" "}
                {object.id ? (
                  <a
                    href={`/leadgen/object/${object.id}`}
                    title={`leadgen object-${object.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EXISTS IN DB
                  </a>
                ) : (
                  "DOESN'T EXIST IN DB"
                )}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                LAST IMPORT DATE:{" "}
                {object.last_import_date
                  ? formatImportDate(lastImportDate)
                  : "n/a"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Import date"
                  value={lastImportDate}
                  onChange={(value) => onChange("lastImportDate", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <Box sx={styles.entityObjectInfo}>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                UNITS AMOUNT: {statistic.unitsAmount}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                OWNERS AMOUNT: {statistic.ownersAmount || "n/a"}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                KADASTER SIZE: {statistic.kadSize || "n/a"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={styles.entityObjectInfo}>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                PURCHASE YEAR: {statistic.purchaseYear || "n/a"}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                PURCHASE PRICE: {statistic.purchasePrice || "n/a"}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={styles.textColumn}
              >
                OBJECT COORDINATES: {statistic.coordinates}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={styles.entityObjectInfo}>
              <Button
                onClick={() =>
                  eventEmitter.emit(types.openUnitsDetailsModal, {
                    units: object.units,
                    status: "for show",
                  })
                }
              >
                SHOW UNITS LIST
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Head;

Head.propTypes = {
  fullList: PropTypes.bool.isRequired,
  lastImportDate: PropTypes.any.isRequired,
  statistic: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isDead: PropTypes.number,
  entity: PropTypes.object,
  object: PropTypes.object,
};
