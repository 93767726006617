const styles = {
  dataBox: {
    flexGrow: 1,
    height: {
      // xs: '30px',
      // sm: '40px',
      // md: '50px',
      // lg: '60px'
      xs: "4.5rem",
      md: "4rem",
    },
    background: "#114B76",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
  },

  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    minHeight: {
      // xs: '26px',
      // sm: '40px',
      // md: '50px',
      // lg: '64px'
      xs: "4.5rem",
      md: "4rem",
    },
  },

  formHolder: {
    overflowY: "auto",
    minHeight: 640,
    maxHeight: {
      xs: 895,
      lg: 1024,
    },
  },

  boxContent: {
    display: "flex",
    alignItems: "center",
  },
  boxContentRight: {
    justifyContent: "flex-end",
  },

  boxContentLeft: {
    textAlign: "left",
  },

  boxStat: {
    display: "flex",
    marginRight: "2rem",
    alignItems: "flex-start",
  },

  headerContent: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    textAlign: "left",
    fontSize: {
      xs: "8px",
      sm: "10px",
      md: "12px",
      lg: "14px",
      xl: "16px",
    },
    color: "#FFF",
    border: "none",
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-input": {
      color: "#FFF",
      paddingRight: 0,
      paddingLeft: "0.5rem",
      padding: {
        xs: "0 0 0 0.5rem",
      },
      fontSize: {
        xs: "8px",
        sm: "10px",
        md: "12px",
        lg: "14px",
        xl: "16px",
      },
      fontWeight: 600,
    },

    // "& .MuiSvgIcon-root": {
    //   opacity: 0,
    // },
  },

  statisticContent: {
    color: "#495057",
    fontSize: {
      xs: "8px",
      sm: "10px",
      md: "12px",
      lg: "14px",
      xl: "16px",
    },
  },
  textSelect: {
    textTransform: "uppercase",
  },

  menuPanel: {
    background: "#B4CEE5",
    minHeight: {
      xs: "6.5rem",
      md: "6rem",
    },
    display: "flex",
    padding: "0 1.5rem",
    // justifyContent: 'space-between',
    alignItems: "center",
    color: "#FFF",
  },

  dateContainer: {
    display: "flex",
    maxWidth: "25rem",
    "& .MuiTextField-root:first-of-type, & .MuiTextField-root:last-of-type": {
      minWidth: 0,
      boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
    },
  },

  inputDate: {
    // "& .MuiOutlinedInput-root":

    "& .MuiFormLabel-root": {
      fontSize: {
        xs: "14px",
        md: "16px",
      },
      // transform: {
      //   xs: 'translate(14px, 10px) scale(1)'
      // },
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      "& .MuiOutlinedInput-input": {
        padding: {
          xs: "8px 0 8px 8px",
          sm: "10px 0 10px 12px",
          md: "16.5px 0 16.5px 14px",
        },
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#FFF",
      borderRadius: "4px",
      color: "#495057",
      // border: '1px solid',
      outline: "none",
      // minWidth: '100px',
      boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "none",
      },
    },
  },

  cardHeader: {
    background: "#B4CEE5",
    display: "flex",
    height: "100%",
    "& .MuiCardHeader-title, & .MuiCardHeader-subheader": {
      fontSize: {
        md: "12px",
        lg: "14px",
      },
    },
    "& .MuiCardHeader-action": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 0,
      marginBottom: 0,
    },
  },

  cardContentTypography: {
    color: "#495057",
    fontSize: {
      xs: "12px",
      lg: "12px",
      xl: "14px",
    },
    fontWeight: 400,
    marginBottom: "0.5rem",
    "&:last-of-type": {
      marginBottom: 0,
    },
  },

  link: {
    display: "flex",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      fontWeight: "bold",
    },
  },
};

export default styles;
