import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Typography, Box } from "@mui/material";

import DefaultModal from "../../default-modal/DefaultModal";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { types } from "../../../helpers/eventEmitter";
import { modalTypes } from "../../../../constants/field-constants";
import { selectAdmin } from "../../../../../modules/admin/selectors";
import {
  adminGetUserLogsRequest,
  adminDeleteUserRequest,
} from "../../../../../modules/admin/actions";
import styles from "../Modals.styles";

const UserDeleteModal = (props: any) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("Selected user");
  const [userEmail, setUserEmail] = useState("n/a");

  const [userId, setUserId] = useState(null);
  const { selectedUserName, selectedUserEmail } = props;

  const handleOpen = (id: any) => {
    setUserId(id);
  };

  useEffect(() => {
    userId !== null && dispatch(adminGetUserLogsRequest(userId));
  }, [dispatch, userId]);

  const handleDelete = (id: any) => {
    dispatch(adminDeleteUserRequest(id));
  };

  useEffect(() => {
    setUserName(selectedUserName);
    setUserEmail(selectedUserEmail);
  }, [dispatch, selectedUserName, selectedUserEmail]);

  return (
    <>
      <DefaultModal onOpen={handleOpen} nameEvent={types.openDeleteUserModal}>
        {({ closeModal }) => (
          <>
            <ModalHeader title="REMOVE USER" />
            <Box sx={styles.dataAppForDeleteModal}>
              <Typography sx={styles.contentContainer}>
                {`Are you sure you want to delete user`}
              </Typography>
              <Typography sx={styles.contentContainer}>
                {`${userName}`}
              </Typography>
              <Typography sx={styles.contentContainer}>
                {`(${userEmail})`}
              </Typography>
              <Typography sx={styles.contentContainer}>
                {`from the database?`}
              </Typography>
            </Box>

            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.DELETE}
              onSave={() => handleDelete(userId)}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const users = selectAdmin(state);
  return users;
}

export default connect(mapStateToProps)(UserDeleteModal);
