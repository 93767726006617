import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import styles from "../Modals.styles";
import { modalTypes } from "../../../../constants/field-constants";
import { receiveLabelsListRequest } from "../../../../../modules/super-admin/actions";
import { selectSuperAdmin } from "../../../../../modules/super-admin/selectors";
import { updateEntityRequest } from "../../../../../modules/leadgen/actions/entityDetails";

const EntityLabelModal = (props: any) => {
  const dispatch = useDispatch();
  const [currentEntityId, setEntityId] = useState("");
  const [entityLabel, setEntityLabel] = useState({} as any);
  const [labels, setLabelsList] = useState([] as any);
  const [deleteMode, setDeleteMode] = useState(false);
  const actionTypes = ["label"];
  const { entityMainDetails, labelsList } = props;

  const handleOpen = (entityId: string) => {
    setEntityId(entityId);
    dispatch(receiveLabelsListRequest());
    entityMainDetails &&
      entityMainDetails.label &&
      setEntityLabel(
        entityMainDetails.label.length > 0 && entityMainDetails.label[0]
          ? entityMainDetails.label[0]
          : {}
      ); /** it's always an array with one object **/
  };

  const handleDeleteLabel = () => {
    setDeleteMode(true);
    setEntityLabel(
      entityMainDetails.label.length > 0 && entityMainDetails.label[0]
        ? entityMainDetails.label[0]
        : {}
    );
    const updatedLabelList = labels.map((label: any) => {
      label.isChecked = false;
      return label;
    });
    setLabelsList(updatedLabelList);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    label: any,
    id: number
  ) => {
    if (e.target.checked) {
      setEntityLabel(label);
      const updatedLabelList = labels.map((label: any) => {
        if (label.id === id) {
          label.isChecked = e.target.checked;
          return label;
        } else {
          label.isChecked = false;
          return label;
        }
      });

      setDeleteMode(false);
      setLabelsList(updatedLabelList);
    }
  };

  useEffect(() => {
    const updatedList =
      labelsList &&
      labelsList.length &&
      labelsList.map((label: any) => {
        if (entityLabel && label.id === entityLabel.id) {
          return { id: label.id, name: label.name, isChecked: true };
        } else {
          return { id: label.id, name: label.name, isChecked: false };
        }
      });

    setLabelsList(updatedList);
  }, [dispatch, labelsList, entityLabel]);

  const handleSave = () => {
    dispatch(
      updateEntityRequest(deleteMode, actionTypes, currentEntityId, {
        entity_label_id: entityLabel.id,
        entityLabelId:
          entityMainDetails.label.length > 0 &&
          entityMainDetails.label[0].EntityLabel
            ? entityMainDetails.label[0].EntityLabel.id
            : null,
      })
    );
    setEntityLabel({});
  };

  return (
    <>
      <DefaultModal onOpen={handleOpen} nameEvent={types.openEntityLabelModal}>
        {({ closeModal }) => (
          <>
            <ModalHeader title="Set entity label" />
            <Container>
              <List sx={styles.list}>
                {labels &&
                  labels.length > 0 &&
                  labels.map((label: any) => (
                    <ListItem key={label.id} sx={styles.listItem}>
                      <ListItemButton>
                        <ListItemIcon>
                          <FormControlLabel
                            control={
                              <Checkbox
                                edge="end"
                                checked={label.isChecked || false}
                                onChange={(e) =>
                                  handleChange(e, label, label.id)
                                }
                              />
                            }
                            label=""
                          />
                        </ListItemIcon>
                        <ListItemText>{label.name}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
              <Button
                sx={styles.buttonContent}
                onClick={() => {
                  handleDeleteLabel();
                }}
              >
                Set empty entity label
              </Button>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.SAVE}
              onSave={handleSave}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state) {
  const { entityMainDetails } = selectLeadgen(state);
  const { labelsList } = selectSuperAdmin(state);
  return { entityMainDetails, labelsList };
}
export default connect(mapStateToProps)(EntityLabelModal);
