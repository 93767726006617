import { createSelector } from "reselect";
import {
  formatDate,
  formatDateAndTime,
  formatTime,
} from "../../../common/formats/date-fromatter";

const adminState = (state) => {
  if (state && state.admin) {
    return state.admin;
  }
  return null;
};

const adminSelector = createSelector(
  [adminState],
  (state) => state //return admin
);

const adminReceiveUsersSelector = createSelector(
  [adminSelector],
  (state) => state.receive
);

const adminReceiveUserLogsSelector = createSelector(
  [adminSelector],
  (state) => state.logs
);

const adminReceiveUserDetailsSelector = createSelector(
  [adminSelector],
  (state) => state.user
);

// const adminReceiveDriveUrlSelector = createSelector(
//   [adminSelector],
//   state => state.receiveDriveAuthUrl
// );

export const selectAdmin: any = createSelector(
  [
    adminSelector,
    adminReceiveUsersSelector,
    adminReceiveUserLogsSelector,
    adminReceiveUserDetailsSelector,
  ],
  (admin, receive, logs, user) => {
    const users = receive
      ? receive.map((user) => {
          return {
            id: user.id,
            email: user.email,
            name: user.name,
            surname: user.surname,
            role: user.role,
            last_activity_date: user.last_activity_date
              ? formatDateAndTime(user.last_activity_date)
              : "n/a",
            ip_restriction: user.ip_restriction ? "yes" : "no",
          };
        })
      : [];

    const userDetails =
      user && Object.keys(user).length
        ? user
        : {
            name: "",
            surname: "",
            email: "",
            role_id: "",
            defaultLPSProfile: "",
            ip_restriction: false,
            user_ip_whitelist: [],
          };

    const userWithLogs = logs ? logs : {};
    const selectedUserEmail = userWithLogs.email ? userWithLogs.email : "n/a";
    let selectedUserName = "Current User";
    if (userWithLogs && userWithLogs.name) {
      selectedUserName =
        userWithLogs.surname !== null
          ? `${userWithLogs.name} ${userWithLogs.surname}`
          : `${userWithLogs.name}`;
    }
    const userNameInitial =
      userWithLogs && userWithLogs.name ? userWithLogs.name.charAt(0) : "";
    const userSurnameInitial =
      userWithLogs && userWithLogs.surname
        ? userWithLogs.surname.charAt(0)
        : "";
    const selectedUserInitials = `${userNameInitial} ${userSurnameInitial}`;
    const selectedUserIP =
      userWithLogs && userWithLogs.logs && userWithLogs.logs.length > 0
        ? userWithLogs.logs[0].ip
        : "n/a";
    const selectedUserOS =
      userWithLogs && userWithLogs.logs && userWithLogs.logs.length > 0
        ? userWithLogs.logs[0].os
        : "n/a";
    const selectedUserLastLogin =
      userWithLogs && userWithLogs.last_activity_date
        ? formatDateAndTime(userWithLogs.last_activity_date)
        : "n/a";
    const selectedUserAvatar =
      userWithLogs && userWithLogs.img_url ? userWithLogs.img_url : "";
    const selectedUserLogInfo =
      userWithLogs && userWithLogs.logs
        ? userWithLogs.logs.map((logs: any) => {
            return {
              ip: logs.ip,
              os: logs.os,
              date: formatDate(logs.date),
              time: formatTime(logs.date),
            };
          })
        : [];
    // const authUrl = receiveDriveAuthUrl ? receiveDriveAuthUrl : "";

    return {
      admin,
      users,
      userDetails,
      userWithLogs,
      selectedUserName,
      selectedUserInitials,
      selectedUserIP,
      selectedUserOS,
      selectedUserAvatar,
      selectedUserLastLogin,
      selectedUserLogInfo,
      selectedUserEmail,
    };
  }
);
