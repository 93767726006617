const styles = {
  intervalInput: {
    display: "flex",
    justifyContent: "right",
    padding: "0 22px 15px 0",
  },
  buttonSave: {
    width: "110px",
    height: "40px",
    background: "rgba(4, 98, 54, 0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(4, 98, 54, 0.9)",
      borderColor: "#046236",
      boxShadow: "none",
    },
  },
  buttonDelete: {
    width: "110px",
    height: "40px",
    background: "#D67575",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#d01f1f",
      borderColor: "#e53a3a",
      boxShadow: "none",
    },
  },
  buttonCansel: {
    marginLeft: "10px",
    width: "110px",
    height: "40px",
    background: "rgba(17,75,118,0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.9)",
      borderColor: "#114B76",
      boxShadow: "none",
    },
  },
};

export default styles;
