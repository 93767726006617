import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import styles from "../Modals.styles";
import { modalTypes } from "../../../../constants/field-constants";
import {
  receiveDependentEntitiesRequest,
  updateEntityRequest,
} from "../../../../../modules/leadgen/actions/entityDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import EntitySearch from "../../../../../modules/search/components/EntitySearch";
import AlertService from "../../../../services/notification.service";

const EntityManagersModal = (props: any) => {
  const dispatch = useDispatch();
  const [currentEntityId, setEntityId] = useState("");
  const [entityManagersForSave, setEntityManagers] = useState([] as any);
  const [entityManagersForDelete, setEntityManagersForDelete] = useState(
    [] as any
  );
  const [managers, setManagersList] = useState([] as any);
  const [deleteMode, setDeleteMode] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const actionTypes = ["managers"];
  const { currentManagers } = props;

  const getManagerName = (manager: any) => {
    if (manager.name) {
      return manager.name;
    } else if (manager.last_name && manager.first_name) {
      return `${manager.first_name} ${manager.last_name}`;
    } else return manager.last_name;
  };

  const handleOpen = (entityId: string) => {
    setEntityId(entityId);
    dispatch(receiveDependentEntitiesRequest(entityId));
  };

  const handleDeleteAllManagers = () => {
    setDeleteMode(true);
    const managersIds = managers.map((manager: any) => {
      return manager.id;
    });
    setEntityManagersForDelete(managersIds);
    setManagersList([]);
  };

  const handleDeleteSelectedManager = (manager: any) => {
    setDeleteMode(true);
    const managersForDelete: any = [];
    const managersForLeave: any = [];
    // eslint-disable-next-line array-callback-return
    managers.map((currentManager: any) => {
      if (currentManager.id === manager.id) {
        managersForDelete.push(currentManager.id);
      } else {
        managersForLeave.push(currentManager);
      }
    });

    setEntityManagersForDelete(managersForDelete);
    setManagersList(managersForLeave);
  };

  const handleChange = (manager: any) => {
    const updatedManagersList: any = [...managers];
    const updatedManagersListForSave: any = [];
    if (
      updatedManagersList.some(
        (currentManager: any) => currentManager.id === manager.id
      )
    ) {
      return AlertService.warning("This manager is already added to entity");
    } else {
      updatedManagersList.push(manager);
      updatedManagersListForSave.push(manager.id);
    }

    setManagersList(updatedManagersList);
    setEntityManagers(updatedManagersListForSave);
  };

  useEffect(() => {
    currentManagers &&
      currentManagers.length &&
      setManagersList(currentManagers);
  }, [dispatch, currentManagers]);

  const handleSave = () => {
    dispatch(
      updateEntityRequest(deleteMode, actionTypes, currentEntityId, {
        entityManagersForSave,
        entityManagersForDelete,
      })
    );
    setDeleteMode(false);
    setEntityManagers([]);
    setEntityManagersForDelete([]);
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        nameEvent={types.openEntityManagersModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader title="Set entity managers" />
            <Container>
              <List sx={styles.list}>
                {managers && managers.length > 0 ? (
                  managers.map((manager: any) => (
                    <ListItem
                      key={manager.id}
                      sx={styles.dependentEntityListItem}
                    >
                      <ListItemText>{getManagerName(manager)}</ListItemText>
                      <IconButton>
                        <DeleteIcon
                          sx={styles.deleteIcon}
                          onClick={() => handleDeleteSelectedManager(manager)}
                        />
                      </IconButton>
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" sx={styles.emptyList}>
                    There is no managers yet
                  </Typography>
                )}
              </List>
              <Button
                sx={styles.buttonContent}
                onClick={() => {
                  handleDeleteAllManagers();
                }}
              >
                Set empty entity managers
              </Button>
              <Button
                sx={styles.buttonContent}
                onClick={() => setOpenSearch(!openSearch)}
              >
                Search entity managers
              </Button>
              {openSearch && (
                <>
                  <Divider />
                  <EntitySearch handleChangeList={handleChange} />
                </>
              )}
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.SAVE}
              onSave={handleSave}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const { currentManagers } = selectLeadgen(state);
  return { currentManagers };
}
export default connect(mapStateToProps)(EntityManagersModal);
