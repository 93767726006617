import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Typography, Box } from "@mui/material";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import { types } from "../../../helpers/eventEmitter";
import DefaultModal from "../../default-modal/DefaultModal";
import ModalHeader from "../../modal-header/ModalHeader";
import styles from "../Modals.styles";
import { modalTypes } from "../../../../constants/field-constants";
import ModalFooter from "../../modal-footer/ModalFooter";
import { receiveEntityNotesRequest } from "../../../../../modules/leadgen/actions/entityNotes";

const LeadsNotesModal = (props: any) => {
  const dispatch = useDispatch();
  const { entityNotes } = props;
  const [currentEntityId, setCurrentEntityId] = useState("");
  const [note, setNote] = useState({
    body: "",
    noteAuthor: { name: "", surname: "" },
  });

  const handleOpen = (entityId: string) => {
    setCurrentEntityId(entityId);
  };

  useEffect(() => {
    if (currentEntityId) {
      dispatch(receiveEntityNotesRequest(currentEntityId));
    }
  }, [dispatch, currentEntityId]);

  useEffect(() => {
    entityNotes && entityNotes.entityNote && setNote(entityNotes.entityNote);
  }, [dispatch, entityNotes]);

  return (
    <>
      <DefaultModal nameEvent={types.openLeadNoteModal} onOpen={handleOpen}>
        {({ closeModal }) => (
          <>
            <ModalHeader title={"LEAD NOTES"} />
            <Container sx={{ mb: 4 }}>
              {note.body && true ? (
                <>
                  <Box sx={{ display: "flex" }}>
                    <Typography sx={[styles.contentNote, styles.contentAuthor]}>
                      Author:
                    </Typography>
                    {note.noteAuthor &&
                    note.noteAuthor.surname &&
                    note.noteAuthor.name ? (
                      <Typography sx={styles.contentNote}>
                        &nbsp;{note.noteAuthor.name} {note.noteAuthor.surname}
                      </Typography>
                    ) : note.noteAuthor && note.noteAuthor.name ? (
                      <Typography sx={styles.contentNote}>
                        &nbsp;{note.noteAuthor.name}
                      </Typography>
                    ) : (
                      <Typography sx={styles.contentNote}>&nbsp;n/a</Typography>
                    )}
                  </Box>

                  <Typography
                    sx={[styles.contentNote, styles.contentBody]}
                    dangerouslySetInnerHTML={{ __html: note.body }}
                  />
                </>
              ) : (
                <Box sx={styles.containerWithoutNotes}>
                  <Typography sx={styles.contentNote}>
                    This lead doesn't have notes
                  </Typography>
                </Box>
              )}
            </Container>
            <ModalFooter closeModal={closeModal} type={modalTypes.INFO} />
          </>
        )}
      </DefaultModal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const entityNotes = selectLeadgen(state);
  return entityNotes;
};

export default connect(mapStateToProps)(LeadsNotesModal);
