import { all } from "redux-saga/effects";

import actionSearchSettings from "./settings";
import actionGlobalSearch from './search';
// import actionSearchByCity from "./city";
// import actionSearchByCompany from "./company";
// import actionSearchByName from "./name";
// import actionSearchByObject from "./object";
// import actionSearchByPerson from "./person";
// import actionSearchByStreet from "./street";
// import actionSearchByPhone from "./phone";

export default function* actionSearch() {
  yield all([
    actionSearchSettings(),
    actionGlobalSearch(),
    // actionSearchByCity(),
    // actionSearchByCompany(),
    // actionSearchByName(),
    // actionSearchByObject(),
    // actionSearchByPerson(),
    // actionSearchByStreet(),
    // actionSearchByPhone(),
  ]);
}
