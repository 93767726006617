import {
  RECEIVE_OBJECTS_OWNERS_ERROR,
  RECEIVE_OBJECTS_OWNERS_REQUEST,
  RECEIVE_OBJECTS_OWNERS_SUCCESS,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";

export const receiveObjectsStakeholdersRequest = (entityObjectsListIds) => {
  return {
    type: RECEIVE_OBJECTS_OWNERS_REQUEST,
    ids: entityObjectsListIds,
  };
};

export const receiveObjectsStakeholdersSuccess = (owners) => {
  return {
    type: RECEIVE_OBJECTS_OWNERS_SUCCESS,
    owners,
  };
};

export const receiveObjectsStakeholdersError = (error) => {
  AlertService.error(`SOMETHING WENT WRONG. Error: ${error}`);
  return {
    type: RECEIVE_OBJECTS_OWNERS_ERROR,
    error,
  };
};
