import { Grid, Paper, Box, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EntityInfoCard from './EntityInfoCard';
import styles from '../../Leadgen.styles';
import EntityNotes from './EntityNotes';
import Stakeholders from './Stakeholders';
import { connect, useDispatch } from 'react-redux';
import { receiveObjectsByOwnerRequest } from '../../actions/objects';
import { selectLeadgen } from '../../selectors';
import EntityRelatedInfo from './EntityRelatedInfo';
import EntityObjects from './EntityObjects';
import eventEmitter, {
  types
} from '../../../../common/components/helpers/eventEmitter';
import Maps from '../common/Maps';

interface EntitiesBlockProps {
  entityObjectsList?: any;
  entityId?: string | null;
  owners?: any;
  objectsAmount?: any;
  addressForMap?: any;
}

const mapStyles = {
  height: '410px',
  marginTop: '1.5em'
};

const breakpointMapMoving: number = 1200;

function MapBlock({ address, width, height }) {
  return (
    <>
      <Grid item xs={16}>
        <Paper sx={(width && width <= 1024) && (height && height <= 500)
          ? [styles.container, styles.containerFullHeight]
          : [styles.container, styles.containerMap]}>
          <Typography variant="h4" component="div" sx={styles.contentTitleMain}>
            Maps
          </Typography>
          <Maps mapStyles={mapStyles} addressForMapDisplaying={address} />
        </Paper>
      </Grid>
    </>
  );
}

function ColumnOne({ entityId, address, width, height }) {
  return (
    <>
      <Grid item container spacing={{ xs: 2, lg: 3 }} columns={16} sx={styles.grid}>
        <Grid item xs={16} sm={8}>
          <EntityInfoCard entityId={entityId} width={width} height={height} />
        </Grid>
        <Grid item xs={16} sm={8}>
          <Paper
            sx={(width && width <= 1024) && (height && height <= 500) ? [styles.firstColumnHolder, styles.containerFullHeight] : styles.firstColumnHolder}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Stakeholders
            </Typography>
            <Stakeholders entityId={entityId} />
          </Paper>
        </Grid>

        {(width >= breakpointMapMoving)
          && <MapBlock
            address={address}
            width={width}
            height={height}
          />}

        {/*<Grid item xs={16}>*/}
        {/*  <Paper sx={[styles.container, styles.containerMap]}>*/}
        {/*    <Typography variant="h4" component="div" sx={styles.contentTitleMain}>*/}
        {/*      Maps*/}
        {/*    </Typography>*/}
        {/*    <Maps mapStyles={mapStyles} addressForMapDisplaying={address} />*/}
        {/*  </Paper>*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
}

function ColumnTwo({ address, entityId, objectsAmount, mainInfo, objectList, width, height }) {
  return (
    <>
      <Grid container spacing={{ xs: 2, lg: 3 }} columns={16} sx={styles.grid}>
        <Grid item xs={16} sm={8}>
          <Paper
            sx={(width && width <= 1024) && (height && height <= 500)
              ? [styles.secondColumnHolder, styles.containerFullHeight]
              : styles.secondColumnHolder}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Notes
            </Typography>
            <EntityNotes entityId={entityId} />
          </Paper>
        </Grid>
        <Grid item xs={16} sm={8}>
          <Paper
            sx={(width && width <= 1024) && (height && height <= 500)
              ? [styles.secondColumnHolder, styles.containerFullHeight]
              : styles.secondColumnHolder}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Related info
            </Typography>
            <EntityRelatedInfo entityId={entityId} />
          </Paper>
        </Grid>
        <Grid item xs={16} sx={styles.grid}>
          <Paper
            sx={(width && width <= 1024) && (height && height <= 500)
              ? [styles.objectsContainer, styles.objectsContainerFullHeight]
              : styles.objectsContainer}>
            <Box sx={styles.objectsHolderBox}>
              <Typography
                variant="body2"
                component="div"
                sx={[styles.objectContentTitle, styles.objectContentTitleEntity]}
              >
                Objects ({objectsAmount})
              </Typography>
              <Typography sx={{ mr: '1rem' }} variant="body2" component="div">
                Total size: {mainInfo.totalSize}m²
              </Typography>
              <Typography sx={{ mr: '1rem' }} variant="body2" component="div">
                Housing: {mainInfo.houseSize}m²
              </Typography>
              <Typography sx={{ mr: '1rem' }} variant="body2" component="div">
                Other: {mainInfo.otherSize}m²
              </Typography>
              <Typography variant="body2" component="div">
                Value: {mainInfo.totalPrice} €
              </Typography>
            </Box>
            <Box sx={styles.infoRow}>
              <Button
                variant="contained"
                sx={styles.buttonLinkObjects}
                onClick={() =>
                  eventEmitter.emit(types.openObjectSearchModal, entityId)
                }
              >
                Link objects
              </Button>
              <Button
                variant="contained"
                sx={styles.buttonUnlinkObjects}
                onClick={() =>
                  eventEmitter.emit(types.openAllObjectUnlinkModal, {
                    unLinkType: 'all_objects',
                    entityId,
                    objectList
                  })
                }
              >
                Unlink all
              </Button>
            </Box>
            <EntityObjects entityId={entityId} />
          </Paper>
        </Grid>
        {(width <= breakpointMapMoving)
          && <MapBlock
            address={address}
            width={width}
            height={height}
          />}
      </Grid>
    </>
  );
}

const EntitiesBlock = (props: EntitiesBlockProps) => {
  const dispatch = useDispatch();
  const { entityId, entityObjectsList, objectsAmount, addressForMap } = props;
  const [entityObjects, setObjects] = useState([]);
  const [addressForMapDisplaying, setAddressForMapsDisplaying] = useState(null);
  const [mainInfo, setMainInfo] = useState({
    houseSize: 'n/a',
    otherSize: 'n/a',
    totalPrice: 'n/a'
  } as any);

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = (event) => {
      setWindowInnerWidth(event.target.innerWidth);
      setWindowInnerHeight(event.target.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  const getObjectListHeadInfo = (entityObjectsList: any[]) => {
    let houseSize = 0;
    let otherSize = 0;
    let totalPrice = 0;

    entityObjectsList &&
    entityObjectsList.length > 0 &&
    entityObjectsList.forEach(({ house_size, other_size, price }) => {
      houseSize += house_size || 0;
      otherSize += other_size || 0;
      totalPrice += Number(price) || 0;
    });

    let totalSize = houseSize + otherSize;
    return { houseSize, otherSize, totalPrice, totalSize };
  };

  useEffect(() => {
    dispatch(receiveObjectsByOwnerRequest(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    setObjects(entityObjectsList);
    setAddressForMapsDisplaying(addressForMap);
    const totalInfo = getObjectListHeadInfo(entityObjectsList);
    setMainInfo(totalInfo);
  }, [dispatch, entityObjectsList, addressForMap]);

  return (
    <Box sx={styles.holder}>
      <Grid container columns={16} spacing={{ xs: 2, lg: 3 }}>
        <Grid item xs={16} lg={8}>
          <ColumnOne
            entityId={entityId}
            address={addressForMapDisplaying}
            width={windowInnerWidth}
            height={windowInnerHeight} />
        </Grid>
        <Grid item xs={16} lg={8}>
          <ColumnTwo
            address={addressForMapDisplaying}
            entityId={entityId}
            objectsAmount={objectsAmount}
            mainInfo={mainInfo}
            objectList={entityObjects}
            width={windowInnerWidth}
            height={windowInnerHeight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

function mapStateToProps(state: any) {
  const { entityObjectsList, objectsAmount, addressForMap } =
    selectLeadgen(state);
  return { entityObjectsList, objectsAmount, addressForMap };
}

export default connect(mapStateToProps)(EntitiesBlock);
