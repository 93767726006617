const styles = {
  header: {
    flexGrow: 1,
    height: "55px",
  },
  headerTitle: {
    fontFamily: "Poppins",
    flexGrow: 1,
    display: { xs: "none", sm: "flex" },
  },
  toolbar: {
    backgroundColor: "#114B76",
  },
  headerButton: {
    color: "#F8F8FB",
    width: "90px",
    backgroundColor: "#658EB1",
    borderColor: "#114B76",
    "&:hover": {
      backgroundColor: "#658EB1",
      boxShadow: "none",
    },
  },
  appBar: {
    padding: 0,
  },
};

export default styles;
