import * as React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Card,
  Paper,
  CardContent,
  Box,
  Button,
  Container,
  Stack,
  ButtonGroup,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import styles from "./Dashboard.styles";
import { selectUser } from "../login/selectors";
import UserAvatar from "../../common/components/avatar/UserAvatar";
import { propsTypes } from "../../common/constants/field-constants";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};
const labels = ["", "", "", "", "", "", "", "", "", "", "", ""];
const chartData = {
  labels,
  datasets: [
    {
      label: "Success deals",
      data: labels.map(() => Math.random() * (50 - 5) + 5),
      // backgroundColor: "#49E8AFFF",
      backgroundColor: "#215f83",
    },
    {
      label: "Failed deals",
      data: labels.map(() => Math.random() * (50 - 5) + 5),
      // backgroundColor: "#F59292FF",
      backgroundColor: "#f39a60",
    },
  ],
};
const pieData = {
  labels: ["", ""],
  datasets: [
    {
      label: "# of Votes",
      data: [4, 8],
      backgroundColor: ["#f39a60", "#215f83"],
      borderColor: ["#f39a60", "#215f83"],
      borderWidth: 1,
      radius: 60,
      rotation: 20,
    },
  ],
};

const Dashboard = (props) => {
  const dataGridRows = [
    {
      id: 1,
      Date: "06.14.2022",
      "Activity type": "Call schedule",
      Status: "in progress",
      Link: "randvast.nl/leadgen/entity/1658456",
    },
    {
      id: 2,
      Date: "06.14.2022",
      "Activity type": "Deal",
      Status: "done",
      Link: "randvast.nl/leadgen/entity/1658456",
    },
    {
      id: 3,
      Date: "06.14.2022",
      "Activity type": "Call schedule",
      Status: "done",
      Link: "randvast.nl/leadgen/entity/1658456",
    },
    {
      id: 4,
      Date: "06.14.2022",
      "Activity type": "Deal",
      Status: "done",
      Link: "randvast.nl/leadgen/entity/1658456",
    },
    {
      id: 5,
      Date: "06.14.2022",
      "Activity type": "Deal",
      Status: "failed",
      Link: "randvast.nl/leadgen/entity/1658456",
    },
  ];
  const dataGridColumns: GridColDef[] = [
    {
      field: "Date",
      width: 140,
    },
    {
      field: "Activity type",
      width: 140,
    },
    {
      field: "Status",
      renderCell: (params: GridRenderCellParams<any, string>) => {
        let backgroundColor;
        let text;
        if (params.value === "done") {
          backgroundColor = "#4caf50";
          text = "done";
        }
        if (params.value === "in progress") {
          backgroundColor = "#FF9800";
          text = "in progress";
        }
        if (params.value === "failed") {
          backgroundColor = "#e53a3a";
          text = "failed";
        }

        return (
          <span
            style={{
              padding: "4px 6px",
              borderRadius: "15px",
              color: "#ffffff",
              backgroundColor,
              width: "90px",
              textAlign: "center",
            }}
          >
            {text}
          </span>
        );
      },
      filterable: false,
    },
    { field: "Link", flex: 1 },
  ];
  const [pageSize, setPageSize] = React.useState({ pageSize: 5, page: 0 });

  return (
    <Box sx={styles.root}>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <Card sx={styles.userInfo}>
            <Box sx={styles.boxImage}>
              <img src="images/rectangle.png" width={160} alt="rectangle" />
            </Box>
            <Paper elevation={0} square sx={styles.userInfoHeader}>
              <Typography sx={styles.cardHeader}>Welcome back!</Typography>
            </Paper>
            <Stack sx={styles.avatarStack}>
              <UserAvatar stylesType={propsTypes.DASHBOARD} />
            </Stack>
            <CardContent sx={styles.cardContent}>
              <Box sx={styles.holder}>
                <Typography sx={styles.contentTitle} variant="h2">
                  {props.userName}
                </Typography>
                <Typography sx={styles.contentSubTitle} variant="h2">
                  {props.userRole}
                </Typography>
              </Box>
              <Box sx={styles.holderAssigned}>
                <Typography sx={styles.contentAssigned} variant="h2">
                  12
                </Typography>
                <Typography sx={styles.subContentAssigned} variant="h2">
                  Assigned
                </Typography>
                <Typography sx={styles.subContentAssigned} variant="h2">
                  leads
                </Typography>
              </Box>
              <Box sx={styles.holderAssigned}>
                <Typography sx={styles.contentAssigned} variant="h2">
                  5
                </Typography>
                <Typography sx={styles.subContentAssigned} variant="h2">
                  Scheduled
                </Typography>
                <Typography sx={styles.subContentAssigned} variant="h2">
                  calls
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Card sx={styles.cardHolder}>
            <Box sx={styles.dealsStatistic}>
              <Typography sx={styles.contentResults}>Results</Typography>
              <ButtonGroup variant="outlined" aria-label="text button group">
                <Button variant="text" size="medium" sx={styles.buttonToday}>
                  Today
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={styles.buttonWeek}
                >
                  Week
                </Button>
              </ButtonGroup>
            </Box>
            <Box sx={styles.cardContentNoPadding}>
              <CardContent sx={styles.cardContentResults}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography sx={styles.cardTypography1}>
                      Deals added
                    </Typography>
                    <Typography sx={styles.cardTypography}>
                      Calls made
                    </Typography>
                    <Typography sx={styles.cardTypography}>
                      Calls missed
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={styles.cardTypography1}>3</Typography>
                    <Typography sx={styles.cardTypography}>8</Typography>
                    <Typography sx={styles.cardTypography}>4</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={styles.boxImages}>
                      <Pie options={options} data={pieData} />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Card>
          <Card>
            <Typography sx={styles.tableHeader} variant="h6">
              RSS news
            </Typography>
            <Container sx={styles.contentContainer}>
              <Timeline position="alternate">
                <TimelineItem sx={{ padding: 0 }}>
                  <TimelineOppositeContent color="text.secondary">
                    1 July
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    Successfully closed 25 deals
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{ padding: 0 }}>
                  <TimelineOppositeContent color="text.secondary">
                    19 May
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>New manager joined the team</TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{ padding: 0 }}>
                  <TimelineOppositeContent color="text.secondary">
                    9 May
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    Updated Leadgen usage in space
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{ padding: 0 }}>
                  <TimelineOppositeContent color="text.secondary">
                    29 April
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    Add new functionality to LPS calc...
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Container>
          </Card>
        </Grid>
        <Grid item md={8}>
          <Card sx={styles.chartHolder}>
            <Box sx={styles.dealsStatistic}>
              <Typography sx={styles.contentResults}>
                Deals statistic
              </Typography>
              <ButtonGroup variant="outlined" aria-label="text button group">
                <Button variant="text" size="medium" sx={styles.buttonToday}>
                  Week
                </Button>
                <Button variant="text" size="medium" sx={styles.buttonToday}>
                  Month
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={styles.buttonWeek}
                >
                  Year
                </Button>
              </ButtonGroup>
            </Box>
            <Box mt={2} sx={{ padding: "15px" }}>
              <Bar options={options} data={chartData} />
            </Box>
          </Card>
          <Paper>
            <Typography sx={styles.tableHeader}>Recent activity</Typography>
            <Box
              sx={{
                height: {
                  xs: "450px",
                  sm: "450px",
                  md: "450px",
                  lg: "328px",
                  xl: "200px",
                },
              }}
            >
              <DataGrid
                rows={dataGridRows}
                columns={dataGridColumns}
                // pageSize={pageSize}
                paginationModel={pageSize}
                onPaginationModelChange={(newPageSize) =>
                  setPageSize(newPageSize)
                }
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pageSizeOptions={[5, 10, 20]}
                pagination
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
function mapStateToProps(state: any) {
  const userData = selectUser(state);
  return userData;
}
export default connect(mapStateToProps)(Dashboard);
