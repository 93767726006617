import { combineReducers } from "redux";
import {
  RECEIVE_DEPENDENT_ENTITIES_ERROR,
  RECEIVE_DEPENDENT_ENTITIES_SUCCESS,
  RECEIVE_ENTITY_DETAILS_ERROR,
  RECEIVE_ENTITY_DETAILS_SUCCESS,
  UPDATE_ENTITY_SUCCESS,
  SET_ENTITY_DELETE,
  DELETE_ENTITY_ERROR,
  DELETE_ENTITY_SUCCESS,
} from "../../../common/constants/action-constants";

const initialState = {
  isDeletingEntity: false,
};

const receiveEntity = (state = {}, action: any) => {
  switch (action.type) {
    case RECEIVE_ENTITY_DETAILS_SUCCESS:
    case UPDATE_ENTITY_SUCCESS:
      return action.entity;

    default:
      return state;
  }
};

const entityError = (state = {}, action: any) => {
  switch (action.type) {
    case RECEIVE_ENTITY_DETAILS_ERROR:
    case RECEIVE_DEPENDENT_ENTITIES_ERROR:
    case DELETE_ENTITY_ERROR:
      return action.error;

    case SET_ENTITY_DELETE:
      return {};

    default:
      return state;
  }
};

const dependentEntities = (state = {}, action: any) => {
  switch (action.type) {
    case RECEIVE_DEPENDENT_ENTITIES_SUCCESS:
      return action.dependentEntities;
    default:
      return state;
  }
};

const responseRemoveEntity = (state = initialState, action: any) => {
  switch (action.type) {
    case DELETE_ENTITY_SUCCESS:
      return {
        isDeletingEntity: true,
      };
    case SET_ENTITY_DELETE:
      return {
        isDeletingEntity: false,
      };
    default:
      return state;
  }
};

const currentEntity = combineReducers({
  entity: receiveEntity,
  dependentEntities,
  responseRemoveEntity,
  error: entityError,
});

export default currentEntity;
