import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import styles from "../Modals.styles";
import { modalTypes } from "../../../../constants/field-constants";
import { receiveTagsListRequest } from "../../../../../modules/super-admin/actions";
import { selectSuperAdmin } from "../../../../../modules/super-admin/selectors";
import { updateEntityRequest } from "../../../../../modules/leadgen/actions/entityDetails";

const EntityTagsModal = (props: any) => {
  const dispatch = useDispatch();
  const [currentEntityId, setEntityId] = useState("");
  const [entityTags, setEntityTags] = useState([] as any);
  const [tags, setTagsList] = useState([] as any);
  const [deleteMode, setDeleteMode] = useState(false);
  const actionTypes = ["tags"];
  const { entityMainDetails, tagsList } = props;

  const handleOpen = (entityId: string) => {
    setEntityId(entityId);
    dispatch(receiveTagsListRequest());
    entityMainDetails &&
      entityMainDetails.tags &&
      setEntityTags(
        entityMainDetails.tags.length > 0 ? entityMainDetails.tags : []
      );
  };

  const handleDeleteTags = () => {
    setEntityTags([]);
    setDeleteMode(true);

    const updatedTagsList = tags.map((tag: any) => {
      tag.isChecked = false;
      return tag;
    });
    setTagsList(updatedTagsList);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const updatedTagsList = tags.map((tag: any) => {
      if (tag.id === id) {
        tag.isChecked = e.target.checked;
        return tag;
      } else {
        return tag;
      }
    });

    setDeleteMode(false);
    setTagsList(updatedTagsList);
  };

  useEffect(() => {
    let updatedList =
      tagsList &&
      tagsList.length &&
      tagsList.map((tag: any) => ({
        id: tag.id,
        name: tag.name,
        isChecked: false,
      }));

    updatedList &&
      updatedList.forEach((tag: any) => {
        for (let i = 0; i < entityTags.length; i++) {
          if (entityTags[i].id === tag.id) {
            tag.isChecked = true;
          }
        }
      });
    setTagsList(updatedList);
  }, [dispatch, tagsList, entityTags]);

  const handleSave = () => {
    const entityTagsForSave: any = [];
    tags.forEach((tag: any) => {
      if (tag.isChecked) {
        entityTagsForSave.push(tag);
      }
    });
    setEntityTags(entityTagsForSave);
    dispatch(
      updateEntityRequest(deleteMode, actionTypes, currentEntityId, {
        entityTagsForSave,
      })
    );
  };

  return (
    <>
      <DefaultModal onOpen={handleOpen} nameEvent={types.openEntityTagsModal}>
        {({ closeModal }) => (
          <>
            <ModalHeader title="Set entity tags" />
            <Container>
              <List sx={styles.list}>
                {tags &&
                  tags.length > 0 &&
                  tags.map((tag: any) => (
                    <ListItem key={tag.id} sx={styles.listItem}>
                      <ListItemButton>
                        <ListItemIcon>
                          <FormControlLabel
                            control={
                              <Checkbox
                                edge="end"
                                checked={tag.isChecked || false}
                                onChange={(e) => handleChange(e, tag.id)}
                              />
                            }
                            label=""
                          />
                        </ListItemIcon>
                        <ListItemText>{tag.name}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
              <Button
                sx={styles.buttonContent}
                onClick={() => {
                  handleDeleteTags();
                }}
              >
                Set empty entity tags
              </Button>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.SAVE}
              onSave={handleSave}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const { entityMainDetails } = selectLeadgen(state);
  const { tagsList } = selectSuperAdmin(state);
  return { entityMainDetails, tagsList };
}
export default connect(mapStateToProps)(EntityTagsModal);
