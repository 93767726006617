import ee from "event-emitter";

const Emitter = function () {};

export default ee(Emitter.prototype);

export const types = {
  openCreateUpdateUserModal: "openCreateUpdateUserModal",
  openUserLogsModal: "openUserLogsModal",
  openDeleteUserModal: "openDeleteUserModal",
  openGlobalSearchModal: "openGlobalSearchModal",
  openScheduleCalendarModal: "openScheduleCalendarModal",
  openEntityLabelModal: "openEntityLabelModal",
  openEntityTagsModal: "openEntityTagsModal",
  openEntityResponsibleUserModal: "openEntityResponsibleUserModal",
  openEntityContactsModal: "openEntityContactsModal",
  openEntityManagersModal: "openEntityManagersModal",
  openObjectSearchModal: "openObjectSearchModal",
  openAllObjectUnlinkModal: "openAllObjectUnlinkModal",
  openUnitsDetailsModal: "openUnitsDetailsModal",
  openLeadNoteModal: "openLeadNoteModal",
  openDeleteLabelTagModal: "openDeleteLabelTagModal",
  openDeleteEntityObjectModal: "openDeleteEntityObjectModal",
  openLabelTagCreateModal: "openLabelTagCreateModal",
  openEntityObjectEditModal: "openEntityObjectEditModal",
};
