import { styled } from "@mui/styles";

// eslint-disable-next-line no-empty-pattern
const StyledSearch = styled("div")(({}) => ({
  position: "relative",
  borderBottom: "1px solid #495057",
  "&:hover": {
    borderBottom: "2px solid #495057",
  },
  marginLeft: 0,
  width: "100%",
}));

export default StyledSearch;
