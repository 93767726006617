import { combineReducers } from "redux";
import currentEntity from "./entityDetails";
import entityNotes from "./entityNotes";
import { objectsOwners } from "./objectsStakeholders";
import objects from "./objects";

const leadgen = combineReducers({
  currentEntity,
  entityNotes,
  objectsOwners,
  objects,
});

export default leadgen;
