import { combineReducers } from "redux";
import {
  CREATE_NEW_OBJECT_SUCCESS,
  CREATE_NEW_OBJECT_ERROR,
} from "../../../common/constants/action-constants";

const createNewObject = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_OBJECT_SUCCESS:
      return action.newObject;

    default:
      return state;
  }
};

const createNewObjectError = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_OBJECT_ERROR:
      return action.error;

    default:
      return state;
  }
};

const createObject = combineReducers({
  create: createNewObject,
  error: createNewObjectError,
});

export default createObject;
