import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import styles from "../Modals.styles";
import { modalTypes } from "../../../../constants/field-constants";
import { updateEntityRequest } from "../../../../../modules/leadgen/actions/entityDetails";
import { adminGetUsersRequest } from "../../../../../modules/admin/actions";
import { selectAdmin } from "../../../../../modules/admin/selectors";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";

const EntityResponsibleUserModal = (props: any) => {
  const dispatch = useDispatch();
  const [currentEntityId, setEntityId] = useState("");
  const [responsibleUser, setResponsibleUser] = useState({} as any);
  const [usersList, setUsersList] = useState([] as any);
  const [deleteMode, setDeleteMode] = useState(false);
  const actionTypes = ["responsible_person"];
  const { users, entityMainDetails } = props;

  const handleOpen = (entityId: string) => {
    setEntityId(entityId);
    dispatch(adminGetUsersRequest());
    entityMainDetails &&
      entityMainDetails.responsible_person &&
      entityMainDetails.responsible_person_id &&
      setResponsibleUser(
        entityMainDetails.responsible_person
          ? entityMainDetails.responsible_person
          : {}
      );
  };
  const handleDeleteResponsibleUser = () => {
    setDeleteMode(true);
    setResponsibleUser(
      entityMainDetails.responsible_person &&
        entityMainDetails.responsible_person_id
        ? entityMainDetails.responsible_person
        : {}
    );
    const updatedUsersList = usersList.map((user: any) => {
      user.isChecked = false;
      return user;
    });
    setUsersList(updatedUsersList);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    user: any,
    id: number
  ) => {
    if (e.target.checked) {
      setResponsibleUser(user);
      const updatedUsersList = usersList.map((user: any) => {
        if (user.id === id) {
          user.isChecked = e.target.checked;
          return user;
        } else {
          user.isChecked = false;
          return user;
        }
      });

      setDeleteMode(false);
      setUsersList(updatedUsersList);
    }
  };

  useEffect(() => {
    const updatedList =
      users &&
      users.length &&
      users.map((user: any) => {
        if (responsibleUser && user.id === responsibleUser.id) {
          return {
            id: user.id,
            name: user.name,
            surname: user.surname,
            isChecked: true,
          };
        } else {
          return {
            id: user.id,
            name: user.name,
            surname: user.surname,
            isChecked: false,
          };
        }
      });
    setUsersList(updatedList);
  }, [dispatch, users, responsibleUser]);

  const handleSave = () => {
    dispatch(
      updateEntityRequest(deleteMode, actionTypes, currentEntityId, {
        responsible_person_id: responsibleUser.id,
      })
    );
    setResponsibleUser({});
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        nameEvent={types.openEntityResponsibleUserModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader title="Set entity responsible person" />
            <Container>
              <List sx={styles.list}>
                {usersList &&
                  usersList.length > 0 &&
                  usersList.map((user: any) => (
                    <ListItem key={user.id} sx={styles.listItem}>
                      <ListItemButton>
                        <ListItemIcon>
                          <FormControlLabel
                            control={
                              <Checkbox
                                edge="end"
                                checked={user.isChecked || false}
                                onChange={(e) => handleChange(e, user, user.id)}
                              />
                            }
                            label=""
                          />
                        </ListItemIcon>
                        <ListItemText>{`${user.name} ${
                          user.surname ? user.surname : ""
                        }`}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
              <Button
                sx={styles.buttonContent}
                onClick={() => {
                  handleDeleteResponsibleUser();
                }}
              >
                Set empty responsible user
              </Button>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.SAVE}
              onSave={handleSave}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const { users } = selectAdmin(state);
  const { entityMainDetails } = selectLeadgen(state);
  return { users, entityMainDetails };
}
export default connect(mapStateToProps)(EntityResponsibleUserModal);
