import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import EntitiesBlock from "./components/leadgen-entity/EntitiesBlock";
import { useParams } from "react-router-dom";
import { modelTypes } from "../../common/constants/field-constants";
import ObjectBlock from "./components/leadgen-object/ObjectBlock";
import { connect, useDispatch } from "react-redux";
import { selectLeads } from "../leads/selectors";
import { setLeadId } from "../leads/actions";

const Leadgen = ({ currentLeadId }) => {
  const { model, id } = useParams();
  const dispatch = useDispatch();
  const [leadId, setId] = useState(null);
  const [objectId, setObjectId] = useState(null);

  useEffect(() => {
    if (id && model && model === "entity") {
      dispatch(setLeadId(id));
    }
    if (id && model && model === "object") {
      dispatch(setLeadId(0));
    }
  }, [dispatch, id, model]);

  useEffect(() => {
    setId(currentLeadId);
  }, [currentLeadId, id]);

  useEffect(() => {
    setObjectId(currentLeadId);
  }, [objectId, id, currentLeadId]);

  return (
    <Box>
      {model === modelTypes.ENTITY && leadId && (
        <EntitiesBlock entityId={leadId} />
      )}
      {model === modelTypes.OBJECT && id && <ObjectBlock objectId={id} />}
    </Box>
  );
};

function mapStateToProps(state: any) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { leads, assignedLeadsList, currentLeadId } = selectLeads(state);
  return { leads, currentLeadId };
}

export default connect(mapStateToProps)(Leadgen);
