import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../../entity-editor/EntityEditor.styles";
import StyledTextField from "../../styled-components/StyledTextField";

const ObjectPlotBlock = ({ onChange, objectFields, errors }) => {

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Plot *
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              required
              label="City code"
              margin="dense"
              onChange={(e) => onChange("objectPlot", "city_code", e, -1)}
              value={objectFields?.city_code || ""}
              error={!errors.isFilledCityCode}
            />
            <StyledTextField
              fullWidth
              required
              label="Section"
              margin="dense"
              onChange={(e) => onChange("objectPlot", "section", e, -1)}
              value={objectFields?.section || ""}
              error={!errors.isFilledSection}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              required
              label="Plot number"
              margin="dense"
              onChange={(e) => onChange("objectPlot", "plot_number", e, -1)}
              value={objectFields?.plot_number || ""}
              error={!errors.isFilledPlotNumber}
            />
            <StyledTextField
              fullWidth
              required
              label="Index"
              margin="dense"
              onChange={(e) => onChange("objectPlot", "index", e, -1)}
              value={objectFields?.index || ""}
              error={!errors.isFilledPlotIndex}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ObjectPlotBlock;
