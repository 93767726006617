import {
  SEND_PDF_REQUEST,
  SEND_PDF_SUCCESS,
  SEND_PDF_ERROR,
  CHECK_PDF_USAGE_ERROR,
  SAVE_PDF_SUCCESS,
  CHECK_PDF_USAGE_SUCCESS,
  CANCEL_PDF_ERROR,
  CANCEL_PDF_SUCCESS,
  SAVE_PDF_REQUEST,
  SAVE_PDF_ERROR,
  CHECK_PDF_USAGE_REQUEST,
  CANCEL_PDF_REQUEST,
  SET_UNITS_FOR_SAVE,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";

export const sendPDFRequest = (data, navigate) => {
  return {
    type: SEND_PDF_REQUEST,
    data,
    navigate,
  };
};

export const sendPDFSuccess = (pdfData, navigate) => {
  return {
    type: SEND_PDF_SUCCESS,
    pdfData,
    navigate,
  };
};

export const sendPDFError = (error) => {
  return {
    type: SEND_PDF_ERROR,
    error,
  };
};

export const savePDFRequest = (data, navigate) => {
  return {
    type: SAVE_PDF_REQUEST,
    data,
    navigate,
  };
};

export const savePDFSuccess = (pdfData) => {
  pdfData.data && AlertService.info(pdfData.data.message);
  return {
    type: SAVE_PDF_SUCCESS,
    pdfData,
  };
};

export const savePDFError = (error) => {
  return {
    type: SAVE_PDF_ERROR,
    error,
  };
};

export const checkPDFUsageRequest = (pdfId, navigate) => {
  return {
    type: CHECK_PDF_USAGE_REQUEST,
    pdfId,
    navigate,
  };
};

export const checkPDFUsageSuccess = (message) => {
  return {
    type: CHECK_PDF_USAGE_SUCCESS,
    message,
  };
};

export const checkPDFUsageError = (error) => {
  return {
    type: CHECK_PDF_USAGE_ERROR,
    error,
  };
};

export const unlinkPDFRequest = (pdfData, fileForDriveImport) => {
  return {
    type: CANCEL_PDF_REQUEST,
    pdfData,
    fileForDriveImport,
  };
};

export const unlinkPDFSuccess = (pdfData) => {
  return {
    type: CANCEL_PDF_SUCCESS,
    pdfData,
  };
};

export const unlinkPDFError = (error) => {
  return {
    type: CANCEL_PDF_ERROR,
    error,
  };
};

export const setUnitsForSave = (unitsForSave) => {
  return {
    type: SET_UNITS_FOR_SAVE,
    unitsForSave,
  };
};
