import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import styles from "./ImportPDF.styles";
import AlertService from "../../common/services/notification.service";
import { useNavigate } from "react-router-dom";
import { savePDFRequest, sendPDFRequest, unlinkPDFRequest } from "./actions";
import { selectPDF } from "./selectors";
import Head from "./components/Head";
import Footer from "./components/Footer";
import Main from "./components/Main";
import dayjs from "dayjs";
import { modelTypes } from "../../common/constants/field-constants";

const pdfInitialState = {
  fullList: false,
  lastImportDate: dayjs(new Date()),
  type: "",
  isDead: 3,
  open: false,
  noTypeWarning: false,
  statistic: [],
  entity: {},
  object: {},
  linkedObjects: [],
  objectsForLink: [],
  objectsForUnlink: [],
  objectsForSaveAndLink: [],
  objectsForCheckAndUpdate: [],
  pdfData: {},
  fileForDriveImport: {},
  unitsForSave: [],
  nonResidentOwnersInDB: [],
  nonResidentOwnersNotInDB: [],
  residentOwnersInDB: [],
  residentOwnersNotInDB: [],
};

const ImportPDF = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pdfState, setPdfState] = useState(pdfInitialState);

  const { isFetching, pdfData, parsedPDFData, fileForDriveImport } = props;

  useEffect(() => {
    setPdfState({
      fullList: false,
      lastImportDate: dayjs(new Date()),
      type: "",
      isDead:
        parsedPDFData?.entity?.is_dead &&
        parsedPDFData?.entity?.is_dead === "true"
          ? 2
          : 1 || 3,
      open: false,
      noTypeWarning: false,
      pdfData: pdfData,
      statistic: parsedPDFData.statistic,
      entity: parsedPDFData.entity,
      object: parsedPDFData.object,
      linkedObjects: parsedPDFData.linkedObjects || [],
      objectsForLink: parsedPDFData.objectsForLink || [],
      objectsForUnlink: parsedPDFData.objectsForUnlink || [],
      objectsForSaveAndLink: parsedPDFData.objectsForSaveAndLink || [],
      objectsForCheckAndUpdate: parsedPDFData.objectsForCheckAndUpdate || [],
      fileForDriveImport: fileForDriveImport || {},
      unitsForSave: pdfData.unitsForSave || [],
      nonResidentOwnersInDB: parsedPDFData.object?.nonResidentOwnersInDB || [],
      nonResidentOwnersNotInDB:
        parsedPDFData.object?.nonResidentOwnersNotInDB || [],
      residentOwnersInDB: parsedPDFData.object?.residentOwnersInDB || [],
      residentOwnersNotInDB: parsedPDFData.object?.residentOwnersNotInDB || [],
    });
  }, [parsedPDFData, pdfData, fileForDriveImport]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const formData = new FormData();

      const type = acceptedFiles[0].type.split("/").slice(-1)[0];

      if (type.toLowerCase() !== "pdf")
        return AlertService.warning("not correct file format");

      const fileName = acceptedFiles[0].name;
      formData.append("pdf", acceptedFiles[0], fileName);

      dispatch(sendPDFRequest(formData, navigate));
    },
    [dispatch, navigate]
  );

  const handleChange = (type, e) => {
    if (type === "isFullList") {
      if (e.target.checked) {
        return setPdfState({
          ...pdfState,
          linkedObjects: [],
          objectsForUnlink: [
            ...pdfState.objectsForUnlink,
            ...pdfState.linkedObjects,
          ],
          fullList: e.target.checked,
        });
      } else if (!e.target.checked) {
        return setPdfState({
          ...pdfState,
          linkedObjects: parsedPDFData.linkedObjects,
          objectsForUnlink: parsedPDFData.objectsForUnlink,
          fullList: e.target.checked,
        });
      }
    }

    if (type === "entity_type") {
      if (!e.target) {
        return setPdfState({
          ...pdfState,
          noTypeWarning: true,
        });
      } else {
        return setPdfState({
          ...pdfState,
          entity: {
            ...pdfState.entity,
            entity_type: e.target.value,
          },
        });
      }
      // if (pdfState.pdfData !== "") {
      //   setPdfState({
      //     ...pdfState,
      //     noTypeWarning: false,
      //   });
      // }
    }

    if (type === "is_dead") {
      setPdfState({
        ...pdfState,
        // @ts-ignore
        isDead: e.target.value,
        entity: {
          ...pdfState.entity,
          is_dead:
            e.target.value === 1 ? false : e.target.value === 2 ? true : null,
        },
      });
    }

    if (type === "lastImportDate") {
      return setPdfState({
        ...pdfState,
        lastImportDate: e,
      });
    }
  };

  const handleCancel = () => {
    dispatch(unlinkPDFRequest(pdfData, fileForDriveImport));
    setPdfState(pdfInitialState);
  };

  const handleSave = () => {
    if (entity) {
      dispatch(
        savePDFRequest(
          {
            object,
            entity,
            fileForDriveImport,
            fullList,
            linkedObjects,
            objectsForLink,
            objectsForSaveAndLink,
            objectsForUnlink,
            unitsForSave,
          },
          navigate
        )
      );
    } else if (object) {
      dispatch(
        savePDFRequest(
          {
            object,
            fileForDriveImport,
            // owners,
          },
          navigate
        )
      );
    }

    setPdfState(pdfInitialState);
  };

  const {
    object,
    entity,
    statistic,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type,
    isDead,
    linkedObjects,
    objectsForUnlink,
    objectsForLink,
    objectsForSaveAndLink,
    objectsForCheckAndUpdate,
    fullList,
    lastImportDate,
    unitsForSave,
    nonResidentOwnersInDB,
    nonResidentOwnersNotInDB,
    residentOwnersInDB,
    residentOwnersNotInDB,
  } = pdfState || {};

  return (
    <Paper sx={styles.content}>
      {isFetching ? (
        <Box sx={styles.wrapperSpinner}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          {Object.keys(pdfState).length &&
          entity &&
          Object.keys(entity).length ? (
            <Box sx={styles.body}>
              <Head
                fullList={fullList}
                lastImportDate={lastImportDate}
                isDead={isDead}
                entity={entity}
                object={object}
                statistic={statistic}
                onChange={handleChange}
              />
              <Main
                objectsForUnlink={objectsForUnlink}
                objectsForLink={objectsForLink}
                linkedObjects={linkedObjects}
                objectsForSaveAndLink={objectsForSaveAndLink}
                objectsForCheckAndUpdate={objectsForCheckAndUpdate}
                type={modelTypes.ENTITY}
              />
              <Footer onCancel={handleCancel} handleSave={handleSave} />
            </Box>
          ) : Object.keys(pdfState).length &&
            object &&
            Object.keys(object).length ? (
            <Box sx={styles.body}>
              <Head
                fullList={fullList}
                lastImportDate={lastImportDate}
                object={object}
                statistic={statistic}
                onChange={handleChange}
              />
              <Main
                nonResidentOwnersInDB={nonResidentOwnersInDB}
                nonResidentOwnersNotInDB={nonResidentOwnersNotInDB}
                residentOwnersInDB={residentOwnersInDB}
                residentOwnersNotInDB={residentOwnersNotInDB}
                type={modelTypes.OBJECT}
              />
              <Footer onCancel={handleCancel} handleSave={handleSave} />
            </Box>
          ) : (
            <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <Box {...getRootProps()} sx={styles.contentHolder}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Typography
                      sx={styles.dragWrapperIsActive}
                      variant="subtitle1"
                    >
                      Drop files here...
                    </Typography>
                  ) : (
                    <Typography sx={styles.wrapperText} variant="subtitle1">
                      Try dropping some files here, or click to select files to
                      upload.
                    </Typography>
                  )}
                </Box>
              )}
            </Dropzone>
          )}
        </>
      )}
    </Paper>
  );
};

function mapStateToProps(state) {
  // @ts-ignore
  const {
    pdfData,
    objectsForCheckbox,
    isFetching,
    parsedPDFData,
    fileForDriveImport,
    savePDF,
    // @ts-ignore
  } = selectPDF(state);
  return {
    pdfData,
    objectsForCheckbox,
    isFetching,
    parsedPDFData,
    fileForDriveImport,
    savePDF,
  };
}

export default connect(mapStateToProps)(ImportPDF);
