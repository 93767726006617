import { alpha, styled, Switch } from "@mui/material";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "rgba(17,75,118, 1)",
    "&:hover": {
      backgroundColor: alpha(
        "rgba(17,75,118, 1)",
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "rgba(17,75,118,0.8)",
  },
}));

export default BlueSwitch;
