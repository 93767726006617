import moment from "moment";
import "moment/locale/en-gb";

moment.locale("en-gb");

export const formatDateAndTime = (date) =>
  moment(date).format("HH:mm, DD-MM-YYYY");

export const formatDateAndTimeTable = (date) =>
  moment(date).format("DD-MM-YYYY/HH:mm");

export const formatDate = (date) => moment(date).format("YYYY-MM-DD");

export const formatImportDate = (date) => moment(date).format("DD-MM-YYYY");

export const formatBirthDate = (date) => moment(date).format("LL");

export const formatTime = (date) => moment(date).format("HH:mm");

export const getDifferenceInDays = (date) => {
  // except hours
  // @ts-ignore
  const ms = new Date(date) - new Date();
  const day = 1000 * 60 * 60 * 24;
  return Math.ceil(ms / day);
};

export const getDateFromNow = (date) => {
  const d = new Date(date);
  const res = getDifferenceInDays(date);

  const formatedDate = moment(d).format("LL").toLowerCase();

  if (res === 0) return { label: "Today", date: formatedDate, type: "in" };
  else if (res === 1)
    return { label: "Tomorrow", date: formatedDate, type: "in" };
  else if (res === -1)
    return { label: "Yesterday", date: formatedDate, type: "ago" };

  // d.setDate(d.getDate() + 1);

  const str = moment(d, "YYYYMMDD").fromNow();

  if (str.includes("in")) {
    const formatedLabel = str.split(" ").slice(1).join(" ");

    return {
      label: `${formatedLabel} from now`,
      date: formatedDate,
      type: "in",
    };
  }

  return { label: str, date: formatedDate, type: "ago" };
};
