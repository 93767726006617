import { combineReducers } from "redux";
import {
  SEARCH_GET_ENTITY_OBJECT_SUCCESS,
  SEARCH_GET_ENTITY_OBJECT_ERROR,
  SEARCH_RECEIVE_CITY_ERROR,
  SEARCH_RECEIVE_CITY_SUCCESS,
  SEARCH_GET_ENTITY_SUCCESS,
  SEARCH_GET_ENTITY_ERROR,
  SEARCH_GET_ENTITY_OBJECT_REQUEST,
  SEARCH_GET_OBJECT_SUCCESS,
  SEARCH_GET_OBJECT_ERROR,
  SEARCH_GET_ENTITY_REQUEST,
  SEARCH_GET_OBJECT_REQUEST,
} from '../../../common/constants/action-constants';

const initialState = {
  isFetching: false,
}

const receiveSearchProcessing = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_GET_ENTITY_OBJECT_REQUEST:
    case SEARCH_GET_ENTITY_REQUEST:
    case SEARCH_GET_OBJECT_REQUEST:
      return {
        isFetching: true,
      };
    case SEARCH_GET_ENTITY_OBJECT_SUCCESS:
    case SEARCH_GET_ENTITY_SUCCESS:
    case SEARCH_GET_OBJECT_SUCCESS:
      return {
        isFetching: false,
      };

    default:
      return state;
  }
};

const receiveSearchResult = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_GET_ENTITY_OBJECT_SUCCESS:
      return action.result;

    default:
      return state;
  }
};

const receiveSearchEntitiesResult = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_GET_ENTITY_SUCCESS:
      return action.result;

    default:
      return state;
  }
};

const receiveSearchObjectsResult = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_GET_OBJECT_SUCCESS:
      return action.result;

    default:
      return state;
  }
};

const receiveSearchCitiesResult = (state = [], action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_CITY_SUCCESS:
      return action.result;

    default:
      return state;
  }
}

const errorSearchResult = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_GET_ENTITY_OBJECT_ERROR:
    case SEARCH_GET_ENTITY_ERROR:
    case SEARCH_GET_OBJECT_ERROR:
    case SEARCH_RECEIVE_CITY_ERROR:
      return action.error;

    default:
      return state;
  }
};

const searchResult = combineReducers({
  receiveSearchProcessing,
  receiveSearchResult,
  receiveSearchCitiesResult,
  entitiesResult: receiveSearchEntitiesResult,
  objectsResult: receiveSearchObjectsResult,
  error: errorSearchResult,
});

export default searchResult;