const styles = {
  root: {
    flexGrow: 1,
    // maxWidth: "1366px",
    margin: "0 auto",
  },
  cardHolder: {
    marginBottom: "32px",
  },
  chartHolder: {
    // maxHeight: "530px",
    marginBottom: "32px",
  },
  userInfo: {
    marginBottom: "32px",
    position: "relative",
  },
  boxImage: {
    display: "block",
    position: "absolute",
    top: "10px",
    right: 0,
  },
  boxImages: {
    width: "125px",
  },
  userInfoHeader: {
    backgroundColor: "secondary.light",
  },
  dealsStatistic: {
    display: "flex",
    padding: "15px 15px 0 15px",
    marginBottom: "32px",
  },
  cardHeader: {
    height: "125px",
    fontFamily: "typography.fontFamily",
    color: "#015A9C",
    fontWeight: "medium",
    fontSize: 22,
    padding: "15px 0 0 15px",
  },
  avatarStack: {
    display: "block",
    position: "absolute",
    top: "85px",
    left: "20px",
  },
  cardContent: {
    display: "flex",
    fontFamily: "typography.fontFamily",
    paddingTop: "50px",
  },
  cardContentNoPadding: {
    display: "flex",
    alignItems: "center",
    fontFamily: "typography.fontFamily",
  },
  contentTitle: {
    paddingTop: "17px",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  contentSubTitle: {
    fontSize: "12px",
    fontWeight: "300",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  holder: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
  holderAssigned: {
    display: "flex",
    flexDirection: "column",
    marginRight: "15px",
  },
  contentAssigned: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  subContentAssigned: {
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#74788D",
  },
  contentResults: {
    flexGrow: "1",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  tableHeader: {
    padding: "15px 0 0 15px",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    fontFamily: "typography.fontFamily",
    color: "#495057",
  },
  buttonToday: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    textTransform: "none",
    color: "#495057",
  },
  buttonWeek: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    textTransform: "none",
    backgroundColor: "#015A9C",
    color: "white",
  },
  cardContentResults: {
    display: "flex",
    flexGrow: "1",
  },
  cardTypography: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
    marginBottom: "5px",
  },
  cardTypography1: {
    paddingTop: "25px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "typography.fontFamily",
    color: "#495057",
    marginBottom: "5px",
  },
  contentContainer: {
    padding: 0,
    // height: "335px",
    height: {
      xs: "200px",
      sm: "200px",
      md: "300px",
      lg: "358px",
    },
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
};

export default styles;
