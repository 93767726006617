import { combineReducers } from "redux";
import {
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
} from "../../../common/constants/action-constants";

const receiveUser = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_SUCCESS:
      return action.user;

    default:
      return state;
  }
};

const errorFetchUser = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_ERROR:
      return {
        error: action.error,
      };

    default:
      return state;
  }
};

const login = combineReducers({
  receive: receiveUser,
  error: errorFetchUser,
});

export default login;
