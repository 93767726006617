import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  DELETE_ENTITY_NOTE_REQUEST,
  RECEIVE_ENTITY_NOTES_REQUEST,
  UPDATE_ENTITY_NOTE_REQUEST,
} from "../../../common/constants/action-constants";
import {
  receiveEntityNotesError,
  receiveEntityNotesSuccess,
  removeNoteError,
  removeNoteSuccess,
  updateNoteError,
  updateNoteSuccess,
} from "../actions/entityNotes";

function* getNote(action: any) {
  try {
    const note = yield call(ApiService.get, `/notes/${action.entity_id}`);
    if (note) {
      yield put(receiveEntityNotesSuccess(note.data));
    }
  } catch (e) {
    yield put(receiveEntityNotesError(e));
  }
}
function* createUpdateNote(action: any) {
  try {
    const note = yield call(ApiService.post, `/notes/data`, action.data);
    if (note) {
      yield put(updateNoteSuccess(note.data.currentNote, note.data.message));
    }
  } catch (e) {
    yield put(updateNoteError(e));
  }
}
function* deleteNote(action: any) {
  try {
    const result = yield call(ApiService.delete, `/notes/${action.id}`);
    if (result && result.data.message) {
      yield put(removeNoteSuccess(result.data.message));
    }
  } catch (e) {
    yield put(removeNoteError(e));
  }
}

export default function* actionNotes() {
  yield takeLatest(RECEIVE_ENTITY_NOTES_REQUEST, getNote);
  yield takeLatest(UPDATE_ENTITY_NOTE_REQUEST, createUpdateNote);
  yield takeLatest(DELETE_ENTITY_NOTE_REQUEST, deleteNote);
}
