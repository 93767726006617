import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../../entity-editor/EntityEditor.styles";
import React from "react";
import StyledTextField from "../../styled-components/StyledTextField";

const ObjectGeneralFieldsBlock = ({ onChange, objectFields, errors }) => {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          General fields
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              required
              label="Kadaster ID"
              margin="dense"
              onChange={(e) => onChange("generalFields", "kadaster_id", e, -1)}
              value={objectFields?.kadaster_id}
              error={!errors.isFilledKadId}
            />
            <StyledTextField
              fullWidth
              label="Kadaster link"
              margin="dense"
              onChange={(e) => onChange("generalFields", "kadaster_url", e, -1)}
              value={objectFields?.kadaster_url}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Price"
              margin="dense"
              onChange={(e) => onChange("generalFields", "price", e, -1)}
              value={objectFields?.price}
            />
            <StyledTextField
              fullWidth
              label="Restrictions"
              margin="dense"
              onChange={(e) => onChange("generalFields", "restrictions", e, -1)}
              value={objectFields?.restrictions}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ObjectGeneralFieldsBlock;
