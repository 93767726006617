import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';

import styles from '../Modals.styles';
import DefaultModal from '../../default-modal/DefaultModal';
import { types } from '../../../helpers/eventEmitter';
import ModalHeader from '../../modal-header/ModalHeader';
import { selectLeadgen } from '../../../../../modules/leadgen/selectors';
import ModalFooter from '../../modal-footer/ModalFooter';
import { modalTypes } from '../../../../constants/field-constants';
import { removeEntityRequest } from '../../../../../modules/leadgen/actions/entityDetails';
import { removeObjectRequest } from '../../../../../modules/leadgen/actions/objects';


const EntityObjectDeleteModal = (props: any) => {
  const dispatch = useDispatch();
  const { entityMainDetails, currentObject} = props;

  const [deleteId, setDeleteId] = useState(null);
  const [typeDelete, setTypeDelete] = useState(null);

  const handleOpen = (type, id) => {
    setTypeDelete(type);
    setDeleteId(id);
  };
  useEffect(() => {
    setDeleteId(null);
  }, [dispatch]);

  const handleDelete = (id: number | null) => {
    if (typeDelete === 'entityDelete') {
      dispatch(removeEntityRequest(id));
    }
    if (typeDelete === 'objectDelete') {
      dispatch(removeObjectRequest(id));
    }
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        nameEvent={types.openDeleteEntityObjectModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader
              title={
                typeDelete === 'entityDelete' ? 'REMOVE ENTITY' : 'REMOVE OBJECT'
              }
            />
            <Box sx={styles.dataAppForDeleteModal}>
              <Typography sx={styles.contentContainer}>
                {`Are you sure you want to delete ${
                  typeDelete === 'entityDelete' ? 'entity' : 'object'
                }`}
              </Typography>
              <Typography sx={styles.contentContainer}>
                {typeDelete === 'entityDelete' ?
                  (entityMainDetails.first_name ?
                    `${entityMainDetails.first_name}`
                    : `${entityMainDetails.last_name}`)
                  : (`${currentObject.city}, ${currentObject.objectAddress}`)}
              </Typography>
              <Typography sx={styles.contentContainer}>
                {`from the database?`}
              </Typography>
            </Box>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.DELETE}
              onSave={() => handleDelete(deleteId)}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state) {
  const { entityMainDetails, currentObject } = selectLeadgen(state);
  return { entityMainDetails, currentObject};
}

export default connect(mapStateToProps)(EntityObjectDeleteModal);