import { combineReducers } from "redux";
import {
  DELETE_ENTITY_NOTE_ERROR,
  RECEIVE_ENTITY_NOTES_ERROR,
  RECEIVE_ENTITY_NOTES_SUCCESS,
  UPDATE_ENTITY_NOTE_ERROR,
  UPDATE_ENTITY_NOTE_SUCCESS,
} from "../../../common/constants/action-constants";

const receiveNotes = (state = {}, action: any) => {
  switch (action.type) {
    case RECEIVE_ENTITY_NOTES_SUCCESS:
    case UPDATE_ENTITY_NOTE_SUCCESS:
      return action.entityNote;
    default:
      return state;
  }
};

const notesError = (state = {}, action: any) => {
  switch (action.type) {
    case RECEIVE_ENTITY_NOTES_ERROR:
    case UPDATE_ENTITY_NOTE_ERROR:
    case DELETE_ENTITY_NOTE_ERROR:
      return action.error;
    default:
      return state;
  }
};

const entityNotes = combineReducers({
  entityNote: receiveNotes,
  error: notesError,
});

export default entityNotes;
