import * as React from "react";
import { connect } from "react-redux";
import { Avatar } from "@mui/material";
import { selectUser } from "../../../modules/login/selectors";
import { selectAdmin } from "../../../modules/admin/selectors";
import { propsTypes } from "../../constants/field-constants";
import styles from "./UserAvatar.styles";

interface DashboardProps {
  stylesType: string;
  userData: {
    userAvatar?: string;
    userInitials?: string;
  };
  users: {
    selectedUserAvatar?: string;
    selectedUserInitials?: string;
  };
}

const UserAvatar = ({ userData, users, stylesType }: DashboardProps) => {
  const { userAvatar, userInitials } = userData;
  const { selectedUserAvatar, selectedUserInitials } = users;

  return (
    <>
      {stylesType === propsTypes.ADMIN_PANEL ? (
        <Avatar
          alt="User avatar"
          src={selectedUserAvatar}
          sx={styles.avatarAdminPanel}
        >
          {selectedUserInitials}
        </Avatar>
      ) : stylesType === propsTypes.DASHBOARD ? (
        <Avatar alt="User avatar" src={userAvatar} sx={styles.cardAvatar}>
          {userInitials}
        </Avatar>
      ) : stylesType === propsTypes.LEADGEN ? (
        <Avatar alt="User avatar" sx={styles.leadgenEntityRatingAvatar}>
          93.73
        </Avatar>
      ) : (
        <Avatar alt="User avatar" src={userAvatar} sx={styles.defaultAvatar}>
          {userInitials}
        </Avatar>
      )}
    </>
  );
};
function mapStateToProps(state) {
  const userData = selectUser(state);
  const users = selectAdmin(state);
  return { userData, users };
}

export default connect(mapStateToProps)(UserAvatar);
