const styles = {
  dataApp: {
    height: "60px",
    background: "#114B76",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
    marginBottom: "12px",
  },
  headerContent: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#FFF",
    margin: "0 auto",
  },
};

export default styles;
