export const propsTypes = {
  DEFAULT: "default",
  DASHBOARD: "dashboard",
  ADMIN_PANEL: "adminPanel",
  LEADGEN: "leadgen",
};

export const userTypes = {
  SUPER_ADMIN: "Superadmin",
  ADMIN: "Administrator",
  USER: "User",
};

export const modalTypes = {
  CREATE: "create",
  DELETE: "delete",
  UNLINK: "unlink",
  SAVE: "save",
  UPDATE: "update",
  INFO: "info",
};

export const entityTypes = {
  PERSON: "person",
  COMPANY: "company",
};

export const lpsFormTypes = {
  INDUSTRIAL: "industrial",
  NON_INDUSTRIAL: "non-industrial",
  KADASTER: "kadaster",
};

export const modelTypes = {
  ENTITY: "entity",
  OBJECT: "object",
};
