import { createSelector } from "reselect";

const superAdminState = (state) => {
  if (state && state.superAdmin) {
    return state.superAdmin;
  }
  return null;
};

const superAdminSelector = createSelector(
  [superAdminState],
  (state) => state // return superAdmin
);

const labelsSelector = createSelector(
  [superAdminSelector],
  (state) => state.labelsList
);

const tagsSelector = createSelector(
  [superAdminSelector],
  (state) => state.tagsList
);

export const selectSuperAdmin: any = createSelector(
  [superAdminSelector, labelsSelector, tagsSelector],
  (superAdmin, labelsList, tagsList) => {
    return {
      superAdmin,
      labelsList,
      tagsList,
    };
  }
);
