import * as React from "react";
import { Box } from "@mui/material";
import styles from "./LoginLayout.styles";
import Login from "../../modules/login/Login";

const LoginLayout = () => {
  return (
    <Box sx={styles.layoutWrapper}>
      <Box component="main" sx={styles.main}>
        <Login />
      </Box>
    </Box>
  );
};

export default LoginLayout;
