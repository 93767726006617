import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "../GlobalSearch.styles";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Tooltip,
  Typography,
} from "@mui/material";
import { selectSearch } from "../selectors";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import StyledInputBase from "../../../common/components/styled-components/StyledInputBase";
import StyledSearchIconWrapper from "../../../common/components/styled-components/StyledSearchIconWrapper";
import StyledSearch from "../../../common/components/styled-components/StyledSearch";
import { findEntityByFiltersRequest } from "../actions/search";
import BlueSwitch from "../../../common/components/styled-components/Blueswitch";

const EntitySearch = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const [preparedToDisplayResult, setShowList] = useState(false);
  const [filters, setFilters] = useState({ byKadId: false });
  const { localSearchEntityResult, handleChangeList, processing } = props;

  useEffect(() => {
    let active = true;
    let newOptions: any = [];

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (active) {
      if (value) {
        newOptions = [value];
      }
      if (localSearchEntityResult) {
        newOptions = [...newOptions, ...localSearchEntityResult];
      }

      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [dispatch, localSearchEntityResult, value, inputValue]);

  const handleChange = (newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 2) {
      const requestData = {
        ...filters,
        inputValue: newInputValue,
        limit: 5,
      };
      !value && dispatch(findEntityByFiltersRequest(requestData));
    }
  };

  const handleClear = () => {
    setInputValue("");
    setValue(null);
  };

  const getResultsList = () => {
    setInputValue("");
    setShowList(true);
    setOpen(false);
  };

  return (
    <Box sx={{ marginBottom: "20px", marginTop: "5px" }}>
      <Box sx={styles.filters}>
        <FormControlLabel
          value="end"
          control={<BlueSwitch />}
          label="Search by kad ID"
          labelPlacement="end"
          //@ts-ignore
          // onChange={(e) => handleChecked("ip_restriction", e, -1)}
          // checked={user.ip_restriction}
          // defaultChecked={initialUserState.ip_restriction}
          style={{ color: "#495057" }}
        />
        <Box sx={styles.filters}>
          <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
            SHOW:
          </Typography>
          <ButtonGroup variant="text" aria-label="Basic button group">
            <Button sx={styles.splitButton}>+5</Button>
            <Button sx={styles.splitButton}>-5</Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Autocomplete
        open={open && inputValue.length >= 2}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        loading={processing}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        blurOnSelect
        fullWidth
        getOptionLabel={(option) => {
          return option.name && option.age
            ? `${option.name} ${option.age}`
            : option.name && !option.age
            ? `${option.name}`
            : `${option.street}`;
        }}
        onChange={(e, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          // setValue(newValue);
          handleChangeList(newValue);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex" }}>
                  {option.name ? (
                    <PersonIcon sx={styles.resultIcon} />
                  ) : (
                    <ApartmentIcon sx={styles.resultIcon} />
                  )}
                  <Grid item>
                    <Typography>
                      {option.name && option.age
                        ? `${option.name}, ${option.age} y.o.`
                        : option.name && !option.age
                        ? `${option.name}`
                        : `${option.street}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </li>
          );
        }}
        onInputChange={(e, newInputValue) => handleChange(newInputValue)}
        renderInput={(params) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledSearch ref={params.InputProps.ref}>
              <StyledSearchIconWrapper>
                <SearchIcon sx={{ color: "#495057" }} />
              </StyledSearchIconWrapper>
              <StyledInputBase
                {...params}
                // onKeyDown={(e) => getResultsList()}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Search",
                }}
              />
            </StyledSearch>
            {inputValue.length > 0 && (
              <Tooltip title={"Clear filed"}>
                <IconButton onClick={handleClear}>
                  <CloseIcon sx={{ color: "#495057" }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

function mapStateToProps(state) {
  // @ts-ignore
  const { localSearchEntityResult, processing } = selectSearch(state);
  return { localSearchEntityResult, processing };
}

export default connect(mapStateToProps)(EntitySearch);
