import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  findEntityAndObjectByFiltersError,
  findEntityAndObjectByFiltersSuccess,
  findEntityByFiltersError,
  findEntityByFilterSuccess,
  findObjectByFiltersError,
  findObjectByFilterSuccess,
  receiveCityError,
  receiveCitySuccess,
} from "../actions/search";
import {
  SEARCH_GET_ENTITY_OBJECT_REQUEST,
  SEARCH_GET_ENTITY_REQUEST,
  SEARCH_GET_OBJECT_REQUEST,
  SEARCH_RECEIVE_CITY_REQUEST,
} from "../../../common/constants/action-constants";

function* searchFindEntityObject(action) {
  try {
    const result = yield call(ApiService.post, "/search", action.requestData);
    yield put(findEntityAndObjectByFiltersSuccess(result.data));
  } catch (error) {
    yield put(findEntityAndObjectByFiltersError(error));
  }
}

function* searchFindEntity(action) {
  try {
    const result = yield call(
      ApiService.post,
      "/search/entities",
      action.requestData
    );
    yield put(findEntityByFilterSuccess(result.data));
  } catch (error) {
    yield put(findEntityByFiltersError(error));
  }
}

function* searchFindObject(action) {
  try {
    const result = yield call(
      ApiService.post,
      "/search/objects",
      action.requestData
    );
    yield put(findObjectByFilterSuccess(result.data));
  } catch (error) {
    yield put(findObjectByFiltersError(error));
  }
}

function* searchReceiveCityName(action) {
  try {
    const result = yield call(
      ApiService.post,
      "/search/city",
      action.requestData
    );
    yield put(receiveCitySuccess(result.data));
  } catch (error) {
    yield put(receiveCityError(error));
  }
}

export default function* actionGlobalSearch() {
  yield takeLatest(SEARCH_GET_ENTITY_OBJECT_REQUEST, searchFindEntityObject);
  yield takeLatest(SEARCH_GET_ENTITY_REQUEST, searchFindEntity);
  yield takeLatest(SEARCH_GET_OBJECT_REQUEST, searchFindObject);
  yield takeLatest(SEARCH_RECEIVE_CITY_REQUEST, searchReceiveCityName);
}
