const { REACT_APP_CLIENT_ID } = process.env;

export default class UserService {
  static set setToken(token) {
    localStorage.setItem("token", token);
  }
  static set setLPC(lpc) {
    localStorage.setItem("lpc_data", lpc);
  }
  static set setLPCIdent(ident) {
    localStorage.setItem("lpc_calc_ident", ident);
  }
  static delLPCIdent() {
    localStorage.removeItem("lpc_calc_ident");
  }
  static get LPCIdent() {
    return localStorage.getItem("lpc_calc_ident");
  }
  static get LPC() {
    return localStorage.getItem("lpc_data");
  }
  static get token() {
    return localStorage.getItem("token");
  }
  static get googleClient() {
    return {
      id: REACT_APP_CLIENT_ID,
    };
  }
  static logout() {
    localStorage.removeItem("token");
  }
}
