import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./404.styles";

const NotFoundPage = () => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.typography}>
        404 error
      </Typography>
      <Typography variant="h4" sx={styles.typography}>
        Page not found
      </Typography>
      <Box sx={styles.imgBg}>
        <img src="images/error-page.png" alt="error" />
      </Box>
    </Box>
  );
};

export default NotFoundPage;
