const styles = {
  searchContainer: {
    // fontFamily: "Poppins",
    maxWidth: {
      xs: "90vw",
      md: "80vw",
      lg: "60vw",
    },
    width: "100%",
    height: {
      xs: "70vh",
    },
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  filterBlockHolder: {
    width: "24%",
    padding: "0 10px 10px 1px",
    height: "inherit",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: "10px",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  filtersHolder: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: "15px",
  },
  filters: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchBlockHolder: {
    width: "75%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  textField: {
    color: "#495057",
    fontFamily: "Poppins",
  },
  checkBoxLabel: {
    color: "#495057",
    fontFamily: "Poppins",
    fontSize: "15px",
  },
  typography: {
    marginBottom: "10px",
    fontSize: "18px",
    color: "#495057",
  },
  filterButtonsHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "5px",
  },
  buttonSave: {
    width: "100%",
    height: "40px",
    background: "rgba(4, 98, 54, 0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(4, 98, 54, 0.9)",
      borderColor: "#046236",
      boxShadow: "none",
    },
  },
  buttonCansel: {
    width: "100%",
    height: "40px",
    background: "rgba(17,75,118,0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.9)",
      borderColor: "#114B76",
      boxShadow: "none",
    },
  },
  buttonFind: {
    marginLeft: "5px",
    width: "10%",
    height: "30px",
    background: "rgba(17,75,118,0.8)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "1.25em",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.9)",
      borderColor: "#114B76",
      boxShadow: "none",
    },
  },
  resultIcon: {
    color: "#B4CEE5",
    marginRight: "5px",
  },
  tableHolder: {
    marginTop: "15px",
    // minWidth: 550,
    width: "100%",
    height: "832px",
    overflow: "hidden",
    overflowX: "auto",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  splitButton: {
    // color: "#F8F8FB",
    color: "rgba(17,75,118,0.8)",
    width: "30px",
    // backgroundColor: "#658EB1",
    // backgroundColor: "rgba(17,75,118,0.8)",
    // borderColor: "#114B76",
    "&:hover": {
      // backgroundColor: "rgba(17,75,118,0.7)",
      boxShadow: "none",
    },
    // color: "#F8F8FB",
    // width: "90px",
    // backgroundColor: "#658EB1",
    // borderColor: "#114B76",
    // "&:hover": {
    //   backgroundColor: "#658EB1",
    //   boxShadow: "none",
    // },
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: "#495057",
  },

  spinner: {
    display: "flex",
    margin: "0 auto",
    color: "#114B76",
  },
};

export default styles;
