import { combineReducers } from "redux";
import {
  CREATE_NEW_ENTITY_SUCCESS,
  CREATE_NEW_ENTITY_ERROR,
} from "../../../common/constants/action-constants";

const createNewEntity = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_ENTITY_SUCCESS:
      return action.newEntity;

    default:
      return state;
  }
};

const createNewEntityError = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_ENTITY_ERROR:
      return action.error;

    default:
      return state;
  }
};

const createEntity = combineReducers({
  create: createNewEntity,
  error: createNewEntityError,
});

export default createEntity;
