import {
  GET_OBJECTS_BY_OWNER_ERROR,
  GET_OBJECTS_BY_OWNER_REQUEST,
  GET_OBJECTS_BY_OWNER_SUCCESS,
  GET_OBJECT_BY_ID_REQUEST,
  GET_OBJECT_BY_ID_SUCCESS,
  GET_OBJECT_BY_ID_ERROR,
  SHOW_OBJECT_ADDRESS_ON_MAP,
  DELETE_OBJECT_REQUEST,
  DELETE_OBJECT_SUCCESS,
  DELETE_OBJECT_ERROR,
  SET_OBJECT_DELETE,
  RESET_OBJECT_ADDRESS_ON_MAP, UPDATE_OBJECT_REQUEST, UPDATE_OBJECT_SUCCESS, UPDATE_OBJECT_ERROR,
} from "../../../common/constants/action-constants";
import AlertService from "../../../common/services/notification.service";

export const receiveObjectsByOwnerRequest = (id) => {
  return {
    type: GET_OBJECTS_BY_OWNER_REQUEST,
    id,
  };
};

export const receiveObjectsByOwnerSuccess = (objects) => {
  return {
    type: GET_OBJECTS_BY_OWNER_SUCCESS,
    objects,
  };
};

export const receiveObjectsByOwnerError = (error) => {
  return {
    type: GET_OBJECTS_BY_OWNER_ERROR,
    error,
  };
};

export const receiveObjectByIdRequest = (id) => {
  return {
    type: GET_OBJECT_BY_ID_REQUEST,
    id,
  };
};

export const receiveObjectByIdSuccess = (object) => {
  return {
    type: GET_OBJECT_BY_ID_SUCCESS,
    object,
  };
};

export const receiveObjectByIdError = (error) => {
  return {
    type: GET_OBJECT_BY_ID_ERROR,
    error,
  };
};

export const showAddressOnMap = (address) => {
  return {
    type: SHOW_OBJECT_ADDRESS_ON_MAP,
    address,
  };
};

export const resetAddressOnMap = (address) => {
  return {
    type: RESET_OBJECT_ADDRESS_ON_MAP,
    address,
  };
};

export const removeObjectRequest = (id) => {
  return {
    type: DELETE_OBJECT_REQUEST,
    id,
  };
};

export const removeObjectSuccess = (message) => {
  AlertService.success(message);
  return {
    type: DELETE_OBJECT_SUCCESS,
  };
};

export const removeObjectError = (error) => {
  return {
    type: DELETE_OBJECT_ERROR,
    error,
  };
};

export const setObjectDelete = () => {
  return {
    type: SET_OBJECT_DELETE,
  };
};

export const updateObjectRequest = (actionTypes, objectId, data) => {
  return {
    type: UPDATE_OBJECT_REQUEST,
    actionTypes,
    objectId,
    data,
  }
};

export const updateObjectSuccess = (object, message) => {
  AlertService.success(message);
  return {
    type: UPDATE_OBJECT_SUCCESS,
    object,
  };
};

export const updateObjectError = (error) => {
  return {
    type: UPDATE_OBJECT_ERROR,
    error,
  }
}