import * as React from "react";
import { Button, Grid, Box, Typography, Paper } from "@mui/material";
import { GoogleLogin } from "react-google-login";
import UserService from "../../common/services/user.service";
import styles from "./Login.styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { receiveLoginRequest } from "./actions";
import { receiveSettingsRequest } from "../search/actions/settings";
import { receiveLPSConfigsRequest } from "../lps-form/actions";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const responseGoogle = (response) => {
    const user = response.profileObj;
    const token = response.tokenId;
    if (user) {
      dispatch(
        receiveLoginRequest(token, navigate, () => {
          dispatch(
            receiveSettingsRequest()
          ); /**search settings for current user**/
          dispatch(receiveLPSConfigsRequest()); /**lps configs list**/
          // dispatch(receiveUsersDataRequest()); /**users list for updating entities responsible person**/
        })
      );
    } else {
      console.log("Something went wrong");
    }
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={styles.root}
      >
        <Paper sx={styles.loginContainer} elevation={3}>
          <Box sx={styles.imageBox}>
            <img src="images/logo.jpg" alt="Randvast logo" />
          </Box>
          <Box sx={styles.contentBox}>
            <Box sx={styles.textHolder}>
              <Typography component="div" variant="h1" sx={styles.welcomeTitle}>
                Welcome to RANDVAST workspace!
              </Typography>
              <Typography
                component="div"
                variant="h2"
                sx={styles.welcomeSubTitle}
              >
                Please log in to continue
              </Typography>
            </Box>
            <Box sx={styles.buttonHolder}>
              <GoogleLogin
                // @ts-ignore
                clientId={UserService.googleClient.id}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <Button
                    variant="contained"
                    sx={styles.loginButton}
                    onClick={renderProps.onClick}
                  >
                    SIGN IN WITH GOOGLE
                  </Button>
                )}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
}

export default Login;
