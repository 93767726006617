import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Typography,
  Container,
  MenuItem,
  Box,
  IconButton,
  AccordionSummary,
  Button,
  FormControlLabel,
  TextField,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "../Modals.styles";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import { modalTypes, userTypes } from "../../../../constants/field-constants";
import { connect, useDispatch } from "react-redux";
import {
  adminCreateUserRequest,
  adminGetUserDetailsRequest,
  adminUpdateUserRequest,
} from "../../../../../modules/admin/actions";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { emailValidator, userFormValidator } from "../../../helpers/validator";
import AlertService from "../../../../services/notification.service";
import { selectAdmin } from "../../../../../modules/admin/selectors";
import BlueSwitch from "../../../styled-components/Blueswitch";
import StyledTextField from "../../../styled-components/StyledTextField";
import { selectLPS } from '../../../../../modules/lps-form/selectors';
import { receiveLPSConfigsRequest } from '../../../../../modules/lps-form/actions';

/** define initial states for modal **/

const roles = [
  {
    value: 1,
    label: userTypes.ADMIN,
  },
  {
    value: 2,
    label: userTypes.USER,
  },
];

const elementOptionsLpsConfig =
  {
    id: null,
    name: 'n/a'
  }
;

const initialUserState = {
  name: "",
  surname: "",
  email: "",
  role_id: "",
  default_lps_profile_id: "",
  defaultLPSProfile: "",
  ip_restriction: false,
  user_ip_whitelist: [],
  ipsForDelete: [],
};

const initialIpDetails = {
  ip: "",
  ip_type: "",
  index: -1,
  id: "",
  is_allowed: false,
  modified: false,
};

const initialEditModeState = {
  isEdit: false,
  index: -1,
};

const initialErrorsState = {
  isFilledName: true,
  isFilledSurname: true,
  isFilledEmail: true,
  isFilledRole: true,
  isFilledIP: true,
  isValidEmail: true,
};

function UserModal(props: any) {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(initialUserState);
  const [ip, setIP] = useState("");
  const [ipType, setIPType] = useState("");
  const [editMode, setEditMode] = useState(initialEditModeState);
  const [editedIpDetails, setEditedIpDetails] = useState(initialIpDetails);
  const [errors, setErrors] = useState(initialErrorsState);
  const [optionsLpsConfig, setOptionsLpsConfig] = useState([]);

  const { userDetails } = props.user;
  const { lpsConfigs } = props.lpsForm;

  const handleOpen = (id: any) => {
    if (id) {
      setUserId(id);
    } else {
      setUserId(null);
    }
    dispatch(receiveLPSConfigsRequest());
  };

  useEffect(() => {
    userId !== null && dispatch(adminGetUserDetailsRequest(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    setUser(userDetails);
  }, [dispatch, userDetails]);

  useEffect(() => {
    if (lpsConfigs !== undefined) {
      const newArray: any = [...lpsConfigs, elementOptionsLpsConfig];
      setOptionsLpsConfig(newArray);
    }

  }, [dispatch, lpsConfigs]);

  const handleClearForm = () => {
    /** clear all fields and error statuses **/
    setUser(initialUserState);
    setEditedIpDetails(initialIpDetails);
    setEditMode(initialEditModeState);
    setErrors(initialErrorsState);
    setUserId(null);
  };

  const handleChecked = (
    type: any,
    event: React.ChangeEvent<HTMLInputElement>,
    selectedIndex: number
  ) => {
    /** update switches in modal by type **/
    if (type === "ip_restriction")
      setUser({ ...user, ip_restriction: event.target.checked });
    if (type === "isActiveIP") {
      const newState = user.user_ip_whitelist.map((item: any, index) => {
        if (index === selectedIndex) {
          return { ...item, is_allowed: event.target.checked, modified: true };
        } else {
          return item;
        }
      });
      // @ts-ignore
      return setUser({ ...user, user_ip_whitelist: newState });
    }
  };

  const handleChange = (
    type: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    /** fields and error statuses changing by type **/

    /** fields change block start **/
    if (type === "ip") setIP(event.target.value);
    if (type === "ipType") setIPType(event.target.value);
    setUser({ ...user, [type]: event.target.value });
    /** fields change block end **/

    /** errors change block start **/
    if (type === "name") setErrors({ ...errors, isFilledName: true });
    if (type === "surname") setErrors({ ...errors, isFilledSurname: true });
    if (type === "email") {
      setErrors({ ...errors, isFilledEmail: true });
      const isValidEmail = emailValidator(user.email);
      if (isValidEmail)
        setErrors({
          ...errors,
          isValidEmail: isValidEmail,
        });
    }
    if (type === "role_id") setErrors({ ...errors, isFilledRole: true });
    /** errors change block end **/
  };

  const handleSave = (type: string, id: any, user: any, closeModal: any) => {
    /** form validation and saving changes **/

    /**validate all fields before user saving**/
    const isValidUserData = userFormValidator(user);
    setErrors(isValidUserData);
    if (
      Object.keys(isValidUserData).every(
        (item) => isValidUserData[item] === true
      )
    ) {
      if (type === modalTypes.SAVE && !id) {
        dispatch(adminCreateUserRequest(user));
        closeModal();
      }

      if (type === modalTypes.SAVE && id) {
        dispatch(adminUpdateUserRequest(id, user));
        closeModal();
      }
      handleClearForm();
    } else {
      AlertService.warning("User form is not valid");
    }
  };

  const handleDeleteItem = (selectedIndex: any) => {
    /** removing item from ip list **/
    const newStateForDelete = [];
    user.user_ip_whitelist.map((item: any, index) => {
      if (index === selectedIndex && item.id) {
        // @ts-ignore
        return newStateForDelete.push(item);
      } else {
        return item;
      }
    });

    const newState = user.user_ip_whitelist.filter(
      (index) => index !== selectedIndex
    );

    return setUser({
      ...user,
      user_ip_whitelist: newState,
      ipsForDelete: newStateForDelete,
    });
  };

  const handleChangeItem = (
    fieldName: string,
    selectedIndex: any,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    /** changing ip item details directly in fields **/
    setEditedIpDetails({
      ...editedIpDetails,
      [fieldName]: event.target.value,
      index: selectedIndex,
      modified: true,
    });
  };

  const handleSetEditMode = (type: string, selectedIndex: any) => {
    /** managing open/close state for ip item change, save changes by click in ip list **/
    if (type === "open") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      user.user_ip_whitelist.forEach((item: any, index) => {
        if (index === selectedIndex) {
          setEditedIpDetails({
            ...item,
            ip: item.ip,
            ip_type: item.ip_type,
            id: item.id,
            index: selectedIndex,
          });
        }
      });
    }
    if (type === "close") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
    }
    if (type === "edit") {
      const newState = user.user_ip_whitelist.map((item: any, index) => {
        if (
          index === editedIpDetails.index &&
          index === selectedIndex &&
          editedIpDetails.ip !== ""
        ) {
          return {
            ...item,
            ip: editedIpDetails.ip,
            ip_type: editedIpDetails.ip_type,
            modified: editedIpDetails.modified,
          };
        } else {
          return item;
        }
      });
      // @ts-ignore
      setUser({ ...user, user_ip_whitelist: newState });
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      setEditedIpDetails(editedIpDetails);
    }
  };

  const addNewIP = () => {
    /** adding new IP to ip list **/
    if (ip) {
      setUser({
        ...user,
        // @ts-ignore
        user_ip_whitelist: [
          // @ts-ignore
          ...user.user_ip_whitelist,
          // @ts-ignore
          { ip, ip_type: ipType, is_allowed: false },
        ],
      });
      setIP("");
      setIPType("");
    }
  };

  return (
    <>
      <DefaultModal
        nameEvent={types.openCreateUpdateUserModal}
        onClose={handleClearForm}
        onOpen={handleOpen}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader title={userId ? "EDIT USER" : "CREATE USER"} />
            <Container sx={styles.formHolderUserModal}>
              <Box
                component="form"
                autoComplete="off"
                sx={{ marginBottom: "12px" }}
              >
                <StyledTextField
                  fullWidth
                  required
                  label="Name"
                  margin="dense"
                  onChange={(e) => handleChange("name", e)}
                  value={user.name || ""}
                  error={!errors.isFilledName}
                />
                <StyledTextField
                  fullWidth
                  required
                  label="Surname"
                  margin="dense"
                  onChange={(e) => handleChange("surname", e)}
                  value={user.surname || ""}
                  error={!errors.isFilledSurname}
                />
                <StyledTextField
                  fullWidth
                  required
                  label="Email"
                  margin="dense"
                  onChange={(e) => handleChange("email", e)}
                  value={user.email || ""}
                  error={!errors.isFilledEmail || !errors.isValidEmail}
                />
                <StyledTextField
                  fullWidth
                  select
                  required
                  label="Role"
                  margin="dense"
                  defaultValue=""
                  value={user.role_id || ""}
                  error={!errors.isFilledRole}
                  onChange={(e) => handleChange("role_id", e)}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <StyledTextField
                  fullWidth
                  select
                  label="Default LPS profile"
                  margin="dense"
                  defaultValue=""
                  value={user.default_lps_profile_id || ''}
                  onChange={(e) => handleChange('default_lps_profile_id', e)}
                >
                  {optionsLpsConfig.map(({id, name}, index) => (
                    <MenuItem key={index} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <FormControlLabel
                  value="end"
                  control={<BlueSwitch />}
                  label="IP restriction"
                  labelPlacement="end"
                  //@ts-ignore
                  onChange={(e) => handleChecked("ip_restriction", e, -1)}
                  checked={user.ip_restriction}
                  defaultChecked={initialUserState.ip_restriction}
                  style={{ color: "#495057" }}
                />
                <Accordion disabled={!user.ip_restriction}>
                  <AccordionSummary>
                    <Typography component="legend" sx={styles.textFieldPadding}>
                      Whitelisted IP’s
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <StyledTextField
                      disabled={!user.ip_restriction}
                      fullWidth
                      required
                      label="IP"
                      margin="dense"
                      onChange={(e) => handleChange("ip", e)}
                      value={ip}
                    />
                    <StyledTextField
                      disabled={!user.ip_restriction}
                      fullWidth
                      label="Type"
                      margin="dense"
                      onChange={(e) => handleChange("ipType", e)}
                      value={ipType}
                    />
                  </AccordionDetails>
                  <Button
                    sx={styles.buttonIp}
                    onClick={addNewIP}
                    disabled={!user.ip_restriction}
                  >
                    Add new IP
                  </Button>
                  {user?.user_ip_whitelist?.length > 0 &&
                    user.user_ip_whitelist.map(
                      (
                        item: {
                          ip: string;
                          ip_type: string;
                          is_allowed: boolean;
                          modified: boolean;
                        },
                        index
                      ) => {
                        return editMode.isEdit && editMode.index === index ? (
                          <Box sx={styles.ipHolder} key={index}>
                            <Box sx={styles.editIpHolderFields}>
                              <TextField
                                sx={{ marginRight: "15px", width: "100%" }}
                                label="IP"
                                required
                                variant="standard"
                                onChange={(e) =>
                                  handleChangeItem("ip", index, e)
                                }
                                value={editedIpDetails.ip}
                                disabled={!user.ip_restriction}
                              />
                              <TextField
                                sx={{ width: "100%" }}
                                label="Type"
                                variant="standard"
                                onChange={(e) =>
                                  handleChangeItem("ip_type", index, e)
                                }
                                value={editedIpDetails.ip_type}
                                disabled={!user.ip_restriction}
                              />
                            </Box>
                            <Box sx={styles.editIpHolderButtons}>
                              <IconButton
                                disabled={!user.ip_restriction}
                                onClick={() => handleSetEditMode("edit", index)}
                              >
                                <DoneIcon fontSize={"small"} />
                              </IconButton>
                              <IconButton
                                disabled={!user.ip_restriction}
                                onClick={() =>
                                  handleSetEditMode("close", index)
                                }
                              >
                                <CloseIcon fontSize={"small"} />
                              </IconButton>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={styles.ipHolder}>
                            <Box sx={styles.ipHolder}>
                              <Typography sx={styles.ipTitle} variant={"h6"}>
                                {item?.ip}
                              </Typography>
                              <Typography sx={styles.ipTitle} variant={"h6"}>
                                {item?.ip_type}
                              </Typography>
                            </Box>
                            <Box sx={styles.ipHolder}>
                              <FormControlLabel
                                control={<BlueSwitch sx={{ transform: "scale(0.8)" }}/>}
                                label="Active"
                                disabled={!user.ip_restriction}
                                sx={styles.switchLabel}
                                checked={item?.is_allowed}
                                onChange={(e) =>
                                  //@ts-ignore
                                  handleChecked("isActiveIP", e, index)
                                }
                              />
                              <IconButton
                                aria-label="Edit"
                                disabled={!user.ip_restriction}
                                sx={styles.iconButton}
                                onClick={() => handleSetEditMode("open", index)}
                              >
                                <EditIcon sx={styles.iconStyles} />
                              </IconButton>
                              <IconButton
                                aria-label="Delete"
                                disabled={!user.ip_restriction}
                                sx={styles.iconButton}
                                onClick={() => handleDeleteItem(index)}
                              >
                                <DeleteIcon sx={styles.iconStyles} />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                </Accordion>
              </Box>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.CREATE}
              onSave={() =>
                handleSave(modalTypes.SAVE, userId, user, closeModal)
              }
            />
          </>
        )}
      </DefaultModal>
    </>
  );
}

function mapStateToProps(state: any) {
  const user = selectAdmin(state);
  const lpsForm = selectLPS(state);
  return { user, lpsForm };
}

export default connect(mapStateToProps)(UserModal);
