import { call, put, takeLatest } from "redux-saga/effects";
import {
  RECEIVE_DEPENDENT_ENTITIES_REQUEST,
  RECEIVE_ENTITY_DETAILS_REQUEST,
  UPDATE_ENTITY_REQUEST,
  DELETE_ENTITY_REQUEST,
} from "../../../common/constants/action-constants";
import ApiService from "../../../common/services/api.service";
import {
  receiveDependentEntitiesError,
  receiveDependentEntitiesSuccess,
  receiveMainEntityDetailsError,
  receiveMainEntityDetailsSuccess,
  updateEntityError,
  updateEntitySuccess,
  removeEntitySuccess,
  removeEntityError,
} from "../actions/entityDetails";
import { receiveObjectsByOwnerRequest } from "../actions/objects";
import AlertService from "../../../common/services/notification.service";
import { notificationMessage } from "../../../common/constants/notication-constants";

function* getEntityDetails(action: any) {
  try {
    const entity = yield call(ApiService.get, `/entity/${action.entityId}`);
    if (entity) {
      yield put(receiveMainEntityDetailsSuccess(entity.data));
    }
  } catch (e) {
    yield put(
      receiveMainEntityDetailsSuccess(receiveMainEntityDetailsError(e))
    );
  }
}

function* updateEntity(action: any) {
  try {
    const updatedEntity = yield call(
      ApiService.put,
      `/entity/${action.entityId}`,
      {
        deleteMode: action.deleteMode,
        types: action.actionTypes,
        info: action.data,
      }
    );
    if (updatedEntity) {
      yield put(
        updateEntitySuccess(
          updatedEntity.data,
          "Entity was successfully updated!"
        )
      );
      if (action.actionTypes.includes("objects")) {
        yield put(receiveObjectsByOwnerRequest(action.entityId));
      }
    }
  } catch (e) {
    yield put(updateEntityError(e));
  }
}

function* getDependentEntities(action: any) {
  try {
    const dependentEntities = yield call(
      ApiService.get,
      `/entity/${action.id}/dependent_entities`
    );
    if (dependentEntities) {
      yield put(receiveDependentEntitiesSuccess(dependentEntities.data));
    }
  } catch (e) {
    yield put(receiveDependentEntitiesError(e));
  }
}

function* deleteEntity(action: any) {
  try {
    const entityDelete = yield call(ApiService.delete, `/entity/${action.id}`);
    if (entityDelete && entityDelete.data.message) {
      yield put(removeEntitySuccess(entityDelete.data.message));
    }
  } catch (error) {
    yield put(removeEntityError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

export default function* actionEntity() {
  yield takeLatest(RECEIVE_ENTITY_DETAILS_REQUEST, getEntityDetails);
  yield takeLatest(UPDATE_ENTITY_REQUEST, updateEntity);
  yield takeLatest(RECEIVE_DEPENDENT_ENTITIES_REQUEST, getDependentEntities);
  yield takeLatest(DELETE_ENTITY_REQUEST, deleteEntity);
}
