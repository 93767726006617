import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Button, Container, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs, { Dayjs } from "dayjs";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import { selectLeadgen } from "../../../../../modules/leadgen/selectors";
import ModalFooter from "../../modal-footer/ModalFooter";
import { modalTypes } from "../../../../constants/field-constants";
import { updateEntityRequest } from "../../../../../modules/leadgen/actions/entityDetails";
import styles from "../Modals.styles";

const ScheduleCalendarModal = (props: any) => {
  const dispatch = useDispatch();
  const [currentEntityId, setEntityId] = useState("");
  const [entityDetails, setEntityDetails] = useState({} as any);
  const [value, setValue] = useState<Dayjs | Date | null>(dayjs(Date.now()));
  const [actionTypes, setActionTypes] = useState(["save_date"]);
  const deleteMode = false;
  const { entityMainDetails } = props;
  const quickScheduleOptions = [
    [
      { label: "tomorrow", optionValue: 1 },
      { label: "1 week", optionValue: 7 },
      { label: "1 month", optionValue: 7 * 4 },
    ],
    [
      { label: "2 days", optionValue: 2 },
      { label: "2 weeks", optionValue: 7 * 2 },
      { label: "3 months", optionValue: 7 * 12 },
    ],
    [
      { label: "3 days", optionValue: 3 },
      { label: "3 weeks", optionValue: 7 * 3 },
      { label: "6 months", optionValue: 7 * 24 },
    ],
  ];

  const handleOpen = (entityId: string) => {
    setEntityId(entityId);
    entityMainDetails && setEntityDetails(entityMainDetails);
    if (entityId !== entityMainDetails.id) {
      setActionTypes([...actionTypes, "save_date_for_stakeholder"]);
    }
  };
  const handleQuickSave = (days: any) => {
    if (days === null) {
      return setValue(null);
    }
    let newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    // @ts-ignore
    setValue(dayjs(newDate));
  };

  const handleSave = (closeModal: any) => {
    dispatch(
      updateEntityRequest(deleteMode, actionTypes, currentEntityId, {
        ...entityDetails,
        schedule: value,
      })
    );
    closeModal();
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        nameEvent={types.openScheduleCalendarModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader title="Schedule date" />
            <Container>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  sx={styles.dateCalendar}
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </LocalizationProvider>
              <Typography sx={styles.scheduleSubTitle} variant="h6">
                Quick (re)schedule options
              </Typography>
              <Box sx={styles.buttonHolder}>
                {quickScheduleOptions.map((row, index) => (
                  <Box key={index} sx={styles.columnHolder}>
                    {row.map(({ label, optionValue }) => (
                      <Button
                        sx={styles.buttonContentCalendar}
                        key={label}
                        onClick={() => {
                          handleQuickSave(optionValue);
                        }}
                      >
                        {label}
                      </Button>
                    ))}
                  </Box>
                ))}
              </Box>
              <Button
                sx={styles.buttonContentClear}
                onClick={() => {
                  handleQuickSave(null);
                }}
              >
                Remove schedule date
              </Button>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.SAVE}
              onSave={() => handleSave(closeModal)}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const entityMainDetails = selectLeadgen(state);
  return entityMainDetails;
}

export default connect(mapStateToProps)(ScheduleCalendarModal);
