import React, { useState } from "react";
import DefaultModal from "../../default-modal/DefaultModal";
import { types } from "../../../helpers/eventEmitter";
import ModalHeader from "../../modal-header/ModalHeader";
import { Box, Container } from "@mui/material";
import styles from "../Modals.styles";
import ModalFooter from "../../modal-footer/ModalFooter";
import { modalTypes } from "../../../../constants/field-constants";
import { useDispatch, connect } from "react-redux";
import StyledTextField from "../../../styled-components/StyledTextField";
import { selectSuperAdmin } from "../../../../../modules/super-admin/selectors";
import {
  createNewLabelRequest,
  createNewTagRequest,
} from "../../../../../modules/super-admin/actions";
import AlertService from "../../../../services/notification.service";

const LabelTagCreateModal = (props: any) => {
  const dispatch = useDispatch();
  const { labelsList, tagsList } = props;

  const [typeCreate, setTypeCreate] = useState(null);
  const [newName, setNewName] = useState("");

  const handleOpen = (type: any) => {
    setTypeCreate(type);
  };

  const handleClearForm = () => {
    setNewName("");
  };

  const handleChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (type === "name") {
      setNewName(e.target.value);
    }
  };

  const handleSave = (type: string, name: string, closeModal: any) => {
    const nameMatch =
      typeCreate === "tagCreate"
        ? tagsList.filter((tag: any) => tag.name === name)
        : labelsList.filter((label: any) => label.name === name);
    if (
      type === modalTypes.SAVE &&
      typeCreate === "labelCreate" &&
      nameMatch.length === 0 &&
      !!name
    ) {
      dispatch(createNewLabelRequest({ name }));
      closeModal();
    }
    if (
      type === modalTypes.SAVE &&
      typeCreate === "tagCreate" &&
      nameMatch.length === 0 &&
      !!name
    ) {
      dispatch(createNewTagRequest({ name }));
      closeModal();
    }
    if (
      type === modalTypes.SAVE &&
      typeCreate === "labelCreate" &&
      nameMatch.length > 0 &&
      !!name
    ) {
      AlertService.warning(
        "A label with the same name already exists in the database"
      );
      return;
    }
    if (
      type === modalTypes.SAVE &&
      typeCreate === "tagCreate" &&
      nameMatch.length > 0 &&
      !!name
    ) {
      AlertService.warning(
        "A tag with the same name already exists in the database"
      );
      return;
    }
    handleClearForm();
  };

  return (
    <>
      <DefaultModal
        onOpen={handleOpen}
        onClose={handleClearForm}
        nameEvent={types.openLabelTagCreateModal}
      >
        {({ closeModal }) => (
          <>
            <ModalHeader
              title={typeCreate === "tagCreate" ? "CREATE TAG" : "CREATE LABEL"}
            />

            <Container sx={styles.formHolder}>
              <Box component="form" autoComplete="off" sx={styles.dataApp}>
                <StyledTextField
                  fullWidth
                  required
                  label={typeCreate === "tagCreate" ? "Name tag" : "Name label"}
                  margin="dense"
                  onChange={(e) => handleChange("name", e)}
                  value={newName || ""}
                />
              </Box>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.CREATE}
              onSave={() => handleSave(modalTypes.SAVE, newName, closeModal)}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const { labelsList, tagsList } = selectSuperAdmin(state);
  return { labelsList, tagsList };
}

export default connect(mapStateToProps)(LabelTagCreateModal);
