import { combineReducers } from "redux";
import {
  ADMIN_GET_USERS_SUCCESS,
  ADMIN_GET_USERS_ERROR,
  ADMIN_GET_USER_LOGS_SUCCESS,
  ADMIN_GET_USER_LOGS_ERROR,
  ADMIN_DELETE_USER_ERROR,
  ADMIN_CREATE_USER_ERROR,
  ADMIN_GET_USER_DETAILS_ERROR,
  ADMIN_GET_USER_DETAILS_SUCCESS,
} from "../../../common/constants/action-constants";

const receiveUsers = (state = [], action) => {
  switch (action.type) {
    case ADMIN_GET_USERS_SUCCESS:
      return action.users;
    default:
      return state;
  }
};

const receiveUserLogs = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_USER_LOGS_SUCCESS:
      return action.logs;
    default:
      return state;
  }
};

const receiveUserDetails = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_USER_DETAILS_SUCCESS:
      return action.user;
    default:
      return state;
  }
};

const usersError = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_USERS_ERROR:
    case ADMIN_GET_USER_LOGS_ERROR:
    case ADMIN_DELETE_USER_ERROR:
    case ADMIN_CREATE_USER_ERROR:
    case ADMIN_GET_USER_DETAILS_ERROR:
      // case ADMIN_UPDATE_USER_ERROR:
      // case GET_ADMIN_DRIVE_ERROR:
      return action.error;

    default:
      return state;
  }
};

const admin = combineReducers({
  receive: receiveUsers,
  logs: receiveUserLogs,
  user: receiveUserDetails,
  // receiveDriveAuthUrl,
  error: usersError,
});

export default admin;
