import { styled, TextField } from "@mui/material";

const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgb(17,75,118)",
  },
  "& .MuiOutlinedInput-root": {
    /**border color without hover**/
    // '& fieldset': {
    //   borderColor: '#495057',
    // },
    "&:hover fieldset": {
      borderColor: "rgba(73,80,87,0.8)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(17,75,118,0.8)",
    },
  },
});

export default StyledTextField;
