import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import StyledTextField from "../styled-components/StyledTextField";
import styles from "./LPSMenu.styles";
import {
  calcDefaultProfile,
  setLPSItem,
  startCountLPSDataRequest,
  resetCurrentCalculationRequest,
} from "../../../modules/lps-form/actions";
import { adminGetUsersRequest } from "../../../modules/admin/actions";
import {
  checkBestLeadRequest,
  findLeadFlag,
  nextLPSLead,
  prevLPSLead,
  setSelectedUsers,
  showAssignedLeads,
  showScheduledLeads,
  skipUnassignedLeadsFlag,
} from "../../../modules/leads/actions";
import { selectAdmin } from "../../../modules/admin/selectors";
import { selectLPS } from "../../../modules/lps-form/selectors";
import { selectLeads } from "../../../modules/leads/selectors";

export const drawerWidth = 270;

interface LPSMenuProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<LPSMenuProps>(({ theme, open }) => ({
  position: "fixed",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginRight: drawerWidth,
  // }),
}));

const LPSMenu = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    lps,
    lpsMenuOpen,
    users,
    countOfLeads,
    isAssigned,
    isScheduled,
    setLeadId,
    leadFlag,
    isCalculated,
    isFetching,
  } = props;
  const { lpsConfigs, processing } = lps;
  const [configOptions, setConfigOptions] = useState([] as any);
  const [selectedConfig, setSelectedConfig] = useState({ name: "", id: "" });
  const [cityOptions, setCityOptions] = useState([
    { value: "All cities", label: "All cities" },
  ] as any);
  const [selectedCity, setSelectedCity] = useState("");
  const [lpcResult, setLpcResult] = useState([]);
  const [configName, setConfigName] = useState([]);
  const [lastRecalculationDate, setLastRecalculationDate] = useState([]);
  const [userList, setUserList] = useState([] as any);
  const [indexOfCurrentLeadID, setIndexOfCurrentLeadID] = useState(-1);
  const IDs = lpcResult ? lpcResult.map((i: any) => i.id) : [];
  const index = IDs ? IDs.indexOf(setLeadId) : -1;
  const [filterItems, setFilterItems] = useState({
    assigned: false,
    scheduled: false,
    isCalculated: false,
    findNextLead: false,
    findPrevLead: false,
    findLead: false,
    isSkipped: false,
    filteredUsersList: [] as any,
  });

  //==================================//

  // console.log('lpcResult ', lpcResult);

  useEffect(() => {
    dispatch(adminGetUsersRequest());
  }, [dispatch]);

  //==================================//

  useEffect(() => {
    setUserList(users);
  }, [dispatch, users]);

  //==================================//

  useEffect(() => {
    const preformattedConfigs = [] as any;
    if (lpsMenuOpen) {
      dispatch(adminGetUsersRequest());
      lpsConfigs.forEach((config: any) => {
        preformattedConfigs.push({
          label: config.name,
          value: config.name,
        });
      });

      setConfigOptions(preformattedConfigs);
    }

    if (processing && processing.result) {
      const { configName, lpc, last_recalculation_date } = processing.result;
      lpc.length > 0 && setLpcResult(lpc);
      setConfigName(configName);
      setLastRecalculationDate(last_recalculation_date);
    }
  }, [lpsMenuOpen, lpsConfigs, lpcResult, dispatch, processing]);

  //==================================//

  useEffect(() => {
    if (indexOfCurrentLeadID !== index) {
      setIndexOfCurrentLeadID(index);
      dispatch(setLPSItem(index));
    }
  }, [indexOfCurrentLeadID, index, dispatch]);

  //==================================//

  useEffect(() => {
    setFilterItems({
      ...filterItems,
      findLead: leadFlag,
    });
  }, [dispatch, filterItems.findLead, leadFlag]);

  //==================================//

  useEffect(() => {
    setFilterItems({
      ...filterItems,
      isCalculated: isCalculated,
    });
  }, [dispatch, filterItems.isCalculated]);

  //==================================//

  useEffect(() => {
    setFilterItems({
      ...filterItems,
      scheduled: isScheduled,
    });
  }, [dispatch, filterItems.scheduled, isScheduled]);

  //==================================//

  useEffect(() => {
    setFilterItems({
      ...filterItems,
      assigned: isAssigned,
    });
  }, [dispatch, filterItems.assigned, isAssigned]);

  //==================================//

  const showAssigned = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(showAssignedLeads(e.target.checked));
    setFilterItems({
      ...filterItems,
      assigned: e.target.checked,
    });
    if (!isAssigned && filterItems.filteredUsersList.length > 0) {
      console.log('clear');
      handleRemoveUsers();
    }
  };

  useEffect(() => {
    if (!isAssigned && filterItems.filteredUsersList.length > 0) {
      console.log('clear');
      handleRemoveUsers();
    }
  }, [isAssigned]);

  const showScheduled = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(showScheduledLeads(e.target.checked));
    setFilterItems({
      ...filterItems,
      scheduled: e.target.checked,
    });
  };

  //TODO check and finish unassigned leads in next development iteration

  const handleSkipUnassignedLeads = () => {
    console.log('!filterItems.isSkipped', !filterItems.isSkipped);
    dispatch(skipUnassignedLeadsFlag(!filterItems.isSkipped));
    setFilterItems({
      ...filterItems,
      isSkipped: !filterItems.isSkipped,
    });
  };

  const handleChangeIsCalculated = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(calcDefaultProfile(e.target.checked));
    setFilterItems({
      ...filterItems,
      isCalculated: e.target.checked,
    });
    // if (!assign && filteredUsersList !== []) {
    //   handleRemoveUser();
    // }
  };

  const handleChangeFindLead = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(findLeadFlag(e.target.checked));
    setFilterItems({
      ...filterItems,
      findLead: e.target.checked,
    });
  };

  const handleRemoveUsers = () => {
    setFilterItems({
      ...filterItems,
      filteredUsersList: [],
      isSkipped: false,
    });
    dispatch(setSelectedUsers([]));
    dispatch(skipUnassignedLeadsFlag(false));
  };
  // console.log('filterItems', filterItems);
  const handleSelectUsers = ({ target }, user: any) => {
    let newState: any[] = filterItems.filteredUsersList;
        if (target.checked) {
      /** add user in list **/
      const isInArr = filterItems.filteredUsersList.includes(user.id);
      if (isInArr) {
        console.log("User is already in array");
      } else {
        newState.push(user.id);
      }
      setFilterItems({
        ...filterItems,
        filteredUsersList: newState,
      });
    } else {
      /** remove user from list **/
      const idForDel = newState.find((item) => item === user.id);
      const index = newState.indexOf(idForDel);
      newState.splice(index, 1);
      setFilterItems({
        ...filterItems,
        filteredUsersList: newState,
      });
    }
    dispatch(setSelectedUsers(newState));
  };

  const setConfigForCalculation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const config = lpsConfigs.find((i: any) => i.name === e.target.value);
    setSelectedConfig({
      name: config.name,
      id: config.id,
    });
    const cityOptions =
      config &&
      config.lps_config_cities.map((i: any) => ({
        value: i.city_name,
        label: i.city_name,
      }));
    setCityOptions([
      ...cityOptions,
      { value: "All cities", label: "All cities" },
    ]);
  };

  const setCityForCalculation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedCity(e.target.value);
  };

  const runLPSCalculation = () => {
    const ident = Date.now();
    dispatch(startCountLPSDataRequest({ selectedConfig, selectedCity, ident }));
  };

  const runResetCurrentCalculation = () => {
    dispatch(resetCurrentCalculationRequest());
  };

  return (
    <AppBar open={lpsMenuOpen}>
      <Drawer
        variant="persistent"
        anchor="right"
        open={lpsMenuOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={styles.mainHolder}>
          <Box sx={styles.calcParamsHolder}>
            {isFetching ? (
              <Box sx={{ width: "100%", marginBottom: "0.5em" }}>
                <CircularProgress
                  color="secondary"
                  size={20}
                  sx={styles.spinner}
                />
              </Box>
            ) : (
              <Button
                sx={styles.runButton}
                onClick={runLPSCalculation}
                disabled={!(selectedCity && selectedConfig.name) || isFetching}
              >
                RUN CALCULATION
              </Button>
            )}
            <Button
              sx={styles.resetButton}
              onClick={runResetCurrentCalculation}
            >
              RESET CALCULATION
            </Button>
            <StyledTextField
              fullWidth
              select
              label="Select config"
              margin="dense"
              defaultValue=""
              sx={{ margin: "5px" }}
              value={selectedConfig.name || ""}
              onChange={(e) => setConfigForCalculation(e)}
            >
              {configOptions.map((option: any, index: number) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextField>
            <StyledTextField
              fullWidth
              select
              label="Select city"
              margin="dense"
              defaultValue=""
              sx={{ margin: "5px" }}
              value={selectedCity}
              onChange={(e) => setCityForCalculation(e)}
            >
              {cityOptions.map((option: any, index: number) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextField>
            <Box sx={styles.checkBoxHolder}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterItems.isCalculated}
                    onChange={(e) => handleChangeIsCalculated(e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    Default config recalculation
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterItems.findLead}
                    onChange={(e) => handleChangeFindLead(e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    Find free lead in background
                  </Typography>
                }
              />
            </Box>
          </Box>

          <Box sx={styles.infoHolder}>
            <Typography sx={styles.title}>Config name:</Typography>
            <Typography>{configName}</Typography>
            <Typography sx={styles.title}>Last recalculation date:</Typography>
            <Typography>{lastRecalculationDate}</Typography>
            {/*<Typography>BASE POINTS:</Typography>*/}
            {/*<Typography>Total amount: </Typography>*/}
            {/*<Typography>Multiplier:</Typography>*/}
            {/*<Typography>Object points:</Typography>*/}

            <Box sx={styles.checkBoxHolder}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterItems.scheduled}
                    onChange={(e) => showScheduled(e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    Show scheduled leads
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterItems.assigned}
                    onChange={(e) => showAssigned(e)}
                  />
                }
                label={
                  <Typography sx={styles.checkBoxLabel}>
                    Show assigned leads
                  </Typography>
                }
              />
              {filterItems.assigned && (
                <>
                  <Typography sx={{ width: "80%", margin: "3rem auto 1rem auto" }}>
                    Show leads for:
                  </Typography>
                  <List sx={styles.list}>

                  {userList.map((user: any, index: number) => (
                    <ListItem key={index}>
                      <ListItemButton dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            disableRipple
                            onChange={(e) => handleSelectUsers(e, user)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={`${user.name}`} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                  <ListItem key={userList.length}>
                    <ListItemButton dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disableRipple
                          onChange={() => handleSkipUnassignedLeads()}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Skip unassigned leads" />
                    </ListItemButton>
                  </ListItem>
                  </List>
                </>
              )}
            </Box>
          </Box>
          <Box sx={styles.leadLogicHolder}>
            <Button
              sx={styles.bestLeadButton}
              disabled={!countOfLeads}
              onClick={() => dispatch(checkBestLeadRequest(navigate))}
            >
              SHOW THE BEST
            </Button>
            <Box sx={styles.buttonGroup}>
              <IconButton
                disabled={indexOfCurrentLeadID === 0 || isFetching}
                title="Prev"
                onClick={() => {
                  dispatch(prevLPSLead(navigate));
                }}
              >
                <ArrowBack />
              </IconButton>
              <Box sx={styles.currentLead}>
                <Typography>LEAD</Typography>
                <Typography variant="caption">
                  {`${indexOfCurrentLeadID + 1}/${countOfLeads}`}
                </Typography>
              </Box>
              <IconButton
                disabled={
                  indexOfCurrentLeadID === countOfLeads - 1 || isFetching
                }
                title="Next"
                onClick={() => {
                  dispatch(nextLPSLead(navigate));
                }}
              >
                <ArrowForward />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </AppBar>
  );
};

function mapStateToProps(state: any) {
  const {
    lps,
    lpsConfigs,
    processing,
    countOfLeads,
    isCalculated,
    isFetching,
  } = selectLPS(state);
  const { users } = selectAdmin(state);
  // @ts-ignore
  const { leads, isAssigned, isScheduled, setLeadId, leadFlag, isSkipped } = selectLeads(state);
  return {
    lps,
    lpsConfigs,
    processing,
    users,
    countOfLeads,
    leads,
    isAssigned,
    isScheduled,
    setLeadId,
    leadFlag,
    isCalculated,
    isFetching,
    isSkipped,
  };
}

export default connect(mapStateToProps)(LPSMenu);
