import * as React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormControl } from "@mui/material/FormControl";

const InputHelperText = (props) => {
  const { focused } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (focused) {
      return "This field is being focused";
    }

    return props.text;
  }, [focused, props.text]);

  return <FormHelperText>{helperText}</FormHelperText>;
};

export default InputHelperText;
