import React from "react";
import styles from "../LPSForm.styles";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledTextField from "../../../common/components/styled-components/StyledTextField";
import { Add } from "@mui/icons-material";
import { lpsFormTypes } from '../../../common/constants/field-constants';

const objectVariableOptions = [
  {
    value: "units amount",
    label: "units amount",
  },
  {
    value: "non housing units",
    label: "non housing units",
  },
  {
    value: "built year",
    label: "built year",
  },
  {
    value: "owners amount",
    label: "owners amount",
  },
  {
    value: "WOZ-value",
    label: "WOZ-value",
  },
];

const objectConditionOptions = [
  {
    value: "bigger than",
    label: "bigger than",
  },
  {
    value: "equal",
    label: "equal",
  },
  {
    value: "less than",
    label: "less than",
  },
];

const ObjectPointsBlock = ({ onChange, objectPointsRules, calculationType, onAdd, onDelete }) => {
  return (
    <>
      <AppBar position="relative" sx={styles.dataBoxSecond}>
        <Toolbar sx={styles.toolBarSecond}>
          <Typography component="div" sx={styles.headerContentSecond}>
            Object points condition
          </Typography>
          <Button
            disabled={objectPointsRules.length >= 5}
            onClick={() => onAdd("lpsc_object_condition_rules", "lpsc_object_condition_rules")}
          >
            <Add sx={{ color: "white" }} />
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={styles.fieldHolderSecond}>
        {objectPointsRules.length > 0 &&
          objectPointsRules.map(
            (
              { variable, condition, condition_value, condition_points },
              index
            ) => (
              <Box sx={styles.fieldBlockSecond} key={index}>
                <StyledTextField
                  fullWidth
                  required={calculationType !== lpsFormTypes.KADASTER}
                  select
                  label="Variable"
                  sx={styles.inputTextField}
                  InputLabelProps={{
                    sx: {
                      fontSize: {
                        xs: '12px',
                        sm: '14px',
                        md: '1rem'
                      }
                    }
                  }}
                  margin="dense"
                  defaultValue=""
                  onChange={(e) =>
                    onChange(
                      "object_points_condition_rules",
                      "variable",
                      e,
                      index
                    )
                  }
                  value={variable || ""}
                >
                  {objectVariableOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <StyledTextField
                  fullWidth
                  select
                  label="Condition"
                  sx={styles.inputTextField}
                  InputLabelProps={{
                    sx: {
                      fontSize: {
                        xs: '12px',
                        sm: '14px',
                        md: '1rem'
                      }
                    }
                  }}
                  margin="dense"
                  onChange={(e) =>
                    onChange(
                      "object_points_condition_rules",
                      "condition",
                      e,
                      index
                    )
                  }
                  value={condition || ""}
                >
                  {objectConditionOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
                <StyledTextField
                  fullWidth
                  label="Value"
                  sx={styles.inputTextField}
                  InputLabelProps={{
                    sx: {
                      fontSize: {
                        xs: '12px',
                        sm: '14px',
                        md: '1rem'
                      }
                    }
                  }}
                  margin="dense"
                  type="number"
                  onChange={(e) =>
                    onChange(
                      "object_points_condition_rules",
                      "condition_value",
                      e,
                      index
                    )
                  }
                  value={condition_value}
                />
                <StyledTextField
                  fullWidth
                  label="Points"
                  sx={styles.inputTextField}
                  InputLabelProps={{
                    sx: {
                      fontSize: {
                        xs: '12px',
                        sm: '14px',
                        md: '1rem'
                      }
                    }
                  }}
                  margin="dense"
                  type="number"
                  onChange={(e) =>
                    onChange(
                      "object_points_condition_rules",
                      "condition_points",
                      e,
                      index
                    )
                  }
                  value={condition_points}
                />
                <IconButton
                  sx={{
                    color: "#B4CEE5",
                    fontSize: {
                      xs: "medium",
                      sm: "medium",
                      md: "24px",
                    },
                  }}
                  onClick={() =>
                    onDelete(
                      "lpsc_object_condition_rules",
                      "lpsc_object_condition_rules",
                      index
                    )
                  }
                  aria-label="Delete"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )
          )}
      </Box>
    </>
  );
};

export default ObjectPointsBlock;
