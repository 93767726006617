import { styled } from "@mui/styles";
import { InputBase } from "@mui/material";

// eslint-disable-next-line no-empty-pattern
const StyledInputBase = styled(InputBase)(({}) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "1px, 1px, 1px, 0",
    paddingLeft: `calc(1em + 15px)`,
    width: "100%",
  },
}));

export default StyledInputBase;
