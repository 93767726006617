import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { types } from "../../../helpers/eventEmitter";

import { adminGetUserLogsRequest } from "../../../../../modules/admin/actions";
import UserAvatar from "../../../avatar/UserAvatar";
import { propsTypes, modalTypes } from "../../../../constants/field-constants";
import DefaultModal from "../../default-modal/DefaultModal";
import ModalHeader from "../../modal-header/ModalHeader";
import ModalFooter from "../../modal-footer/ModalFooter";
import styles from "../Modals.styles";
import { selectAdmin } from "../../../../../modules/admin/selectors";

function createData(ip: number, os: string, date: string, time: string) {
  return { ip, os, date, time };
}

const UserLogsModal = (props: any) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("Selected user");
  const [userEmail, setUserEmail] = useState("n/a");
  const [userIP, setUserIP] = useState("n/a");
  const [userOS, setUserOS] = useState("n/a");
  const [userLastLogin, setUserLastLogin] = useState("n/a");
  const [userLogs, setUserLogs] = useState([]);
  const [userId, setUserId] = useState(null);
  const {
    selectedUserName,
    selectedUserEmail,
    selectedUserIP,
    selectedUserOS,
    selectedUserLastLogin,
    selectedUserLogInfo,
  } = props;

  const handleOpen = (id: any) => {
    setUserId(id);
  };

  useEffect(() => {
    userId !== null && dispatch(adminGetUserLogsRequest(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    setUserName(selectedUserName);
    setUserEmail(selectedUserEmail);
    setUserIP(selectedUserIP);
    setUserOS(selectedUserOS);
    setUserLastLogin(selectedUserLastLogin);
    setUserLogs(selectedUserLogInfo);
  }, [
    dispatch,
    selectedUserName,
    selectedUserEmail,
    selectedUserIP,
    selectedUserOS,
    selectedUserLastLogin,
    selectedUserLogInfo,
  ]);

  if (props.selectedUserName !== userName) setUserName(props.selectedUserName);
  const rows =
    userLogs.length > 0
      ? userLogs.map((log: any) => {
          return createData(log.ip, log.os, log.date, log.time);
        })
      : [];

  return (
    <>
      <DefaultModal onOpen={handleOpen} nameEvent={types.openUserLogsModal}>
        {({ closeModal }) => (
          <>
            <ModalHeader title="ACTIVITY DETAILS" email={userEmail} />
            <Container>
              <Card sx={{ display: "flex", marginBottom: "24px" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={styles.headerTable}
                  >
                    {`Name: ${userName}`}
                  </Typography>
                  <Typography sx={styles.contentCard} component="div">
                    {`Last login: ${userLastLogin}`}
                  </Typography>

                  <Typography sx={styles.contentCard} component="div">
                    {`IP: ${userIP}`}
                  </Typography>

                  <Typography sx={styles.contentCard} component="div">
                    {`OS: ${userOS}`}
                  </Typography>
                </CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <UserAvatar stylesType={propsTypes.ADMIN_PANEL} />
                </Box>
              </Card>
              {userLogs.length > 0 && (
                <Card sx={{ marginBottom: "24px" }}>
                  <AppBar position="relative" sx={styles.dataAppUserLogsModal}>
                    <Toolbar>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={styles.headerContent}
                      >
                        LOGS
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={styles.cardContainer}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={styles.headerTable}>IP</TableCell>
                            <TableCell sx={styles.headerTable}>OS</TableCell>
                            <TableCell sx={styles.headerTable}>Date</TableCell>
                            <TableCell sx={styles.headerTable}>Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={styles.contentTable}
                              >
                                {row.ip ? row.ip : "n/a"}
                              </TableCell>
                              <TableCell sx={styles.contentTable}>
                                {row.os ? row.os : "n/a"}
                              </TableCell>
                              <TableCell sx={styles.contentTable}>
                                {row.date ? row.date : "n/a"}
                              </TableCell>
                              <TableCell sx={styles.contentTable}>
                                {row.time ? row.time : "n/a"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Box>
                </Card>
              )}
            </Container>
            <ModalFooter closeModal={closeModal} type={modalTypes.INFO} />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state: any) {
  const users = selectAdmin(state);
  return users;
}

export default connect(mapStateToProps)(UserLogsModal);
