const styles = {
  root: {
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0,0,0,0.4)",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    pointerEvents: "none",
    transition: "0.5s",
  },
  rootActive: {
    zIndex: 1210,
    minHeight: "100vh",
    height: "100%",
    ninWidth: "100vw",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.4)",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 1,
    pointerEvents: "all",
    transition: "0.5s",
  },
  boxContent: {
    maxWidth: "525px",
    borderRadius: "5px",
    background: "#FFFFFF",
    transform: "scale(0.5)",
    transition: "0.4s all",
  },
  boxContentActive: {
    maxWidth: {
      xs: "80%",
      sm:"525px"},
    borderRadius: "5px",
    background: "#FFFFFF",
    transform: "scale(1)",
    transition: "0.4s all",
  },
  boxContentActiveSearch: {
    maxWidth: {
      xs: "90vw",
      md: "80vw",
      lg: "60vw",
    },
    width: "100%",
    borderRadius: "5px",
    background: "#FFFFFF",
    transform: "scale(1)",
    transition: "0.4s all",
  },
};

export default styles;
