import * as React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import eventEmitter, {
  types,
} from "../../../common/components/helpers/eventEmitter";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { modelTypes } from "../../../common/constants/field-constants";
import styles from "../ImportPDF.styles";

const Main = ({
  linkedObjects,
  objectsForUnlink,
  objectsForLink,
  objectsForSaveAndLink,
  objectsForCheckAndUpdate,
  nonResidentOwnersInDB,
  nonResidentOwnersNotInDB,
  residentOwnersInDB,
  residentOwnersNotInDB,
  type,
}) => {
  const getObjectName = (municipality: string, plot: any) =>
    `${municipality || ""} ${plot.section || ""} ${plot.plot_number || ""} ${
      plot.index || ""
    }`.trim();

  const objectsForRows = (
    objectsForUnlink: any[],
    objectsForLink: any[],
    linkedObjects: any[],
    objectsForSaveAndLink: any[],
    objectsForCheckAndUpdate: any[]
  ) => {
    const rows: any = [];
    if (linkedObjects && linkedObjects.length) {
      linkedObjects.forEach((object: any) => {
        rows.push({
          id: object.id,
          "Object address": `${
            object.object_name
              ? object.object_name
              : getObjectName(object.municipality, object.plot)
          }`,
          "Kadaster id": `${object.kadaster_id}`,
          kadaster_url: `${object.kadaster_url ? object.kadaster_url : ""}`,
          Status: "linked",
          Units:
            object.units && object.units.length ? object.units.length : "n/a",
          units_details:
            object.units && object.units.length ? object.units : "no details",
        });
      });
    }
    if (objectsForUnlink && objectsForUnlink.length) {
      objectsForUnlink.forEach((object: any) => {
        rows.push({
          id: object.id,
          "Object address": `${
            object.object_name
              ? object.object_name
              : getObjectName(object.municipality, object.plot)
          }`,
          "Kadaster id": `${object.kadaster_id}`,
          kadaster_url: `${object.kadaster_url ? object.kadaster_url : ""}`,
          Status: "for unlink",
          Units:
            object.units && object.units.length ? object.units.length : "n/a",
          units_details:
            object.units && object.units.length ? object.units : "no details",
        });
      });
    }
    if (objectsForLink && objectsForLink.length) {
      objectsForLink.forEach((object) => {
        rows.push({
          id: object.id,
          "Object address": `${
            object.object_name
              ? object.object_name
              : getObjectName(object.municipality, object.plot)
          }`,
          "Kadaster id": `${object.kadaster_id}`,
          kadaster_url: `${object.kadaster_url ? object.kadaster_url : ""}`,
          Status: "for link",
          Units:
            object.units && object.units.length ? object.units.length : "n/a",
          units_details:
            object.units && object.units.length ? object.units : "no details",
        });
      });
    }
    if (objectsForSaveAndLink && objectsForSaveAndLink.length) {
      let idCounter = 0;
      objectsForSaveAndLink.forEach((object) => {
        idCounter = idCounter + 1;
        rows.push({
          id: idCounter,
          "Object address": `${
            object.object_name
              ? object.object_name
              : getObjectName(object.municipality, object.plot)
          }`,
          "Kadaster id": `${object.kadaster_id}`,
          kadaster_url: `${object.kadaster_url ? object.kadaster_url : ""}`,
          Status: "for save and link",
          Units:
            object.units && object.units.length ? object.units.length : "n/a",
          units_details:
            object.units && object.units.length ? object.units : "no details",
        });
      });
    }
    if (objectsForCheckAndUpdate && objectsForCheckAndUpdate.length) {
      objectsForCheckAndUpdate.forEach((object) => {
        rows.push({
          id: object.id,
          "Object address": `${
            object.object_name
              ? object.object_name
              : getObjectName(object.municipality, object.plot)
          }`,
          "Kadaster id": `${object.kadaster_id}`,
          kadaster_url: `${object.kadaster_url ? object.kadaster_url : ""}`,
          Status: "check&update",
          Units:
            object.units && object.units.length ? object.units.length : "n/a",
          units_details:
            object.units && object.units.length ? object.units : "no details",
        });
      });
    }
    return rows;
  };

  const dataGridRows = objectsForRows(
    objectsForUnlink,
    objectsForLink,
    linkedObjects,
    objectsForSaveAndLink,
    objectsForCheckAndUpdate
  );

  const ownersForRows = (
    nonResidentOwnersInDB: any[],
    nonResidentOwnersNotInDB: any[],
    residentOwnersInDB: any[],
    residentOwnersNotInDB: any[],
  ) => {
    const rows: any = [];
    if (nonResidentOwnersInDB && nonResidentOwnersInDB.length) {
      nonResidentOwnersInDB.forEach((owner) => {
        rows.push({
          id: owner.id,
          "Owner name": owner.last_name,
          Resident: "no",
          "In DB": "yes",
          inDb: true,
        });
      });
    }
    if (nonResidentOwnersNotInDB && nonResidentOwnersNotInDB.length) {
      let idCounter = 0;
      nonResidentOwnersNotInDB.forEach((owner) => {
        idCounter = idCounter + 1;
        rows.push({
          id: idCounter,
          "Owner name": owner.last_name,
          Resident: "no",
          "In DB": "no",
          inDb: false,
        });
      });
    }
    if (residentOwnersInDB && residentOwnersInDB.length) {
      residentOwnersInDB.forEach((owner) => {
        rows.push({
          id: owner.id,
          "Owner name": owner.last_name,
          Resident: "yes",
          "In DB": "yes",
          inDb: true,
        });
      });
    }
    if (residentOwnersNotInDB && residentOwnersNotInDB.length) {
      let idCounter = 0;
      residentOwnersNotInDB.forEach((owner) => {
        idCounter = idCounter + 1;
        rows.push({
          id: idCounter,
          "Owner name": owner.last_name,
          Resident: "yes",
          "In DB": "no",
          inDb: false,
        });
      });
    }
    return rows;
  };

  const objectDataGridRows = ownersForRows(
    nonResidentOwnersInDB,
    nonResidentOwnersNotInDB,
    residentOwnersInDB,
    residentOwnersNotInDB
  );

  const dataGridColumns: GridColDef[] = [
    {
      field: "Object address",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (params.row.Status === "for save and link") {
          return params.value;
        } else {
          return (
            <a
              // @ts-ignore
              href={`/leadgen/object/${params.value.split(" ")}`}
              title={`To leadgen`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params.value}
            </a>
          );
        }
      },
    },
    {
      field: "Kadaster id",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (params.row.kadaster_url === "") {
          return params.value;
        } else {
          return (
            // @ts-ignore
            <a
              href={params.row.kadaster_url || ""}
              target="_blank"
              rel="noopener noreferrer"
              title={"To Kadaster"}
            >
              {params.value}
            </a>
          );
        }
      },
    },
    {
      field: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        let backgroundColor;
        let text;
        if (params.value === "linked") {
          backgroundColor = "#4caf50";
          text = "linked";
        }
        if (params.value === "for link") {
          backgroundColor = "#658EB1";
          text = "for link";
        }
        if (params.value === "for save and link") {
          backgroundColor = "#FF9800";
          text = "for save and link";
        }
        if (params.value === "for unlink") {
          backgroundColor = "#e53a3a";
          text = "for unlink";
        }

        return (
          <span
            style={{
              padding: "4px 6px",
              borderRadius: "15px",
              color: "#ffffff",
              backgroundColor,
              // width: "90px",
              textAlign: "center",
            }}
          >
            {text}
          </span>
        );
      },
      // filterable: false,
    },
    {
      field: "Units",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return params.row.Units === "n/a" ? (
          <Typography>{params.row.Units}</Typography>
        ) : (
          <>
            <Typography>{params.row.Units}</Typography>
            <IconButton
              aria-label="MenuButton"
              onClick={() =>
                eventEmitter.emit(types.openUnitsDetailsModal, {
                  units: params.row.units_details,
                  status: params.row.Status,
                })
              }
            >
              <MoreVertIcon sx={styles.menuIcon} />
            </IconButton>
          </>
        );
      },
    },
  ];

  const objectDataGridColumns: GridColDef[] = [
    {
      field: "Owner name",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (!params.row.inDb) {
          return params.value;
        } else {
          return (
            <a
              // @ts-ignore
              href={`/leadgen/entity/${params.row.id}`}
              title={`To leadgen`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params.value}
            </a>
          );
        }
      },
    },
    {
      field: "Resident",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        let backgroundColor;
        let text;
        if (params.value === "yes") {
          backgroundColor = "#4caf50";
          text = "yes";
        }
        if (params.value === "no") {
          backgroundColor = "#FF9800";
          text = "no";
        }

        return (
          <span
            style={{
              padding: "4px 6px",
              borderRadius: "15px",
              color: "#ffffff",
              backgroundColor,
              width: "90px",
              textAlign: "center",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      field: "In DB",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        let backgroundColor;
        let text;
        if (params.value === "yes") {
          backgroundColor = "#4caf50";
          text = "yes";
        }
        if (params.value === "no") {
          backgroundColor = "#FF9800";
          text = "no";
        }

        return (
          <span
            style={{
              padding: "4px 6px",
              borderRadius: "15px",
              color: "#ffffff",
              width: "90px",
              backgroundColor,
              textAlign: "center",
            }}
          >
            {text}
          </span>
        );
      },
    },
  ];

  return (
    <Grid sx={styles.mainBlockHolder}>
      <DataGrid
        rows={type === modelTypes.ENTITY ? dataGridRows : objectDataGridRows}
        columns={
          type === modelTypes.ENTITY ? dataGridColumns : objectDataGridColumns
        }
        // pageSize={pageSize}
        // paginationModel={pageSize}
        // onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // pageSizeOptions={[5, 10, 20]}
        // pagination
      />
    </Grid>
  );
};

export default Main;

Main.propTypes = {
  type: PropTypes.string.isRequired,
  objectsForUnlink: PropTypes.object,
  objectsForLink: PropTypes.object,
  linkedObjects: PropTypes.object,
  objectsForSaveAndLink: PropTypes.object,
  objectsForCheckAndUpdate: PropTypes.object,
  nonResidentOwnersInDB: PropTypes.object,
  nonResidentOwnersNotInDB: PropTypes.object,
  residentOwnersInDB: PropTypes.object,
  residentOwnersNotInDB: PropTypes.object,
};
