// import React, { useState } from "react";
import React from "react";
import styles from "./PostcodeForm.styles";
import { Box, Divider, FormControl, InputAdornment } from "@mui/material";
import StyledTextField from "../styled-components/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import InputHelperText from "../styled-components/InputHelperText";
// import PostCodeApiService from "../../services/postcode-api.service";

// import {withStyles} from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
// import {INPUT, SELECT} from '../../../constants/field-types';
// import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
// import Search from '@material-ui/icons/Search';
// import Cancel from '@material-ui/icons/CancelOutlined';
// import FieldEditor from '../../editor/field';
// import Container from './Container';
// import PostCodeApiService from '../../../../common/services/postcode-api.service';
// import AlertService from '../../../services/alert.service';
//
// type PostcodeFormProps = {
//   classes: {
//     button: string,
//     buttonGroup: string,
//     divider: string,
//     iconSearch: string,
//     container: string,
//     rightColumn: string,
//     inputWrapper: string,
//   },
// };
//
// type PostcodeFormState = {
//   postcode: string,
//   number: string,
//   addresses: any,
//   currentValue: null | ""
// }
//
// class PostcodeForm extends PureComponent<PostcodeFormProps, PostcodeFormState> {
// class PostcodeForm {

const PostcodeForm = () => {
  // const [searchValue, setSearchValue] = useState("");

  // const handleChange = async (event) => {
  //   console.log("event.target ", event.target.value);
  //   setSearchValue(event.target.value);
  //   if (event.target.value <= 6) {
  //     return;
  //   } else {
  //     console.log("works");
  //     const splitedValue = event.target.value.split(" ");
  //     console.log("splitedValue ", splitedValue);
  //
  //     const postcode = splitedValue[0];
  //     let number = "";
  //     if (splitedValue.length > 1) {
  //       number = splitedValue[1];
  //     }
  //
  //     const result = await PostCodeApiService.get(
  //       { postcode, number },
  //       // event.target.value,
  //       // ({data}) => this.setState({addresses: data._embedded.addresses}),
  //       ({ data }) => console.log({ addresses: data._embedded.addresses }),
  //       (e) =>
  //         console.log("No such postcode or something went wrong!", e.message)
  //     );
  //
  //     console.log("result ", result);
  //   }
  // };

  return (
    <React.Fragment>
      <Box sx={styles.fieldBlock} component="form">
        <FormControl sx={styles.form}>
          <StyledTextField
            fullWidth
            margin="dense"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // onChange={(e) => handleChange(e)}
            // value={searchValue}
          />
          <InputHelperText text="Type postcode and number to search an address" />
        </FormControl>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

//   static propTypes =
//   {
//     classes: PropTypes.object.isRequired,
//   };
//
//   state = {
//     postcode: '',
//     number: '',
//     addresses: [],
//     currentValue: null,
//   };
//
//   getValue = ({city = {}, street, number, addition, letter}) =>
//     //@ts-ignore
//     `${city.label || ''} ${street || ''} ${number || ''} ${addition ||
//     ''}${letter || ''}`.trim();
//
//   handleChange = (type, {target}) => {
//     //@ts-ignore
//     const {onSelect} = this.props;
//     const {addresses} = this.state;
//     if (type === 'addresses') {
//       const {
//         purpose,
//         postcode,
//         city,
//         letter,
//         addition,
//         number,
//         year,
//         street,
//       } = addresses[target.value];
//       onSelect({
//         target: {
//           value: {
//             purpose,
//             postcode,
//             //@ts-ignore
//             city: city.label,
//             number,
//             street_name: street,
//             built_year: year,
//             number_add: `${addition || ''}${letter || ''}`,
//           },
//         },
//       });
//       return this.setState({
//         currentValue: target.value,
//       });
//     }
//     //@ts-ignore
//     this.setState({
//       [type]: target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase(),
//     });
//   };
//
//   handleCancel = () => {
//     this.setState({
//       postcode: '',
//       number: '',
//       addresses: [],
//       currentValue: null,
//     });
//   };
//
//   handleGetPostcodeData = async () => {
//     const {postcode, number} = this.state;
//     await PostCodeApiService.get(
//       {postcode, number},
//       ({data}) => this.setState({addresses: data._embedded.addresses}),
//       e => AlertService.error('No such postcode or something went wrong!', e.message),
//     );
//   };
//
//   render() {
//     //@ts-ignore
//     // const {classes} = this.props;
//     // const {postcode, number, addresses, currentValue} = this.state;
//
//     return (
//       <React.Fragment>
// <div className={classes.container}>
//   <Container
//     leftColumn={[
//       <FieldEditor
//         type={INPUT}
//         name="postcode"
//         label="Postcode"
//         value={postcode}
//         //@ts-ignore
//         onChange={e => this.handleChange('postcode', e)}
//       />,
//     ]}
//     rightColumn={[
//       <div className={classes.rightColumn}>
//         <div className={classes.inputWrapper}>
//           <FieldEditor
//             type={INPUT}
//             name="number"
//             label="Number"
//             value={number}
//             //@ts-ignore
//             onChange={e => this.handleChange('number', e)}
//           />
//         </div>
//         <div className={classes.buttonGroup}>
//           <Button
//             onClik={this.handleGetPostcodeData}
//             className={classes.button}
//           >
//             <Search className={classes.iconSearch}/>
//           </Button>
//           <Button
//             onClick={this.handleCancel}
//             className={classes.button}
//           >
//             <Cancel className={classes.iconSearch}/>
//           </Button>
//         </div>
//       </div>,
//     ]}
//   />
// </div>
// {addresses.length ? (
//   <FieldEditor
//     type={SELECT}
//     name="addresses"
//     label="Addresses"
//     //@ts-ignore
//     onChange={e => this.handleChange('addresses', e)}
//     value={
//       {
//         current: currentValue !== null ? currentValue : '',
//         options: addresses.map((a, index) => ({
//         value: index,
//          label: this.getValue(a),
// })),
//      } || {}
//   }
//  />
// ) : null}
// <Divider className={classes.divider}/>
//       </React.Fragment>
//     );
//   }
// }

export default PostcodeForm;
