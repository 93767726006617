import { call, put, takeLatest } from 'redux-saga/effects';
import {
  RECEIVE_LABELS_LIST_REQUEST,
  RECEIVE_TAGS_LIST_REQUEST,
  UPDATE_TAG_REQUEST,
  DELETE_TAG_REQUEST,
  UPDATE_LABEL_REQUEST,
  DELETE_LABEL_REQUEST, CREATE_NEW_LABEL_REQUEST, CREATE_NEW_TAG_REQUEST
} from '../../../common/constants/action-constants';
import ApiService from '../../../common/services/api.service';
import {
  receiveLabelsListError,
  receiveLabelsListSuccess,
  receiveTagsListError,
  receiveTagsListSuccess,
  updateTagError,
  receiveTagsListRequest,
  deleteTagError,
  receiveLabelsListRequest,
  updateLabelError,
  deleteLabelError,
  createNewLabelError,
  createNewTagError,
} from '../actions';
import AlertService from '../../../common/services/notification.service';
import { notificationMessage } from '../../../common/constants/notication-constants';

function* getLabelsList(action) {

  try {
    const labelsList = yield call(ApiService.get, '/labels');
    if (labelsList) {
      yield put(receiveLabelsListSuccess(labelsList.data));
    }
  } catch (e) {
    yield put(receiveLabelsListError(e));
  }
}

function* updateLabel(action) {
  try {
    const labels = yield call(
      ApiService.put,
      `/label/${action.id}`,
      action.name
    );
    if (labels.data.message) {
      AlertService.success(labels.data.message);
    }
    yield call(ApiService.get, '/labels');
    yield put(receiveLabelsListRequest());
  } catch (error) {
    yield put(updateLabelError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* deleteLabel(action) {
  try {
    const labelDelete = yield call(ApiService.delete, `/label/${action.id}`);
    if (labelDelete.data.message) {
      AlertService.success(labelDelete.data.message);
    }
    yield call(ApiService.get, '/labels');
    yield put(receiveLabelsListRequest());
  } catch (error) {
    yield put(deleteLabelError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* createLabel(action) {
  try {
    const newLabel = yield call(ApiService.post, "/label", action.label);
    if (newLabel.data) {
      AlertService.success(newLabel.data.message);
      yield put(receiveLabelsListRequest());
    }
  } catch (error) {
    yield put(createNewLabelError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}


function* getTagsList(action) {
  try {
    const tagsList = yield call(ApiService.get, '/tag');
    if (tagsList) {
      yield put(receiveTagsListSuccess(tagsList.data));
    }
  } catch (e) {
    yield put(receiveTagsListError(e));
  }
}

function* updateTag(action) {
  try {
    const tags = yield call(
      ApiService.put,
      `/tag/${action.id}`,
      action.name
    );
    if (tags.data.message) {
      AlertService.success(tags.data.message);
    }
    yield call(ApiService.get, '/tag');
    yield put(receiveTagsListRequest());
  } catch (error) {
    yield put(updateTagError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* deleteTag(action) {
  try {
    const tagDelete = yield call(ApiService.delete, `/tag/${action.id}`);
    if (tagDelete.data.message) {
      AlertService.success(tagDelete.data.message);
    }
    yield call(ApiService.get, '/tag');
    yield put(receiveTagsListRequest());
  } catch (error) {
    yield put(deleteTagError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* createTag(action) {
  try {
    const newTag = yield call(ApiService.post, "/tag", action.tag);
    if (newTag.data) {
      AlertService.success(newTag.data.message);
      yield put(receiveTagsListRequest());
    }
  } catch (error) {
    yield put(createNewTagError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

export default function* actionSuperAdmin() {
  yield takeLatest(RECEIVE_LABELS_LIST_REQUEST, getLabelsList);
  yield takeLatest(RECEIVE_TAGS_LIST_REQUEST, getTagsList);
  yield takeLatest(UPDATE_LABEL_REQUEST, updateLabel);
  yield takeLatest(DELETE_LABEL_REQUEST, deleteLabel);
  yield takeLatest(UPDATE_TAG_REQUEST, updateTag);
  yield takeLatest(DELETE_TAG_REQUEST, deleteTag);
  yield takeLatest(CREATE_NEW_LABEL_REQUEST, createLabel);
  yield takeLatest(CREATE_NEW_TAG_REQUEST, createTag);
}
