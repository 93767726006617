import { Box, Button } from "@mui/material";
import styles from "../../../common/components/entity-editor/EntityEditor.styles";
import React from "react";

const Footer = ({ onCancel, handleSave }) => {
  return (
    <Box sx={styles.buttonHolder}>
      <Button variant="contained" sx={styles.buttonSave} onClick={handleSave}>
        Save
      </Button>
      <Button variant="contained" sx={styles.buttonCansel} onClick={onCancel}>
        Cancel
      </Button>
    </Box>
  );
};

export default Footer;
