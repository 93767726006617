import {
  RECEIVE_LABELS_LIST_ERROR,
  RECEIVE_LABELS_LIST_REQUEST,
  RECEIVE_LABELS_LIST_SUCCESS,
  CREATE_NEW_LABEL_REQUEST,
  CREATE_NEW_LABEL_ERROR,
  RECEIVE_TAGS_LIST_ERROR,
  RECEIVE_TAGS_LIST_REQUEST,
  RECEIVE_TAGS_LIST_SUCCESS,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_ERROR,
  DELETE_TAG_REQUEST,
  DELETE_TAG_ERROR,
  UPDATE_LABEL_REQUEST,
  UPDATE_LABEL_ERROR,
  DELETE_LABEL_REQUEST,
  DELETE_LABEL_ERROR,
  CREATE_NEW_TAG_REQUEST,
  CREATE_NEW_TAG_ERROR

} from '../../../common/constants/action-constants';
// import AlertService from "../../../common/services/notification.service";

export const receiveLabelsListRequest = () => {
  return {
    type: RECEIVE_LABELS_LIST_REQUEST
  };
};

export const receiveLabelsListSuccess = (labelsList) => {
  return {
    type: RECEIVE_LABELS_LIST_SUCCESS,
    labelsList
  };
};

export const receiveLabelsListError = (error) => {
  return {
    type: RECEIVE_LABELS_LIST_ERROR,
    error
  };
};

export const updateLabelRequest = (id, name) => {
  return {
    type: UPDATE_LABEL_REQUEST,
    id,
    name
  };
};

export const updateLabelError = (error) => {
  return {
    type: UPDATE_LABEL_ERROR,
    error
  };
};

export const deleteLabelRequest = (id) => {

  return {
    type: DELETE_LABEL_REQUEST,
    id
  };
};

export const deleteLabelError = (error) => {
  return {
    type: DELETE_LABEL_ERROR,
    error
  };
};

export const createNewLabelRequest = label => {
  return {
    type: CREATE_NEW_LABEL_REQUEST,
    label
  };
};

export const createNewLabelError = (error) => {
  return {
    type: CREATE_NEW_LABEL_ERROR,
    error
  };
};


//======== tags ===========//

export const receiveTagsListRequest = () => {
  return {
    type: RECEIVE_TAGS_LIST_REQUEST
  };
};

export const receiveTagsListSuccess = (tagsList) => {
  return {
    type: RECEIVE_TAGS_LIST_SUCCESS,
    tagsList
  };
};

export const receiveTagsListError = (error) => {
  return {
    type: RECEIVE_TAGS_LIST_ERROR,
    error
  };
};

export const updateTagRequest = (id, name) => {
  return {
    type: UPDATE_TAG_REQUEST,
    id,
    name
  };
};

export const updateTagError = (error) => {
  return {
    type: UPDATE_TAG_ERROR,
    error
  };
};


export const deleteTagRequest = (id) => {
  return {
    type: DELETE_TAG_REQUEST,
    id
  };
};

export const deleteTagError = (error) => {
  return {
    type: DELETE_TAG_ERROR,
    error
  };
};

export const createNewTagRequest = (tag) => {
  return {
    type: CREATE_NEW_TAG_REQUEST,
    tag
  };
};

export const createNewTagError = (error) => {
  return {
    type: CREATE_NEW_TAG_ERROR,
    error
  };
};





