import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@mui/material';
import eventEmitter from '../../helpers/eventEmitter';
import styles from './DefaultModal.styles';

export default function DefaultModal(props: any) {
  const [modal, setModal] = useState({
    isOpen: false,
    data: []
  });

  const handleOpen = (...args: any[]) => {
    setModal({
      isOpen: true,
      // @ts-ignore
      data: args,
    });
  };

  const closeModal = () => {
    setModal({
      isOpen: false,
      data: []
    });
  };

  useLayoutEffect(() => {
    if (modal.isOpen) props.onOpen(...modal.data);
    if (!modal.isOpen) props.onClose(...modal.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    if (modal.isOpen) {
      document.body.style.cssText = `overflow: hidden; padding-right: ${window.innerWidth - document.body.offsetWidth}px;`;
    } else {
      document.body.style.cssText = '';
    }
    ;
  }, [modal]);

  useEffect(() => {
    eventEmitter.on(props.nameEvent, handleOpen);
    return () => {
      eventEmitter.off(props.nameEvent, handleOpen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nameEvent]);

  if (!modal.isOpen) return null;

  return (
    <>
      <Paper
        sx={props.onOpen ? styles.rootActive : styles.root}
        onClick={() => closeModal}
      >
        <Box
          sx={props.onOpen && props.modalType === 'search' ? styles.boxContentActiveSearch : props.onOpen ? styles.boxContentActive : styles.boxContent}>
          {props.children({ closeModal })}
        </Box>
      </Paper>
    </>
  );
}

DefaultModal.propTypes = {
  children: PropTypes.func.isRequired,
  nameEvent: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  modalType: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

DefaultModal.defaultProps = {
  onOpen: () => {
  },
  onClose: () => {
  }
};
