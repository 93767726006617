import * as React from "react";
import styles from "./SideBar.styles";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import UserAvatar from "../avatar/UserAvatar";
import { propsTypes, userTypes } from "../../constants/field-constants";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GroupIcon from "@mui/icons-material/Group";
import RoofingIcon from "@mui/icons-material/Roofing";
import ApprovalIcon from "@mui/icons-material/Approval";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LogoutIcon from "@mui/icons-material/Logout";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserService from "../../services/user.service";
import { selectUser } from "../../../modules/login/selectors";
import Login from "../../../modules/login/Login";

interface SideBarProps {
  drawerClose?: () => any;
  open: boolean;
  userName?: string;
  userRole?: string;
}

/** Block for sidebar **/
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  justifyContent: "space-around",
  padding: 0,
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<SideBarProps>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
/** Block for sidebar start end **/

const SideBar = ({ drawerClose, open, userName, userRole }: SideBarProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [selectedSideBarIndex, setSelectedSideBarIndex] = React.useState(0);
  const [selectedSideBarAdminIndex, setSelectedSideBarAdminIndex] =
    React.useState(-1);
  const [selectedSideBarSuperAdminIndex, setSelectedSideBarSuperAdminIndex] =
    React.useState(-1);

  const handleNavigate = (
    path,
    event: React.MouseEvent<HTMLElement>,
    index: number,
    type
  ) => {
    navigate(path);
    if (type === "default") {
      setSelectedSideBarIndex(index);
      setSelectedSideBarAdminIndex(-1);
      setSelectedSideBarSuperAdminIndex(-1);
    }
    if (type === "admin") {
      setSelectedSideBarAdminIndex(index);
      setSelectedSideBarIndex(-1);
      setSelectedSideBarSuperAdminIndex(-1);
    }
    if (type === "superadmin") {
      setSelectedSideBarSuperAdminIndex(index);
      setSelectedSideBarIndex(-1);
      setSelectedSideBarAdminIndex(-1);
    }
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    if (index === 2) {
      UserService.logout();
      navigate("/login", { state: <Login /> });
    } else return;
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  /** menu items which are available for all users **/
  const menuItems = [
    {
      title: "Dashboard",
      to: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Leadgen",
      to: "/leadgen",
      icon: <ApartmentIcon />,
    },
    {
      title: "Add new",
      to: "/add-new",
      icon: <AddBoxIcon />,
    },
    {
      title: "PDF import",
      to: "/import-pdf",
      icon: <PictureAsPdfIcon />,
    },
    {
      title: "Leads",
      to: "/leads",
      icon: <GroupIcon />,
    },
  ];
  /** menu items which are available only for admins **/
  const adminMenuItems = [
    {
      title: "Query objects",
      to: "/query-objects",
      icon: <RoofingIcon />,
    },
    {
      title: "LPS config",
      to: "/lps-form",
      icon: <ApprovalIcon />,
    },
    {
      title: "Admin panel",
      to: "/admin",
      icon: <AdminPanelSettingsIcon />,
    },
  ];

  const superAdminMenuItems = [
    {
      title: "Superadmin panel",
      to: "/super-admin",
      icon: <AdminPanelSettingsIcon color="warning" />,
    },
  ];

  /** popup menu items **/
  const personalMenuItems = [
    {
      title: "Settings",
      to: "/",
      icon: <SettingsIcon />,
    },
    {
      title: "Reports",
      to: "/",
      icon: <SummarizeIcon />,
    },
    {
      title: "Logout",
      to: "/login",
      icon: <LogoutIcon />,
    },
  ];

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ margin: 0 }}
            aria-controls={openMenu ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <UserAvatar stylesType={propsTypes.DEFAULT} />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openMenu}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {personalMenuItems.map(({ title, to, icon }, index) => (
            <MenuItem
              selected={selectedIndex === index}
              key={index}
              disabled={index < 2}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{title}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
        <Box sx={styles.userInfoHolder}>
          <Typography sx={styles.userName} variant="h3">
            {userName}
          </Typography>
          <Typography sx={styles.userRole} variant="h6">
            {userRole}
          </Typography>
        </Box>
        <IconButton onClick={drawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuItems.map(({ title, to, icon }, index) => (
          <ListItem key={title} disablePadding sx={styles.list}>
            <ListItemButton
              sx={{
                minHeight: 38,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              selected={selectedSideBarIndex === index}
              onClick={(event) => handleNavigate(to, event, index, "default")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {(userRole === userTypes.ADMIN || userRole === userTypes.SUPER_ADMIN) &&
          adminMenuItems.map(({ title, to, icon }, index) => (
            <ListItem key={title} disablePadding sx={styles.list}>
              <ListItemButton
                selected={selectedSideBarAdminIndex === index}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={(event) => handleNavigate(to, event, index, "admin")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        {userRole === userTypes.SUPER_ADMIN &&
          superAdminMenuItems.map(({ title, to, icon }, index) => (
            <>
              <Divider />
              <ListItem key={title} disablePadding sx={styles.list}>
                <ListItemButton
                  selected={selectedSideBarSuperAdminIndex === index}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={(event) =>
                    handleNavigate(to, event, index, "superadmin")
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          ))}
      </List>
    </Drawer>
  );
};

function mapStateToProps(state) {
  const userData = selectUser(state);
  return userData;
}
export default connect(mapStateToProps)(SideBar);
