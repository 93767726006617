const styles = {
  fieldHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  fieldBlock: {
    marginBottom: "20px",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // width: "49%",
  },
  form: {
    width: "100%",
  },
};

export default styles;
