import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import StyledTextField from "../../styled-components/StyledTextField";
import styles from "../EntityEditor.styles";
import StyledPhoneInput from "../../styled-components/StyledPhoneInput";
import { useDispatch } from "react-redux";

const initialEditModeState = {
  isEdit: false,
  index: -1,
};

const initialPhoneNumberState = {
  type: "",
  number: "",
  countryCallingCode: "",
};

const initialPhoneNumberDetails = {
  type: "",
  number: "",
  countryCallingCode: "",
  index: -1,
  id: "",
  modified: false,
};

const EntityPhoneNumbersBlock = ({ phoneNumbers, onChange }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(initialEditModeState);
  const [entityPhoneNumber, setEntityPhoneNumber] = useState(
    initialPhoneNumberState
  );
  const [entityPhoneNumbersList, setEntityPhoneNumbersList] = useState([]);
  const [editedPhoneNumberDetails, setEditedPhoneNumberDetails] = useState(
    initialPhoneNumberDetails
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phoneNumberForDelete, setPhoneNumberForDelete] = useState([]);

  useEffect(() => {
    setEntityPhoneNumbersList(phoneNumbers);
  }, [dispatch, phoneNumbers]);

  const handleClearForm = () => {
    /** clear all fields and error statuses **/
    setEditedPhoneNumberDetails(initialPhoneNumberDetails);
    setEntityPhoneNumber(initialPhoneNumberState);
    setEditMode(initialEditModeState);
    setEntityPhoneNumbersList([]);
    setPhoneNumberForDelete([]);
  };

  const handleChange = (name, key, event, value) => {
    if (key === "number") {
      return setEntityPhoneNumber({
        ...entityPhoneNumber,
        [key]: value.numberValue,
        countryCallingCode: value.countryCallingCode,
      });
    }

    return setEntityPhoneNumber({
      ...entityPhoneNumber,
      [key]: event.target.value,
    });
  };

  const handleChangeItem = (fieldName, selectedIndex, event) => {
    setEditedPhoneNumberDetails({
      ...editedPhoneNumberDetails,
      [fieldName]: event.target.value,
      index: selectedIndex,
      modified: true,
    });
  };

  const handleDeleteItem = (selectedIndex) => {
    const newStateForDelete = [];
    entityPhoneNumbersList.map((item: any, index) => {
      if (index === selectedIndex && item.id) {
        // @ts-ignore
        return newStateForDelete.push(item);
      } else {
        return item;
      }
    });

    const filteredList = entityPhoneNumbersList.filter(
      (item: any, index) => index !== selectedIndex
    );

    const newState = {
      filteredList,
      newStateForDelete,
    };

    setEntityPhoneNumbersList(filteredList);
    setPhoneNumberForDelete(newStateForDelete);
    onChange("entityPhoneNumbers", newState, true);
  };

  const handleSetEditMode = (type, selectedIndex) => {
    /** managing open/close state for ip item change, save changes by click in ip list **/
    if (type === "open") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      entityPhoneNumbersList.forEach((item: any, index) => {
        if (index === selectedIndex) {
          setEditedPhoneNumberDetails({
            ...item,
            index: selectedIndex,
            // id: item.id || null,
            type: item.type,
            number: item.number,
            countryCallingCode: item.countryCallingCode,
          });
        }
      });
    }
    if (type === "close") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
    }
    if (type === "edit") {
      const newState = entityPhoneNumbersList.map((item: any, index) => {
        if (
          index === editedPhoneNumberDetails.index &&
          index === selectedIndex
        ) {
          return {
            // id: editedPhoneNumberDetails.id,
            type: editedPhoneNumberDetails.type,
            number: editedPhoneNumberDetails.number,
            countryCallingCode: editedPhoneNumberDetails.countryCallingCode,
            modified: editedPhoneNumberDetails.modified,
          };
        } else {
          return item;
        }
      });
      // @ts-ignore
      setEntityPhoneNumbersList(newState);
      onChange("entityPhoneNumbers", newState);
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      setEditedPhoneNumberDetails(editedPhoneNumberDetails);
    }
  };

  const addNewPhoneNumber = () => {
    if (entityPhoneNumber.number) {
      const newState = [
        ...entityPhoneNumbersList,
        {
          type: entityPhoneNumber.type,
          number: entityPhoneNumber.number,
        },
      ];
      // @ts-ignore
      setEntityPhoneNumbersList(newState);
      onChange("entityPhoneNumbers", newState);
      handleClearForm();
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Phone numbers
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Type"
              margin="dense"
              onChange={(e) =>
                handleChange("entityPhoneNumbers", "type", e, null)
              }
              value={entityPhoneNumber.type}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledPhoneInput
              placeholder="Phone number"
              defaultCountry="NL"
              continents={["EU", "OC"]}
              fullWidth
              required
              margin="dense"
              size="medium"
              onChange={(e, value) =>
                handleChange("entityPhoneNumbers", "number", e, value)
              }
              value={entityPhoneNumber.number || ""}
            />
          </Box>
        </Box>
        <Button sx={styles.button} onClick={addNewPhoneNumber}>
          Add new phone number
        </Button>
        <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />
        {entityPhoneNumbersList?.length > 0 &&
          entityPhoneNumbersList.map(
            (
              item: {
                type: string;
                number: string;
              },
              index
            ) => {
              return editMode.isEdit && editMode.index === index ? (
                <Box sx={styles.editHolder} key={index}>
                  <Typography variant="body2" sx={styles.textField}>
                    Edit current phone number
                  </Typography>
                  <Box sx={styles.editFieldsBlockHolder}>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        sx={{ marginRight: "15px", width: "100%" }}
                        label="Type"
                        variant="standard"
                        onChange={(e) => handleChangeItem("type", index, e)}
                        value={editedPhoneNumberDetails.type}
                      />
                    </Box>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Number"
                        variant="standard"
                        required
                        onChange={(e) => handleChangeItem("number", index, e)}
                        value={editedPhoneNumberDetails.number}
                      />
                    </Box>
                  </Box>
                  <Box sx={styles.editButtonGroup}>
                    <IconButton
                      onClick={() => handleSetEditMode("edit", index)}
                    >
                      <DoneIcon fontSize={"small"} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSetEditMode("close", index)}
                    >
                      <CloseIcon fontSize={"small"} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box sx={styles.itemContainer} key={index}>
                  <Box sx={styles.itemHolder}>
                    <Box sx={styles.infoHolder}>
                      <Box sx={styles.typeInfo}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Type:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {item?.type}
                        </Typography>
                      </Box>
                      <Box sx={styles.info}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Phone number:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {item?.number}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.iconHolder}>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => handleSetEditMode("open", index)}
                      >
                        <EditIcon sx={styles.editIcon} />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <DeleteIcon sx={styles.deleteIcon} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EntityPhoneNumbersBlock;
