import { combineReducers } from "redux";
import login from "./modules/login/reducers";
import lps from "./modules/lps-form/reducers";
import admin from "./modules/admin/reducers";
import newEntity from "./modules/add-new-entity/reducers";
import search from './modules/search/reducers';
import leadgen from './modules/leadgen/reducers';
import superAdmin from './modules/super-admin/reducers';
import leads from "./modules/leads/reducers";
import importPDF from "./modules/importPDF/reducers";
// import entity from "../modules/leadgen-head-menu/reducers";
// import queryObjects from "../modules/query-objects/reducers";

const rootReducer = combineReducers({
  login,
  lps,
  admin,
  newEntity,
  search,
  leadgen,
  superAdmin,
  leads,
  importPDF,
  // entity,
  // queryObjects,
});

export default rootReducer;
