import { createSelector } from "reselect";

const leadsState = (state) => {
  if (state && state.leads) {
    return state.leads;
  }
  return null;
};

const leadsSelector = createSelector(
  [leadsState],
  (state) => state //return leads
);

const setLeadIdSelector = createSelector(
  [leadsState],
  (state) => state.setLeadId
);

export const selectLeads = createSelector(
  [leadsSelector, setLeadIdSelector],
  (leads, setLeadId) => {
    const assignedLeadsList = leads ? leads.assigned.assigned : false;
    const scheduledLeads = leads ? leads.scheduled.scheduled : false;
    const currentLeadId = setLeadId ? setLeadId : null;
    const leadsList = leads ? leads.receive : [];
    const isAssigned = leads ? leads.assigned : false;
    const isScheduled = leads ? leads.scheduled : false;
    const isSkipped =  leads ? leads.isSkipped : false;

    return {
      leads,
      leadsList,
      assignedLeadsList,
      scheduledLeads,
      currentLeadId,
      setLeadId,
      isAssigned,
      isScheduled,
      isSkipped,
    };
  }
);
