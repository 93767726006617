import React, { useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  useAdvancedMarkerRef,
  useMapsLibrary,
  useMap,
  InfoWindow,
  Pin,
} from "@vis.gl/react-google-maps";

import { connect, useDispatch } from "react-redux";
import { selectLeadgen } from "../../selectors";
import { resetAddressOnMap } from "../../actions/objects";
import { Typography, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { selectLeads } from "../../../leads/selectors";
import styles from "../../Leadgen.styles";

const { REACT_APP_API_KEY } = process.env;

interface MapsProps {
  mapStyles: object;
  currentObject?: object;
  entityObjectsList?: any;
  addressForMapDisplaying?: any;
  currentLeadId?: any;
}

const defaultCenterAmsterdam = {
  lat: 52.3679843,
  lng: 4.903561399999944,
};

const getObjectAddress = (currentObject: any) => {
  return `${currentObject.city} ${currentObject.objectAddress}`;
};

function Geocoding({ address, mapStyles }) {
  const map = useMap();
  const dispatch = useDispatch();

  const geocodingApiLoaded = useMapsLibrary("geocoding");
  const [geocodingService, setGeocodingService] =
    useState<google.maps.Geocoder>();
  const [geocodingResult, setGeocodingResult] =
    useState<google.maps.GeocoderResult>();
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [mapref, setMapRef] = useState<google.maps.Map>();
  const [center, setNewCenter] = useState({
    lat: 52.3679843,
    lng: 4.903561399999944,
  });
  const [addressForDisplaying, setAddressForDisplaying] = useState("");

  useEffect(() => {
    if (address) {
      setAddressForDisplaying(address);
    }
  }, [address]);

  useEffect(() => {
    if (!geocodingApiLoaded) return;
    if (!map) return;

    setMapRef(map);
    setGeocodingService(new window.google.maps.Geocoder());
    setNewCenter(defaultCenterAmsterdam);
  }, [geocodingApiLoaded, map]);

  useEffect(() => {
    if (geocodingResult && !address) {
      setNewCenter(defaultCenterAmsterdam);
    }
  }, [geocodingResult, address]);

  useEffect(() => {
    if (!addressForDisplaying) return;
    if (!geocodingResult) return;

    if (
      addressForDisplaying.includes("null") ||
      addressForDisplaying.includes("n/a")
    ) {
      setNewCenter(defaultCenterAmsterdam);
      mapref?.panTo(defaultCenterAmsterdam);
    } else {
      const marker = {
        location: {
          lat: geocodingResult?.geometry.location.lat(),
          lng: geocodingResult?.geometry.location.lng(),
        },
        address: geocodingResult?.formatted_address,
        place_id: geocodingResult?.place_id,
      };
      setNewCenter(marker.location);
      mapref?.panTo({
        // @ts-ignore
        lat: parseFloat(geocodingResult?.geometry.location.lat()),
        // @ts-ignore
        lng: parseFloat(geocodingResult?.geometry.location.lng()),
      });
    }
  }, [addressForDisplaying, geocodingResult]);

  useEffect(() => {
    if (!geocodingService) return;
    if (address) {
      geocodingService.geocode({ address }, (results, status) => {
        if (results && status === "OK") {
          setGeocodingResult(results[0]);
        }
      });
      dispatch(resetAddressOnMap(""));
    }
  }, [geocodingService, address]);

  return (
    <Map
      style={mapStyles}
      defaultCenter={addressForDisplaying ? center : defaultCenterAmsterdam}
      defaultZoom={20}
      gestureHandling={"greedy"}
      disableDefaultUI={false}
      mapId={"LEADGEN_MAP"}
    >
      <AdvancedMarker
        ref={markerRef}
        position={addressForDisplaying ? center : defaultCenterAmsterdam}
        onClick={() => setOpenInfo(true)}
      >
        <Pin background={"red"} borderColor={"green"} glyphColor={"yellow"} />
      </AdvancedMarker>

      {openInfo && (
        <InfoWindow
          anchor={marker}
          position={addressForDisplaying ? center : defaultCenterAmsterdam}
          headerDisabled
        >
          <Box sx={styles.boxInfoWindow}>
            <Typography sx={styles.textInfoWindow}>
              {!addressForDisplaying.includes("n/a") &&
              !addressForDisplaying.includes("null")
                ? addressForDisplaying
                : "Center Amsterdam"}
            </Typography>
            <IconButton sx={{ padding: 0 }} onClick={() => setOpenInfo(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </InfoWindow>
      )}
    </Map>
  );
}

const Maps = (props: MapsProps) => {
  const {
    mapStyles,
    currentObject,
    entityObjectsList,
    addressForMapDisplaying,
    currentLeadId,
  } = props;

  const [address, setAddress] = useState<string>("");
  const [flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    if (currentLeadId) {
      setFlag(false);
    }
  }, [currentLeadId]);

  useEffect(() => {
    if (
      addressForMapDisplaying &&
      Object.keys(addressForMapDisplaying).length > 0
    ) {
      setAddress(addressForMapDisplaying);
    }
    if (!flag && !addressForMapDisplaying && entityObjectsList.length > 0) {
      setFlag(true);
    }
  }, [addressForMapDisplaying, entityObjectsList]);

  useEffect(() => {
    if (flag && !addressForMapDisplaying) {
      setAddress("");
    }
  }, [addressForMapDisplaying, flag]);

  useEffect(() => {
    if (currentObject) {
      setAddress(getObjectAddress(currentObject));
    }
  }, [currentObject]);
  return (
    <APIProvider
      //@ts-ignore
      apiKey={REACT_APP_API_KEY}
    >
      <>
        <Geocoding address={address} mapStyles={mapStyles} />
      </>
    </APIProvider>
  );
};

function mapStateToProps(state: any) {
  const { entityObjectsList, currentObject } = selectLeadgen(state);
  // @ts-ignore
  const { currentLeadId } = selectLeads(state);
  return { entityObjectsList, currentObject, currentLeadId };
}

export default connect(mapStateToProps)(Maps);
